import styled from '@emotion/styled';
import theme from '../../../theme';
import ToggleSwitch from '../../../elements/toggle_switch';
import Dropdown from '../../../elements/dropdown';

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
`;

export const ModalTitle = styled.p`
    font-size: ${theme.text.fontSizePrimary};
    font-family: ${theme.text.fontPrimary};
    color: ${theme.text.colorPrimary};
    text-decoration: ${theme.text.decorationPrimary};

    margin-top: 10px;
`;

export const ModalLabel = styled.label`
    font-size: ${theme.text.fontSizeSecondary};
    font-family: ${theme.text.fontSecondary};
    color: ${theme.text.colorSecondary};
    text-decoration: ${theme.text.decorationSecondary};

    ${({gridArea}) => gridArea ? `grid-area: ${gridArea}` : ''}
`;

export const StyledToggleSwitch = styled(ToggleSwitch)`
    height: 30px;
    width: 100%;

    border-radius: 0px;
`;

export const StyledDropdown = styled(Dropdown)`
    margin-bottom: 10px;
`;

export const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
    width: 75%;
    height: 100%;
    margin-bottom: 25px;
    gap: 5px;
`;

export const ErrorText = styled.p`
    grid-area: export-input;

    text-align: center;
    font-size: ${theme.text.fontSizeError};
    color: ${theme.text.colorError};
`;
