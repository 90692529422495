import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { RadioButton } from '../../../../../elements';
import { MapBackgroundContext } from '../../../../../contexts/map_background';
import { getMapFromFloor } from '../../../../../assets/maps';
import { getMapFromFloors } from '../../../../../models/maps';

export default function FloorButtons(props) {
    const {
        floor,
        setFloor
    } = useContext(MapBackgroundContext);

    // Get the buttons for the floors.
    const mapFloors = getMapFromFloor(floor);
    const map = getMapFromFloors(mapFloors);

    const floorButtons = map.floors.map((entry, indx) => {
        const selected = entry === floor;
        const onClick = selected ? () => {} : () => setFloor(entry);

        return (
            <RadioButton key={indx + 1} selected={selected} onClick={onClick}>
                {indx + 1}
            </RadioButton>
        );
    });

    return (
        <FloorButtonWrapper {...props}>
            {map.basement && <BasementButton map={map} />}
            {floorButtons}
            {map.roof && <RoofButton map={map} />}
        </FloorButtonWrapper>
    );
}

function BasementButton({map, ...props}) {

    const {
        floor,
        setFloor
    } = useContext(MapBackgroundContext);

    const selected = map.basement === floor;
    const onClick = selected ? () => {} : () => setFloor(map.basement);

    return (
        <RadioButton {...props} key={0} selected={selected} onClick={onClick}>
            Basement
        </RadioButton>
    );
}

function RoofButton({map, ...props}) {

    const {
        floor,
        setFloor
    } = useContext(MapBackgroundContext);

    const selected = map.roof === floor;
    const onClick = selected ? () => {} : () => setFloor(map.roof);

    return (
        <RadioButton {...props} key={100} selected={selected} onClick={onClick}>
            Roof
        </RadioButton>
    );
}
 
const FloorButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 7px;
`;
