import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import icons from '../../../../assets/icons';

import { get_by_icon, Unknown } from '../../../../models/operators';
import { Filter } from '../../../../elements';
import { IconButton, IconTray } from '../elements';
import { OperatorContext } from '../../../../contexts/operators';
import { filter_doesnt_match, filter_matches, get_matches } from '../../../../models/tags';

export default function OperatorButtons({isMain, currentTrayOperator, selectedOperator, setSelectedOperator, setPlayerColor, ...props}) {

    const {
        operators: selectedOperators,
        attackBan: {
            main: mainAttackBan,
            alt: altAttackBan
        },
        defenceBan: {
            main: mainDefenceBan,
            alt: altDefenceBan
        }
    } = useContext(OperatorContext);
    const bans = [mainAttackBan, altAttackBan, mainDefenceBan, altDefenceBan].filter(operator => operator.name !== Unknown.name);

    const [filter, setFilter] = useState('');

    // Filter icons down to:
    //   - Those that match the user's filter
    //   - Those which belond to an operator
    //   - Those which are not a recruit's
    const nonRecruitOperators = filter_doesnt_match(filter_matches(get_matches(filter), 'operator'), 'recruit');
    const buttonOperators = [...nonRecruitOperators, 'UnknownIcon'];
    const iconButtons = buttonOperators.map((operatorIcon, index) => {
        const operator = get_by_icon(icons[operatorIcon]);
        const operatorAlreadyInTray = selectedOperators.some((popoutOperator) => {
            return (
                popoutOperator.main.name === operator.name 
                    && operator.name !== currentTrayOperator.name
                    && popoutOperator.main.name !== Unknown.name
            ) || ( isMain &&
                    popoutOperator.alt.name === operator.name 
                    && operator.name !== currentTrayOperator.name
                    && popoutOperator.alt.name !== Unknown.name
            );
        });

        const isSelectedOperator = operator.name === selectedOperator.name;
        const isOperatorBanned = bans.includes(operator);

        const onClick = operatorAlreadyInTray || isSelectedOperator || isOperatorBanned ? () => {} : () => {
            // Destructure to guard against user accidentally updating
            // all of the unknown icons at once.
            setSelectedOperator({...operator});
            setPlayerColor(operator.color);
        };

        return (
            <IconButton
                key={index}
                locked={operatorAlreadyInTray}
                selected={isSelectedOperator}
                disabled={isOperatorBanned}
                src={operator.icon}
                alt="Icon Button"
                onClick={onClick}
            />
        );
    });

    return (
        <>
            <StyledFilter filter={filter} setFilter={setFilter} {...props}/>
            <StyledIconTray gridArea="tray" columns="4">
                {iconButtons}
            </StyledIconTray>
        </>
    );
}

const StyledFilter = styled(Filter)`
    grid-area: filter;

    margin-top: 5px;
`;

const StyledIconTray = styled(IconTray)`
    place-items: start;
`;
