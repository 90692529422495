import React, { useState, useContext } from 'react';
import { ModalContext } from '../../../contexts/modal';

import {
    ContentObscure,
    ModalDiv
} from './elements';

export default function Modal() {

    const {
        visible,
        content,
        hideModal,
        modalHeight,
        modalWidth,
        setModalContent
    } = useContext(ModalContext);

    // Create a prop which any modal can see which
    // tells them whether the modal animations has
    // ended.
    const [animationEnded, setAnimationEnded] = useState(false);
    const children = React.Children.map(content, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {animationEnded});
        }

        return child;
    });

    if (!content) return null;

    return (
        <ContentObscure
            visible={visible}
            onMouseDown={hideModal}
            onAnimationEnd={() => {
                if (!visible) {
                    setModalContent(undefined); 
                }
                setAnimationEnded(true);
            }}
        >
            <ModalDiv 
                visible={visible}
                modalHeight={modalHeight}
                modalWidth={modalWidth}
                onMouseDown={(event) => 
                    event.stopPropagation()
                }
            >
                {children}
            </ModalDiv>
        </ContentObscure>
    );
}
