import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import theme from '../../../theme';
import { EXPORT_VERSION } from '../../../constants';

import * as mapAssets from '../../../assets/maps';
import {default as iconAssets} from '../../../assets/icons';
import {default as operatorModels} from '../../../models/operators';
import { Button } from '../../../elements';
import { Highlight, Icon, Line, Rect, Text } from '../../../models/drawables';
import { ModalContext } from '../../../contexts/modal';
import { DrawingContext } from '../../../contexts/drawing';
import { OperatorContext } from '../../../contexts/operators';
import { MapBackgroundContext } from '../../../contexts/map_background';
import Circle from '../../../models/drawables/circle';

import Stenography from '../../../services/stenographyService';

export default function ImportModal(props) {
    const {setFloor} = useContext(MapBackgroundContext);
    const {hideModal} = useContext(ModalContext);
    const {importDrawing} = useContext(DrawingContext);
    const {importOperators, importBans} = useContext(OperatorContext);

    let fileReader;

    const [file, setFile] = useState();
    const [fileJson, setFileJson] = useState();
    const [ignoreVersion, setIgnoreVersion] = useState(false);
    const [incompatibleVersion, setIncompatibleVersion] = useState(false);
    const [buttonPress, setButtonPress] = useState(false);

    const readImportToJson = () => {
        const readJson = JSON.parse(fileReader.result);
        setFileJson(readJson);

        const fileVersion = readJson.version ? readJson.version : 'Unknown Version';
        if (fileVersion !== EXPORT_VERSION) {
            setIncompatibleVersion(true);
        }
    };

    const importFromFile = () => {
        const lines = fileJson.lines
            .map(lineString => JSON.parse(lineString))
            .map(({floor, width, color, points, operator, lineStart, endCap, lineStyle, isFreehand}) => new Line(mapAssets[floor], width, color, points, 1, operator, lineStart, endCap, lineStyle, isFreehand));

        const highlights = fileJson.highlights
            .map(highlightString => JSON.parse(highlightString))
            .map(({floor, color, points, operator}) => new Highlight(mapAssets[floor], color, points, operator));

        const shapes = fileJson.shapes
            .map(shapesString => JSON.parse(shapesString))
            .map(({shapeType, floor, position, size, operator, stroke, strokeWidth, filled}) => {
                switch (shapeType) {
                case 'placeholder':
                case 'rect': {
                    return new Rect(mapAssets[floor], position, size, operator, stroke, strokeWidth, filled);
                }
                case 'circle': {
                    return new Circle(mapAssets[floor], position, size, operator, stroke, strokeWidth, filled);
                }
                default: {
                    console.warn(`WARNING: Unexpected shape on import:${shapeType}. Ignoring`);
                    break;
                }}
            });

        const icons = fileJson.icons
            .map(iconString => JSON.parse(iconString))
            .map(({icon, floor, width, height, rotation, color, position, operator}) => new Icon(iconAssets[icon], mapAssets[floor], width, height, rotation, color, position, operator));

        const text = fileJson.text
            .map(textString => JSON.parse(textString))
            .map(({floor, text, position, operator, color, ...options}) => new Text(mapAssets[floor], text, position, operator, {fontColor: color, ...options}));

        const bans = {
            attack: {
                main: operatorModels[fileJson.bans.attack[0]],
                alt: operatorModels[fileJson.bans.attack[1]]
            },
            defence: {
                main: operatorModels[fileJson.bans.defence[0]],
                alt: operatorModels[fileJson.bans.defence[1]]
            }
        };

        const operators = fileJson.operators.map(({main, alt}) => {
            const mainOperator = {
                ...operatorModels[main.name],
                player: main.player,
                color: main.color
            };
    
            const altOperator = {
                ...operatorModels[alt.name],
                player: alt.player,
                color: alt.color
            };

            return {
                main: mainOperator,
                alt: altOperator
            };
        });

        const secondaryUtility = fileJson.secondaryUtility;
        const exportedFloor = mapAssets[fileJson.setFloor];
        if (exportedFloor) setFloor(exportedFloor);

        importDrawing(lines, highlights, shapes, icons, text);
        importOperators(operators, secondaryUtility);
        importBans(bans.attack, bans.defence);
        hideModal();
    };

    return (
        <ModalBody {...props}>
            <ModalTitle>Import</ModalTitle>
            <ImportWrapper>
                <ModalLabel htmlFor="import-input">Upload Your Strat File</ModalLabel>
                <FileInput
                    id="import-input"
                    type="file"
                    onChange={(event) => {
                        setFile(event.target.files[0]);

                        if (event.target.files[0].name.endsWith('.strat')) {
                            fileReader = new FileReader();
                            fileReader.onload = readImportToJson;
                            fileReader.readAsText(event.target.files[0]);
                        } else if (event.target.files[0].name.endsWith('.png')) {
                            const blobURL = URL.createObjectURL(event.target.files[0]);
                            Stenography.decode(blobURL).then(data => {
                                const readJson = JSON.parse(data);
                                setFileJson(readJson);
                        
                                const fileVersion = readJson.version ? readJson.version : 'Unknown Version';
                                if (fileVersion !== EXPORT_VERSION) {
                                    setIncompatibleVersion(true);
                                }
                            });

                        }
                    }}
                />
                {!file?.name.endsWith('.strat') && !file?.name.endsWith('.png') && <ErrorText>Please Upload a Strat File</ErrorText>}
            </ImportWrapper>
            { incompatibleVersion &&
                <IgnoreVersionWrapper greyOut={buttonPress}>
                    <ErrorText>This is an out of date file. We can still try to import, but things may go wrong. Try anyways?</ErrorText>
                    <IgnoreVersionButtons>
                        <StyledButton disabled={buttonPress} onClick={() => {setIgnoreVersion(true); setButtonPress(true);}}>Yes</StyledButton>
                        <StyledButton disabled={buttonPress} onClick={hideModal}>No</StyledButton>
                    </IgnoreVersionButtons>
                </IgnoreVersionWrapper>
            }
            <ImportButton
                disabled={!fileJson || (incompatibleVersion && !ignoreVersion)}
                onClick={importFromFile}
            >Import</ImportButton>
        </ModalBody>
    );
}

const fadein = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const StyledButton = styled(Button)`
    width: 85px;
`;

const ImportButton = styled(Button)`
    margin-top: auto;
    margin-bottom: 25px;
`;

const IgnoreVersionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    transition: filter 350ms;

    margin: auto 0;

    animation: ${fadein} 350ms;
    filter: ${({greyOut}) => greyOut ? 'grayscale(1)' : 'unset'};
`;

const IgnoreVersionButtons = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    justify-content: space-around;
`;

const ErrorText = styled.p`
    font-size: ${theme.text.fontSizeError};
    font-family: ${theme.text.fontError};
    color: ${theme.text.colorError};
    text-decoration: ${theme.text.decorationError};

    margin-top: 0px;
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 100%;
`;

const ModalTitle = styled.p`
    font-size: ${theme.text.fontSizePrimary};
    font-family: ${theme.text.fontPrimary};
    color: ${theme.text.colorPrimary};
    text-decoration: ${theme.text.decorationPrimary};

    margin-top: 10px;
`;

const ModalLabel = styled.label`
    font-size: ${theme.text.fontSizeSecondary};
    font-family: ${theme.text.fontSecondary};
    color: ${theme.text.colorSecondary};
    text-decoration: ${theme.text.decorationSecondary};

    ${({gridArea}) => gridArea ? `grid-area: ${gridArea}` : ''}
`;

const FileInput = styled.input`
    padding: 10px;
    border: 1px black dotted;
`;

const ImportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
