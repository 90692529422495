import React, { useContext } from 'react';
import styled from '@emotion/styled';
import theme from '../../../../theme';

import { RightPopout } from '../..';
import { OperatorContext } from '../../../../contexts/operators';
import SelectionTile, {BanSelectionTile} from './selection_tile';

export default function OperatorPopout({...props}) {    
    const {operators} = useContext(OperatorContext);

    const selectionIcons = operators.map((operator, index) => (
        <SelectionTile 
            key={`${index}-selection-tile`}
            operator={operator.main}
            altOperator={operator.alt}
            index={index}
        />
    ));
    return (
        <StyledRightPopout
            height="615px"
            hiddenWidth="25px"
            expandedWidth="175px"
            label="Operators & Bans"
            {...props}
        >
            <TrayBody>
                <SecondaryText>Bans</SecondaryText>
                <BansWrapper>
                    <BanSelectionTile isAttacker={true}/>
                    <BanSelectionTile isAttacker={false}/>
                </BansWrapper>
                {selectionIcons}
            </TrayBody>
        </StyledRightPopout>
    );
}

const BansWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: space-evenly;

    height: 65px;
    border-bottom: 2px solid black;
`;

const SecondaryText = styled.p`
    margin: 0;
    place-self: center;

    font-family: ${theme.text.fontSecondary};
    font-size: ${theme.text.fontSizeSecondary};
    color: ${theme.text.colorSecondary};
    text-decoration: ${theme.text.decorationSecondary};
`;

const StyledRightPopout = styled(RightPopout)`
    bottom: 10%;
`;

const TrayBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
