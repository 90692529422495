export const iconWidth = 35;
export const iconHeight = 35;
export const headerHeightValue = 75;
export const headerHeight = `${headerHeightValue}px`;

export const drawMapHeight = 50000;
export const drawMapWidth = 50000;

export const imageMaskHexOpacity = 'a0';

export const OPERATOR_MAX_SECONDARY_UTILITIES = 3;

// Export constants
export const EXPORT_VERSION = '1.3.3';
export const titleStripHeight = 100;
export const operatorStripHeight = 150;
export const leftMargin = 20;
export const rightMargin = 20;
export const verticalMargin = 10;
export const labelTextWidth = 45;
export const iconImageHeight = 60;
export const iconImageWidth = 60;
export const utilityImageHeight = 45;
export const utilityImageWidth = 45;
export const playerNameY = 20;

export const freehandLineExample = [
    [ 5, 20.126050420167516 ],
    [ 5.81570996978775, 18.361344537814574 ],
    [ 6.631419939575499, 16.596638655461632 ],
    [ 7.719033232627679, 14.831932773108694 ],
    [ 9.078549848941519, 13.067226890755753 ],
    [ 11.253776435043108, 11.302521008402811 ],
    [ 13.972809667673557, 9.66386554621867 ],
    [ 16.691842900301236, 8.025210084033247 ],
    [ 19.682779456193344, 6.512605042016624 ],
    [ 23.489425981873204, 5.630252100840153 ],
    [ 27.296072507553063, 5.126050420167517 ],
    [ 31.10271903323292, 5 ],
    [ 34.90936555891278, 5 ],
    [ 38.71601208459264, 5 ],
    [ 42.522658610272494, 5.75630252100767 ],
    [ 45.513595166161835, 7.268907563025577 ],
    [ 47.14501510574011, 9.033613445378517 ],
    [ 48.77643504531837, 10.798319327731459 ],
    [ 49.320241691841694, 12.5630252100844 ],
    [ 49.59214501510613, 14.327731092437341 ],
    [ 49.86404833836779, 16.09243697479028 ],
    [ 50.13595166163221, 17.857142857143224 ],
    [ 50.40785498489387, 19.621848739496166 ],
    [ 50.679758308158306, 21.386554621849108 ],
    [ 50.679758308158306, 23.15126050420205 ],
    [ 50.95166163141997, 24.915966386554988 ],
    [ 51.22356495468163, 26.806722689075446 ],
    [ 52.31117824773381, 28.571428571428388 ],
    [ 54.21450151057374, 30.33613445378133 ],
    [ 56.38972809667809, 32.10084033613427 ],
    [ 58.83685800604134, 33.86554621848721 ],
    [ 62.643504531721206, 34.873949579831205 ],
    [ 66.45015105740106, 35 ],
    [ 70.25679758308092, 35 ],
    [ 74.06344410876078, 34.621848739496166 ],
    [ 77.87009063444064, 33.99159663865473 ],
    [ 81.6767371601205, 33.23529411764706 ],
    [ 85.48338368580035, 31.974789915966753 ],
    [ 88.47432024169247, 30.462184873948846 ],
    [ 90.92145015105848, 28.697478991595904 ],
    [ 92.00906344411067, 26.932773109242962 ],
    [ 92.82477341389841, 25.16806722689002 ],
    [ 93.3685800604245, 23.403361344537082 ],
    [ 93.91238670695058, 21.63865546218414 ],
    [ 94.18429003021225, 19.873949579831198 ],
    [ 95, 18.109243697478256 ]
];
