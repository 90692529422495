import React, { useState } from 'react';
import { get_matches } from '../../../../models/tags';
import icons from '../../../../assets/icons';

import styled from '@emotion/styled';
import { headerHeight } from '../../../../constants';
import { LeftPopout } from '../..';
import { Filter, Tray } from '../../../../elements';
import FavoriteableIconButton from './Favoriteable_icon_button';

export default function IconPopout(props) {
    
    const [userFilters, setUserFilters] = useState('');
    const [favoritedIcons, setFavoritedIcons] = useState([]);

    const favoriteIconButtons = favoritedIcons.map((key) => {
        return (
            <FavoritedIcon 
                key={key}
                src={icons[key]}
                alt={key}
                favorited={true}
                onFavorite={() => setFavoritedIcons([...favoritedIcons, key])}
                onUnfavorite={() => setFavoritedIcons(favoritedIcons.filter((favoriteKey) => favoriteKey !== key))}
            />
        );
    });
    const matchingIcons = get_matches(userFilters).map((key) => {
        const iconIsFavorited = favoritedIcons.includes(key);

        return (
            userFilters === '' && iconIsFavorited ? null : 
                <FavoriteableIconButton 
                    key={key}
                    src={icons[key]}
                    alt={key}
                    favorited={iconIsFavorited}
                    onFavorite={() => setFavoritedIcons([...favoritedIcons, key])}
                    onUnfavorite={() => setFavoritedIcons(favoritedIcons.filter((favoriteKey) => favoriteKey !== key))}
                />
        );
    });

    return (
        <LongLeftPopout
            top={`calc(${headerHeight} + 325px)`}
            height="unset"
            hiddenWidth="25px"
            expandedWidth="330px"
            label="Icons"
            {...props}
        >
            <TrayBody>
                <Filter filter={userFilters} setFilter={setUserFilters} />                
                <TrayContents columns={4} columnWidth='50px'>
                    {userFilters === '' && favoriteIconButtons}
                    {matchingIcons}
                </TrayContents>
            </TrayBody>
        </LongLeftPopout>
    );
}

const FavoritedIcon = styled(FavoriteableIconButton)`

`;

const LongLeftPopout = styled(LeftPopout)`
    bottom: 25px;
`;

const TrayContents = styled(Tray)`
    padding-top: 5px;
    padding-bottom: 5px;

    height: 100%;
`;

const TrayBody = styled.div`
    height: calc(100% - 40px);
    width: 89%;
    margin: 5px 0px 5px 4px;
    
    display: flex;
    flex-direction: column;

    overflow: hidden;
`;
