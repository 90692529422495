import { Bank } from '../../models/maps';

export const initialState = {
    mapLocked: false,
    floor: Bank.floors[0],
    hoverX: 0,
    hoverY: 0,
    mapHeight: 0,
    mapWidth: 0,
    mapScale: 1,
    panX: 0,
    panY: 0,
    initialLoad: true,
    mapCroppingLocked: true,
    mapCropping: {x: 0, y: 0, height: 0, width: 0}
};

export const MapBackgroundActions = {
    lockViewport: 'lock_viewport',
    unlockViewport: 'unlock_viewport',
    setMap: 'set_map',
    setFloor: 'set_floor',
    changeHoverPosition: 'change_hover_position',
    resizeMap: 'resize_map',
    zoomMap: 'zoom_map',
    resetMapZoom: 'reset_map_zoom',
    panMap: 'pan_map',
    resetMapPan: 'reset_map_pan',
    finishInitialLoad: 'finish_initial_load',
    startMapCropping: 'start_map_cropping',
    extendMapCropping: 'extend_map_cropping',
    finishMapCropping: 'finish_map_cropping',
    lockMapCropping: 'lock_map_cropping',
    unlockMapCropping: 'unlock_map_cropping'
};

export const MapBackgroundReducer = (state, action) => {
    switch (action.type) {
    case MapBackgroundActions.lockViewport: {
        return {
            ...state,
            mapLocked: true,
        };
    }
    case MapBackgroundActions.unlockViewport: {
        return {
            ...state,
            mapLocked: false,
        };
    }
    case MapBackgroundActions.setMap: {
        return {
            ...state,
            floor: action.map.floors[0]
        };
    }
    case MapBackgroundActions.setFloor: {
        return {
            ...state,
            floor: action.floor
        };
    }
    case MapBackgroundActions.changeHoverPosition: {
        return {
            ...state,
            hoverX: action.x,
            hoverY: action.y
        };
    }
    case MapBackgroundActions.resizeMap: {
        return {
            ...state,
            mapHeight: action.height,
            mapWidth: action.width
        };
    }
    case MapBackgroundActions.zoomMap: {
        return {
            ...state,
            mapScale: Math.max((state.mapScale + action.scaleUpdate).toFixed(2), 0.5)
        };
    }
    case MapBackgroundActions.resetMapZoom: {
        return {
            ...state,
            mapScale: initialState.mapScale
        };
    }
    case MapBackgroundActions.panMap: {
        return {
            ...state,
            panX: state.panX + action.dx,
            panY: state.panY + action.dy
        };
    }
    case MapBackgroundActions.resetMapPan: {
        return {
            ...state,
            panX: initialState.panX,
            panY: initialState.panY
        };
    }
    case MapBackgroundActions.finishInitialLoad: {
        return {
            ...state,
            initialLoad: false
        };
    }
    case MapBackgroundActions.startMapCropping: {
        return {
            ...state,
            mapCropping: {
                x: action.x,
                y: action.y,
                width: 0,
                height: 0
            }
        };
    }
    case MapBackgroundActions.extendMapCropping: {
        return {
            ...state,
            mapCropping: {
                ...(state.mapCropping),
                width: state.mapCropping.width + action.dx,
                height: state.mapCropping.height + action.dy
            }
        };
    }
    case MapBackgroundActions.lockMapCropping: {
        return {
            ...state,
            mapCroppingLocked: true
        };
    }
    case MapBackgroundActions.unlockMapCropping: {
        const event = new Event('croppingUnlocked');
        document.dispatchEvent(event);

        return {
            ...state,
            mapCroppingLocked: false
        };
    }
    default:
        console.log(`WARNING: Invalid action ${action.type} provided.`);
        return state;
    }
};

