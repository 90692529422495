import React, { useContext } from 'react';
import {v4} from 'uuid';

import { DrawingContext } from '../../contexts/drawing';
import { MapBackgroundContext } from '../../contexts/map_background';
import { ToolOptionsContext } from '../../contexts/tool_options';
import { OperatorContext } from '../../contexts/operators';

// A rectd drawable object. To get a basic 
// starting position for a new drawable, simply
// replace 'rect' => newThing, 'Rect' => NewThing
function SVGRect(rect, {...props}) {
    const {floor} = useContext(MapBackgroundContext);
    const {tool, eraseShapes} = useContext(ToolOptionsContext);
    const {removeShape, mouseDown} = useContext(DrawingContext);
    const {operators, mainOperatorVisible} = useContext(OperatorContext);

    if (floor !== rect.floor) return null;
    else if (rect.operator !== null && !operators.map(({main, alt}, index) => mainOperatorVisible[index] ? main.name : alt.name).includes(rect.operator)) return null;

    const rectOperator = operators
        .map(({main, alt}) => [main, alt])
        .flat()
        .filter(({name}) => { return name === rect.operator;})?.[0];

    const [x, y] = rect.relativePosition;
    const [width, height] = rect.size;
    const realX = width >= 0 ? x : x + width;
    const realY = height >= 0 ? y : y + height;
    const realWidth = Math.abs(width);
    const realHeight = Math.abs(height);

    const shouldErase = tool === 'Eraser' && eraseShapes;
    const color = rectOperator ? rectOperator.color : rect.stroke;
    return (
        <g
            id={`rect-${rect.id}`}
            onMouseOver={() => {
                if (shouldErase && mouseDown.left) removeShape(rect.id);
            }}
            onMouseDown={e => {
                if (shouldErase && e.button === 0) removeShape(rect.id);
            }}

            {...props}
        >
            <rect
                x={realX}
                y={realY}
                width={realWidth}
                height={realHeight}
                stroke={color}
                strokeWidth={rect.strokeWidth}
                fill={rect.filled ? color : 'none'}
            />
        </g>
    );
}

export default class Rect {
    constructor(floor, position, size, operator, color, thickness, filled=false) {
        this.id = v4();
        this.shapeType = 'rect';
        this.floor = floor;
        this.position = position;
        this.operator = operator;
        this.size = size;
        this.stroke = color;
        this.strokeWidth = thickness;
        this.filled = filled;

        this.relativePosition = position;
        this.render = (props) => SVGRect(this, props);
    }

    getRelative(xOffset, yOffset) {
        const [x, y] = this.position;
        this.relativePosition = [x + xOffset, y + yOffset];
        return this;
    }

    resize(dx, dy) {
        const [width, height] = this.size;
        this.size = [width + dx, height + dy];

        return this;
    }
}
