import React, { useCallback, useContext } from 'react';

import { MapBackgroundContext } from '../../contexts/map_background';
import { DrawingContext } from '../../contexts/drawing';
import { ToolOptionsContext } from '../../contexts/tool_options';
import {drawMapHeight, drawMapWidth} from '../../constants';
import MinimalMap from './minimalMap';

import BoundingBox from './BoundingBox';
import styled from '@emotion/styled';
import { ContextMenuContext } from '../../contexts/context_menu';
import MapContextMenu from './mapContextMenu';
import {RecenterIcon} from '../../assets';
import theme from '../../theme';

export default function Map(props) {
    const {
        mapLocked,
        changeHoverPosition,
        setMapScale,
        mapScale,
        panX,
        panY,
        panMap,
        extendMapCropping,
        mapHeight: boundingBoxHeight,
        mapWidth: boundingBoxWidth
    } = useContext(MapBackgroundContext);

    const {
        tool,
    } = useContext(ToolOptionsContext);

    const {
        mouseDown,
        extendLine,
        extendHighlight,
        resizeShape
    } = useContext(DrawingContext);

    const {
        setContextMenuContent,
        openContextMenu
    } = useContext(ContextMenuContext);
    
    const cursor = {
        'Cursor': 'move',
        'Icon': 'crosshair',
        'Pencil': 'default',
        'Eraser': 'default',
        'Highlighter': 'default',
        'Text': 'text',
        'Cropper': 'crosshair'
    };

    const onMouseMove = useCallback(event => {
        event.stopPropagation();
        
        const screenLeft = (boundingBoxWidth - drawMapWidth) / 2;
        const screenTop = (boundingBoxHeight - drawMapHeight) / 2;

        // To calculate scaling, find the mouse's distance
        // from the center of the screen.
        // Divide that value by the scale value to get the
        // projection relative to center.
        // Finally, calculate this value relative to top left,
        // and that's the new X, Y
        const mouseX = event.nativeEvent.offsetX;
        const mouseY = event.nativeEvent.offsetY;
        const centerX = (boundingBoxWidth / 2);
        const centerY = (boundingBoxHeight / 2);
        const centerToMouseDeltaX = mouseX - centerX - panX;
        const centerToMouseDeltaY = mouseY - centerY - panY;
        const mouseXProjection = centerToMouseDeltaX / mapScale;
        const mouseYProjection = centerToMouseDeltaY / mapScale;
        const projectionFromLeft = centerX + mouseXProjection;
        const projectionFromTop = centerY + mouseYProjection;

        const newHoverX = projectionFromLeft - screenLeft;
        const newHoverY = projectionFromTop - screenTop;

        if (mouseDown.left) {
            if (tool === 'Cursor') panMap(event.movementX, event.movementY);
            else if (tool === 'Pencil') extendLine(newHoverX, newHoverY);
            else if (tool === 'Highlighter') extendHighlight(newHoverX, newHoverY);
            else if (tool === 'Shape') resizeShape(event.movementX / mapScale, event.movementY / mapScale);
            else if (tool === 'Cropper') extendMapCropping(event.movementX / mapScale, event.movementY / mapScale);
        } else if (mouseDown.middle) {
            panMap(event.movementX, event.movementY);
        }

        if (!mapLocked) {
            changeHoverPosition(newHoverX, newHoverY);
        }
    });

    return (
        <>
            <BoundingBox {...props} 
                onWheel={e => {
                    // If deltaY is positive, zoom in
                    // If shift key is held, 10x for faster control
                    const scaleDelta = .1 * (e.deltaY > 0 ? -1 : 1) * (e.shiftKey ? 10 : 1);
                    setMapScale(scaleDelta);
                }}
                onContextMenu={e => {
                    e.preventDefault();

                    setContextMenuContent(<MapContextMenu />);
                    openContextMenu(e.clientX, e.clientY);
                }}
            >
                <DrawBox
                    id='draw-box'

                    tool={tool}
                    style={{
                        cursor: cursor[tool],
                    }}
                    onMouseMove={onMouseMove}

                    width={drawMapWidth}
                    height={drawMapHeight}
                    x={(boundingBoxWidth - drawMapWidth) / 2}
                    y={(boundingBoxHeight - drawMapHeight) / 2}
                >
                    <g transform-origin='center center' transform={`translate(${panX} ${panY}) scale(${mapScale}, ${mapScale})`}>
                        <rect id='size-rect' height={drawMapHeight} width={drawMapWidth} x='0' y='0' fillOpacity='0'/>
                        <MinimalMap id='minimal-map'/>
                    </g>
                </DrawBox>
            </BoundingBox> 
            {(panX !== 0 || panY !== 0) &&
                <RecenterDiv>
                    <StyledRecenterIcon onClick={() => panMap(-panX, -panY)}/>
                </RecenterDiv>
            }
        </>
    );
}

const DrawBox = styled.svg`
    transform-origin: 50% 50%;

    border: 1px dotted red;
`;

const RecenterDiv = styled.div`
    height: 35px;
    width: 35px;
    border-radius: 50%;

    display: grid;
    place-items: center;

    background-color: ${theme.colors.UI300};

    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: ${theme.zIndices.recenterIcon};

    box-shadow: ${theme.shadows.UI300};

    cursor: pointer;
    :hover {
        filter: brightness(1.3);
    }
`;

const StyledRecenterIcon = styled(RecenterIcon)`
    height: 25px;
    width: 25px;
`;
