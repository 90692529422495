import React, { useContext, useEffect, useState } from 'react';

import { BottomPopout } from '../..';
import styled from '@emotion/styled';
import {OperatorContext} from '../../../../contexts/operators';
import UtilityTile from './operator_tile';
import ToolWindow from './tool_window';
import AnimatedDiv from '../../../animated_div';
import { keyframes } from '@emotion/react';
import icons from '../../../../assets/icons';
import { DrawingContext } from '../../../../contexts/drawing';
import { iconToUtility } from '../../../../models/utility';

function getElementRelativePosition(parentID, childID) {
    const parent = document.getElementById(parentID);
    const child = document.getElementById(childID);

    const parentPos = parent.getBoundingClientRect();
    const childPos = child.getBoundingClientRect();

    const relativePosition = {
        top: `${Math.round(childPos.top - parentPos.top)}px`,
        bottom: `${Math.round(parentPos.bottom - childPos.bottom)}px`,
        left: `${Math.round(childPos.left - parentPos.left)}px`,
        right: `${Math.round(parentPos.right - childPos.right)}px`,
    };

    return relativePosition;
}

const utilityPopoutTop = 25;
export default function UtilityPopout({...props}) {
    const {icons: drawnIcons} = useContext(DrawingContext);
    const {operators, secondaryUtility, visibleOperators, mainOperatorVisible} = useContext(OperatorContext);
    const [toolWindowVisible, setToolWindowVisible] = useState(false);
    const [toolWindowOperatorIndex, setToolWindowOperatorIndex] = useState(undefined);
    const [computedAnimationIn, setComputedAnimationIn] = useState(undefined);

    useEffect(() => {
        if (toolWindowOperatorIndex !== undefined) {
            const startPosition = (toolWindowOperatorIndex === undefined) ? 
                {top: 0, bottom: 0, left: 0, right: 0} 
                :
                getElementRelativePosition('operator-tools-popout', `utility-tile-${toolWindowOperatorIndex}`);

            setComputedAnimationIn(animationIn(startPosition));
        }
    }, [toolWindowOperatorIndex]);

    const visibleUtilityTotals = visibleOperators.reduce((accumulator, operator, index) => {
        const operatorSecondaryIndex = mainOperatorVisible[index] ? secondaryUtility[index].main : secondaryUtility[index].alt;

        Object.entries(operator.utilityCounts(operatorSecondaryIndex)).forEach(([utilityName, utilityCount]) => {
            if (utilityName in accumulator) accumulator[utilityName] += utilityCount;
            else accumulator[utilityName] = utilityCount;
        });

        return accumulator;
    }, {});
    const visibleUtilityRemainders = drawnIcons.reduce((accumulator, icon) => {
        const thisUtility = iconToUtility[icon.icon];
        const visibleOperatorNames = visibleOperators.map(({name}) => name);
        if (thisUtility in accumulator && visibleOperatorNames.includes(icon.operator)) accumulator[thisUtility] -= 1;

        return accumulator;
    }, {...visibleUtilityTotals});

    const operatorName = toolWindowOperatorIndex !== undefined && (mainOperatorVisible[toolWindowOperatorIndex] ? operators[toolWindowOperatorIndex].main.name : operators[toolWindowOperatorIndex].alt.name);
    return (
        <TransformedBottomPopout
            id='operator-tools-popout'
            left="50%"
            width="700px"
            hiddenHeight={`${utilityPopoutTop}px`}
            expandedHeight="200px"
            label={toolWindowOperatorIndex === undefined ? 'Operator Utility' : `${operatorName} Utility`}
        >
            <TrayBody {...props}>
                {operators.map(({main, alt}, index) => 
                    <UtilityTile
                        id={`utility-tile-${index}`}
                        key={index}
                        openToolWindow={() => {setToolWindowOperatorIndex(index); setToolWindowVisible(true);}}
                        operator={mainOperatorVisible[index] ? main : alt} 
                    />
                )}
            </TrayBody>
            <GlobalCountWrapper>
                {Object.entries(visibleUtilityTotals).map(([utilityName, count]) => 
                    count !== 0 && isFinite(count) &&
                        <GlobalCountTile key={utilityName}>
                            <IconImage src={icons[utilityName]}/>
                            <CountText positive={count > 0}>{visibleUtilityRemainders[utilityName]}/{count}</CountText>
                        </GlobalCountTile>
                )}
            </GlobalCountWrapper>
            <ToolWindowWrapper
                display={toolWindowVisible}
                animationIn={computedAnimationIn}
                animationOut={animationOut}
                cleanUp={() => setToolWindowOperatorIndex(undefined)}
            >
                <ToolWindow 
                    operatorIndex={toolWindowOperatorIndex}
                    closeToolWindow={() => setToolWindowVisible(false)}
                />
            </ToolWindowWrapper>
        </TransformedBottomPopout>
    );
}

const TransformedBottomPopout = styled(BottomPopout)`
    transform: translate(-50%, 0);
`;

const ToolWindowWrapper = styled(AnimatedDiv)`
    position: absolute;
    top: ${utilityPopoutTop}px;
    bottom: 0;
    left: 0;
    right: 0;

    animation-duration: 300ms;
`;

const animationIn = (startPosition) => keyframes`
    from {
        top: ${startPosition.top};
        bottom: ${startPosition.bottom};
        left: ${startPosition.left};
        right: ${startPosition.right};
    }
    to {
        top: ${utilityPopoutTop}px;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

const animationOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const TrayBody = styled.div`
    height: 125px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    margin-top: 6px;
`;

const GlobalCountWrapper = styled.div`
    width: 90%;
    height: 50px;
    margin: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

const GlobalCountTile = styled.div`
    height: 100%;
    border: 2px solid #aaa;
    padding: 1px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

const IconImage = styled.img`
    height: 30px;
    width: 30px;

    filter: invert(1);
`;

const CountText = styled.p`
    color: ${({positive}) => positive ? 'black' : 'red'};
    font-weight: bold;
    margin: unset;
    padding: unset;
`;
