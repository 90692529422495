import React from 'react';
import { ModalProvider } from './modal';
import { MapBackgroundProvider } from './map_background';
import { ToolOptionsProvider } from './tool_options';
import { DrawingProvider } from './drawing';
import { OperatorProvider } from './operators';
import { OverlayProvider } from './overlay';
import { ContextMenuProvider } from './context_menu';

export default function GlobalContext({children}) {
    return (
        <ContextMenuProvider>
            <OverlayProvider>
                <ModalProvider>
                    <MapBackgroundProvider>
                        <ToolOptionsProvider>
                            <DrawingProvider>
                                <OperatorProvider>
                                    {children}
                                </OperatorProvider>
                            </DrawingProvider>
                        </ToolOptionsProvider>
                    </MapBackgroundProvider>
                </ModalProvider>
            </OverlayProvider>
        </ContextMenuProvider>
    );
}
