import React, { useContext } from 'react';
import styled from '@emotion/styled';

import Cell from '../context_menu/cell';
import { ToolOptionsContext } from '../../contexts/tool_options';
import {MapBackgroundContext} from '../../contexts/map_background';

export default function MapContextMenu({...props}) {

    const {mapLocked, lockViewport, unlockViewport} = useContext(MapBackgroundContext);
    const {tool} = useContext(ToolOptionsContext);

    return (
        <ContextMenuBody {...props}>
            { tool === 'Icon' && 
                <Cell
                    key={mapLocked ? 'Unlock-Icon-Preview' : 'Lock-Icon-Preview'}
                    onClick={mapLocked ? unlockViewport : lockViewport}
                >
                    {mapLocked ? 'Unlock Icon Preview' : 'Lock Icon Preview'}
                </Cell>
            }
        </ContextMenuBody>
    );
}

const ContextMenuBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    align-items: flex-start;
    justify-content: flex-start;
`;
