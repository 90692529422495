import React, {createContext, useCallback, useMemo, useReducer} from 'react';

import {
    initialState,
    OperatorActions,
    OperatorReducer
} from '../../reducers/operators';

export const OperatorContext = createContext();

export const OperatorProvider = ({children}) => {
    const [state, dispatch] = useReducer(OperatorReducer, initialState);

    const setOperator = useCallback(
        (index, operator, updateMain) => dispatch({type: OperatorActions.setOperator, index, operator, updateMain}),
        [dispatch]
    );
    const setMainOperatorVisibility = useCallback(
        (index, visibility) => dispatch({type: OperatorActions.setMainOperatorVisibility, index, visibility}),
        [dispatch]
    );
    const setSecondaryUtility = useCallback(
        (index, secondaryUtility, updateMain) => dispatch({type: OperatorActions.setSecondaryUtility, index, secondaryUtility, updateMain}),
        [dispatch]
    );
    const setAttackBan = useCallback(
        (ban, updateMain) => dispatch({type: OperatorActions.setAttackBan, ban, updateMain}),
        [dispatch]
    );
    const setDefenceBan = useCallback(
        (ban, updateMain) => dispatch({type: OperatorActions.setDefenceBan, ban, updateMain}),
        [dispatch]
    );
    const importOperators = useCallback(
        (operators, secondaryUtility) => dispatch({type: OperatorActions.importOperators, operators, secondaryUtility}),
        [dispatch]
    );
    const importBans = useCallback(
        (attack, defence) => dispatch({type: OperatorActions.importBans, attack, defence}),
        [dispatch]
    );
    const resetOperators = useCallback(
        () => dispatch({type: OperatorActions.resetOperators}),
        [dispatch]
    );
    const resetBans = useCallback(
        () => dispatch({type: OperatorActions.resetBans}),
        [dispatch]
    );

    const visibleOperators = useMemo(() => {
        return Object.values(state.operators).map((operator, operatorIndex) => {
            return state.mainOperatorVisible[operatorIndex] ? operator.main : operator.alt;
        });
    }, [state.mainOperatorVisible, state.operators]);

    const value = useMemo(() => {
        return {
            ...state,
            visibleOperators,
            setOperator,
            setMainOperatorVisibility,
            setSecondaryUtility,
            setAttackBan,
            setDefenceBan,
            importOperators,
            importBans,
            resetOperators,
            resetBans
        };
    }, [
        state,
        visibleOperators,
        setOperator,
        setMainOperatorVisibility,
        setSecondaryUtility,
        setAttackBan,
        setDefenceBan,
        importOperators,
        importBans,
        resetOperators,
        resetBans,
    ]);

    return (
        <OperatorContext.Provider value={value}>
            {children}
        </OperatorContext.Provider>
    );
};
