import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import theme from '../../theme';

import AnimatedDiv from '../animated_div';
import {ContextMenuContext} from '../../contexts/context_menu';

export default function ContextMenu({...props}) {
    
    const {
        open: contextMenuOpen,
        content: contextMenuContent,
        x: contextMenuX,
        y: contextMenuY,
        closeContextMenu
    } = useContext(ContextMenuContext);
    
    const [cellCount, setCellCount] = useState(0);
    const [dividerCount, setDividerCount] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            const documentCellCount = document.querySelectorAll('#context-menu-cell').length;
            const documentDividerCount = document.querySelectorAll('#context-menu-divider').length;
            setCellCount(documentCellCount);
            setDividerCount(documentDividerCount);
    
            if (documentCellCount === 0) {
                closeContextMenu();
            }
        }, 25);
    }, [contextMenuOpen]);

    return (
        <ContextMenuDiv {...props} 
            top={contextMenuY - 2}
            left={contextMenuX - 2}
            onClick={() => closeContextMenu()}
            onMouseLeave={() => closeContextMenu()}
            onContextMenu={e => e.preventDefault()}
            cellCount={cellCount}
            dividerCount={dividerCount}

            // AnimatedDiv props
            display={contextMenuOpen}
            animationIn={animationIn(cellCount)}
            animationOut={animationOut}
        >
            {contextMenuContent}
        </ContextMenuDiv>
    );
}

const animationIn = cellCount => keyframes`
    from {
        height: 0px
    }

    to {
        height: ${theme.sizing.contextMenuCellHeight * cellCount}px;
    }
`;

const animationOut = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

const ContextMenuDiv = styled(AnimatedDiv)`
    position: absolute;
    width: 200px;
    height: ${({cellCount, dividerCount}) => (theme.sizing.contextMenuCellHeight * cellCount) + (theme.sizing.contextMenuDividerHeight * dividerCount)}px;
    overflow: hidden;

    ${({cellCount}) => cellCount > 0 && `
        border: 1px solid ${theme.colors.UI400};
        padding: 2px 0px;
    `}

    top: ${({top}) => top}px;
    left: ${({left}) => left}px;

    background: linear-gradient(to bottom, ${theme.colors.UI100}, ${theme.colors.UI300});
    z-index: ${theme.zIndices.contextMenu};
    drop-shadow: ${theme.shadows.UI200};
    border-radius: 3px;

    animation-duration: ${({display}) => display ? '300ms' : '150ms'};
`;
