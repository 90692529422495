import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import theme from '../../../theme';

const ModalFadein = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const ModalFadeOut = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

const ModalSlideIn = keyframes`
    from {
        top: 65%;
    }

    to {
        top: 50%;
    }
`;

const ModalSlideOut = keyframes`
    from {
        top: 50%;
    }

    to {
        top: 60%;
    }
`;

export const ContentObscure = styled.div`
    z-index: ${theme.zIndices.contentObscure};
    background-color: rgba(0, 0, 0, .5);
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    animation-name: ${({visible}) => visible ? ModalFadein : ModalFadeOut};
    animation-duration: 250ms;
    animation-fill-mode: both;
`;

export const ModalDiv = styled.div`
    z-index: ${theme.zIndices.modal};
    background-color: ${theme.colors.UI200};
    border-radius: 5px;
    box-shadow: ${theme.shadows.UI100};
    height: ${({modalHeight}) => modalHeight}px;
    width: ${({modalWidth}) => modalWidth}px;
    padding: 3px;

    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation-name: ${({visible}) => visible ? ModalSlideIn : ModalSlideOut};
    animation-duration: 150ms;
    animation-fill-mode: both;
    animation-delay: 0ms;
`;
