export function getDrawnFloors(lines, highlights, shapes, icons, text) {
    const floorsWithLines = lines.map(({floor}) => floor);
    const floorsWithHighlights = highlights.map(({floor}) => floor);
    const floorsWithShapes = shapes.map(({floor}) => floor);
    const floorsWithIcons = icons.map(({floor}) => floor);
    const floorsWithText = text.map(({floor}) => floor);

    const uniqueFloors = new Set([
        ...floorsWithLines,
        ...floorsWithHighlights,
        ...floorsWithShapes,
        ...floorsWithIcons,
        ...floorsWithText
    ]);

    return Array.from(uniqueFloors);
}
