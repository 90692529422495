import React from 'react';
import IconPopout from './icon_popout';

import MapPopout from './map_popout';
import OperatorPopout from './operator_popout';
import ToolPopout from './tool_popout';
import ToolPreviewWindow from './tool_preview_window';
import UtilityPopout from './utility_popout';

export default function SiegeOverlay() {
    return (
        <>
            <MapPopout />
            <ToolPopout />
            <IconPopout />
            <OperatorPopout />
            <UtilityPopout />

            <ToolPreviewWindow />
        </>
    );
}

