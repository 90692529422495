export const initialState = {
    currentOverlay:'Siege'
};

export const OverlayActions = {
    changeOverlay: 'change_overlay'
};

export const OverlayReducer = (state, action) => {
    switch (action.type) {
    case OverlayActions.changeOverlay: {
        return {
            ...state,
            currentOverlay: action.overlay
        };
    }
    default:
        console.log(`WARNING: Invalid action ${action.type} provided.`);
        return state;
    }
};
