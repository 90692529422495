import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { Helmet } from 'react-helmet';

import TwitterMetaImage from './assets/TwitterMetaImage.png';

import Header from './components/header';
import Map from './components/map';
import Modal from './components/modals/modal';
import SiegeOverlay from './components/overlays/siege';
import ExportOverlay from './components/overlays/export';
import LoadingScreen from './components/loading_screen';
import ContextMenu from './components/context_menu';

import { ToolOptionsContext } from './contexts/tool_options';
import { OverlayContext } from './contexts/overlay';
import { ModalContext } from './contexts/modal';

import { headerHeight } from './constants';

export default function App() {
    const {
        setTool
    } = useContext(ToolOptionsContext);

    const {
        hideModal
    } = useContext(ModalContext);

    const {
        currentOverlay
    } = useContext(OverlayContext);

    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: 'AIzaSyAL26tXyQmVOJb_9g9-Ces9E-71CTLCPkY',
        authDomain: 'stratbook-4a0a1.firebaseapp.com',
        projectId: 'stratbook-4a0a1',
        storageBucket: 'stratbook-4a0a1.appspot.com',
        messagingSenderId: '929367178066',
        appId: '1:929367178066:web:f04aa9ad9c32452d04988b',
        measurementId: 'G-4TELHB5NXW'
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);

    return (
        <AppDiv
            onKeyDown={event => {
                if (event.key === 'Escape') {
                    hideModal();
                    setTool('Cursor');
                }
            }}
            tabIndex='0'
        >
            <Helmet>
                <title>StratbookGG: Create Strategies for Rainbow Six: Siege</title>
                <meta
                    name='description'
                    content="Create R6:S strats on any competitive map. Manage your primary/alternate operators and bans, and even export a generated image!"
                />
                <meta
                    name='keywords'
                    content={[
                        'stratbook',
                        'r6 strats', 
                        'r6 strat tool',
                        'r6 stratbook',
                        'siege strats',
                        'siege strat tool',
                        'siege stratbook',
                        'r6 strategies',
                        'r6 strategy tool',
                        'siege strategies',
                        'siege strategy tool',
                        'rainbow six siege strats',
                        'rainbow six siege strat tool',
                        'rainbow six siege stratbook',
                        'rainbow six siege strategies',
                        'rainbow six siege strategy tool'
                    ].join(',')}
                />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="StratbookGG: Create Strategies for Rainbow Six: Siege" />
                <meat name="twitter:description" content="Create R6:S strats on any competitive map. Manage your primary/alternate operators and bans, and even export a generated image!" />
                <meta name="twitter:site" content="@StratbookGG" />
                <meta name="twitter:creator" content="@MartyrForHire" />
                <meta name="twitter:image:src" content={TwitterMetaImage} />

                <meta property='og:title' content='StratbookGG: Create Strategies for Rainbow Six: Siege' />
                <meta property='og:type' content="website" />
                <meta property="og:url" content="https://stratbook.gg" />
                <meta property="og:description" content="Create R6:S strats on any competitive map. Manage your primary/alternate operators and bans, and even export a generated image!" />
                <meta property="og:site_name" content="StratbookGG" />
                <meta property="og:image" content={TwitterMetaImage} />
            </Helmet>
            <LoadingScreen />
            <Header />
            <Body>
                <Map />
            </Body>
            { currentOverlay === 'Siege' && <SiegeOverlay />}
            { currentOverlay === 'Export' && <ExportOverlay />}
            <Modal />
            <ContextMenu />
        </AppDiv>
    );
}

const AppDiv = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    
    overflow: hidden;
    background-color: #35637c;
`;

const Body = styled.div`
    position: relative;

    height: calc(100vh - ${headerHeight});
    width: 100vw;
`;
