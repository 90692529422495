import React from 'react';
import styled from '@emotion/styled';
import { Button } from '..';
import theme from '../../theme';

export default function RadioButton({children, ...props}) {
    return (
        <StyledButton {...props}>
            {children}
        </StyledButton>
    );
}

const StyledButton = styled(Button)`
    
    height: 30px;
    width: 100px;
    margin: 0px 5px 2px 0px;
    padding: 0px 5px 0px 5px;

    ${({selected}) => selected ? `
        :hover {
            background-color: ${theme.colors.buttonPress};
            color: white;
            cursor: default;
        }
        background-color: ${theme.colors.buttonPress};
        color: white;
        cursor: default;
    ` : `
        :hover {
            cursor: pointer;
            background: ${theme.colors.buttonHover}
        }
    `}
`;
