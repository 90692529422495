import React, { useContext, useEffect, useMemo } from 'react';

import {drawMapHeight, drawMapWidth} from '../../constants';
import { MapBackgroundContext } from '../../contexts/map_background';
import { DrawingContext } from '../../contexts/drawing';
import Floor from '../../models/drawables/floor';
import PaperTexture from '../../assets/paper-texture.jpg';
import useBase64 from '../../hooks/useBase64';
import { ToolOptionsContext } from '../../contexts/tool_options';

export default function MinimalMap({transform=null, ...props}) {
    const {floor, mapWidth, mapHeight, mapCroppingLocked, mapCropping} = useContext(MapBackgroundContext);
    const {tool} = useContext(ToolOptionsContext);
    const {icons, lines, highlights, text, shapes} = useContext(DrawingContext);

    // Paper Image Encoding
    const [encodedBackgroundLoading, backgroundEncoding] = useBase64(PaperTexture);
    const paperHref = encodedBackgroundLoading ? backgroundEncoding : PaperTexture;

    const drawables = [...icons, ...lines, ...highlights, ...text, ...shapes];
    const FloorObject = useMemo(() => new Floor(floor, drawables), [floor]);

    useEffect(() => {
        FloorObject.changeDrawables(drawables);
    }, [JSON.stringify(drawables)]);
    
    useEffect(() => {
        FloorObject.changeTransform(transform);
    }, [transform]);

    const cropX = mapCropping.width < 0 ? mapCropping.x + mapCropping.width : mapCropping.x;
    const cropY = mapCropping.height < 0 ? mapCropping.y + mapCropping.height : mapCropping.y;
    const cropWidth = Math.abs(mapCropping.width);
    const cropHeight = Math.abs(mapCropping.height);
    return (
        <svg {...props} width={mapWidth} height={mapHeight} x={(drawMapWidth - mapWidth) / 2} y={(drawMapHeight - mapHeight) / 2}>
            <g id='original-floor-map'>
                <image href={paperHref} width={mapWidth} height={mapHeight} x={0} y={0} preserveAspectRatio='none' />
                {<FloorObject.render />}
            </g>

            {tool === 'Cropper' &&
                <path
                    fill='#000000c0'
                    fillRule='evenodd'
                    stroke='black'
                    d={`M 0 0 H ${mapWidth} V ${mapHeight} H 0 Z M ${cropX} ${cropY} H ${cropX + cropWidth} V ${cropY + cropHeight} H ${cropX} Z`}
                />
            }
            {!mapCroppingLocked &&
                <g>
                    <svg
                        id='cropped-background'
                        viewBox={`${cropX} ${cropY} ${cropWidth} ${cropHeight}`}
                        width={mapWidth}
                        height={mapHeight}
                        x='0'
                        y='0'
                    >
                        <use href='#original-floor-map' />
                    </svg>
                </g>
            }
        </svg>
    );
}
