const tags = {
    AceIcon: ['ace', 'icon', 'operator', 'attacker', 'hardbreach'],
    AlibiIcon: ['alibi', 'icon', 'operator', 'defender', 'info'],
    AmaruIcon: ['amaru', 'icon', 'operator', 'attacker'],
    AruniIcon: ['aruni', 'icon', 'operator', 'defender', 'burn'],
    AshIcon: ['ash', 'icon', 'operator', 'attacker', 'entry', 'softbreach'],
    AzamiIcon: ['azami', 'icon', 'operator', 'defender', 'denial'],
    BanditIcon: ['bandit', 'icon', 'operator', 'defender', 'denial'],
    BlackbeardIcon: ['blackbeard', 'icon', 'operator', 'attacker'],
    BlitzIcon: ['blitz', 'icon', 'operator', 'attacker'],
    BravaIcon: ['brava', 'icon', 'operator', 'attacker', 'info'],
    BuckIcon: ['buck', 'icon', 'operator', 'attacker', 'softbreach'],
    CapitaoIcon: ['capitao', 'icon', 'operator', 'attacker'],
    CastleIcon: ['castle', 'icon', 'operator', 'defender'],
    CaveiraIcon: ['caveira', 'icon', 'operator', 'defender'],
    ClashIcon: ['clash', 'icon', 'operator', 'defender'],
    DeimosIcon: ['deimos', 'icon', 'operator', 'attacker', 'anti-roam'],
    DocIcon: ['doc', 'icon', 'operator', 'defender', 'healer'],
    DokkaebiIcon: ['dokkaebi', 'icon', 'operator', 'attacker', 'info'],
    EchoIcon: ['echo', 'icon', 'operator', 'defender', 'info'],
    ElaIcon: ['ela', 'icon', 'operator', 'defender', 'trap'],
    FenrirIcon: ['fenrir', 'icon', 'operator', 'defender', 'trap'],
    FinkaIcon: ['finka', 'icon', 'operator', 'attacker', 'healer'],
    FloresIcon: ['flores', 'icon', 'operator', 'attacker', 'softbreach'],
    FrostIcon: ['frost', 'icon', 'operator', 'defender', 'trap'],
    FuzeIcon: ['fuze', 'icon', 'operator', 'attacker'],
    GlazIcon: ['glaz', 'icon', 'operator', 'attacker'],
    GoyoIcon: ['goyo', 'icon', 'operator', 'defender'],
    GridlockIcon: ['gridlock', 'icon', 'operator', 'attacker', 'flankwatch'],
    GrimIcon: ['grim', 'icon', 'operator', 'attacker', 'info', 'flankwatch'],
    HibanaIcon: ['hibana', 'icon', 'operator', 'attacker', 'hardbreach'],
    IanaIcon: ['iana', 'icon', 'operator', 'attacker', 'entry'],
    IqIcon: ['iq', 'icon', 'operator', 'attacker'],
    JackalIcon: ['jackal', 'icon', 'operator', 'attacker', 'info'],
    JagerIcon: ['jager', 'icon', 'operator', 'defender', 'burn'],
    KaidIcon: ['kaid', 'icon', 'operator', 'defender', 'denial'],
    KaliIcon: ['kali', 'icon', 'operator', 'attacker'],
    KapkanIcon: ['kapkan', 'icon', 'operator', 'defender', 'trap'],
    LesionIcon: ['lesion', 'icon', 'operator', 'defender', 'trap'],
    LionIcon: ['lion', 'icon', 'operator', 'attacker', 'info'],
    MaestroIcon: ['maestro', 'icon', 'operator', 'defender', 'info'],
    MaverickIcon: ['maverick', 'icon', 'operator', 'attacker', 'hardbreach'],
    MelusiIcon: ['melusi', 'icon', 'operator', 'defender'],
    MiraIcon: ['mira', 'icon', 'operator', 'defender'],
    MontagneIcon: ['montagne', 'icon', 'operator', 'attacker'],
    MozzieIcon: ['mozzie', 'icon', 'operator', 'defender', 'info', 'denial'],
    MuteIcon: ['mute', 'icon', 'operator', 'defender', 'info', 'denial'],
    NokkIcon: ['nokk', 'icon', 'operator', 'attacker'],
    NomadIcon: ['nomad', 'icon', 'operator', 'attacker', 'flankwatch'],
    OryxIcon: ['oryx', 'icon', 'operator', 'defender'],
    OsaIcon: ['osa', 'icon', 'operator', 'attacker'],
    PulseIcon: ['pulse', 'icon', 'operator', 'defender', 'info'],
    BlueRecruitIcon: ['bluerecruit', 'icon', 'operator', 'attacker', 'defender'],
    GreenRecruitIcon: ['greenrecruit', 'icon', 'operator', 'attacker', 'defender'],
    OrangeRecruitIcon: ['orangerecruit', 'icon', 'operator', 'attacker', 'defender'],
    RedRecruitIcon: ['redrecruit', 'icon', 'operator', 'attacker', 'defender'],
    YellowRecruitIcon: ['yellowrecruit', 'icon', 'operator', 'attacker', 'defender'],
    RamIcon: ['ram', 'icon', 'operator', 'attacker', 'softbreach'],
    RookIcon: ['rook', 'icon', 'operator', 'defender'],
    SensIcon: ['sens', 'icon', 'operator', 'attacker'],
    SledgeIcon: ['sledge', 'icon', 'operator', 'attacker', 'softbreach'],
    SmokeIcon: ['smoke', 'icon', 'operator', 'defender'],
    SolisIcon: ['solis', 'icon', 'operator', 'defender', 'info'],
    TachankaIcon: ['tachanka', 'icon', 'operator', 'defender'],
    ThatcherIcon: ['thatcher', 'icon', 'operator', 'attacker'],
    ThermiteIcon: ['thermite', 'icon', 'operator', 'attacker', 'hardbreach'],
    ThornIcon: ['thorn', 'icon', 'operator', 'defender', 'trap'],
    ThunderbirdIcon: ['thunderbird', 'icon', 'operator', 'defender', 'healer'],
    TubaraoIcon: ['tubarao', 'icon', 'operator', 'defender', 'denial'],
    TwitchIcon: ['twitch', 'icon', 'operator', 'attacker'],
    ValkyrieIcon: ['valkyrie', 'icon', 'operator', 'defender', 'info'],
    VigilIcon: ['vigil', 'icon', 'operator', 'defender'],
    WamaiIcon: ['wamai', 'icon', 'operator', 'defender', 'burn'],
    WardenIcon: ['warden', 'icon', 'operator', 'defender'],
    YingIcon: ['ying', 'icon', 'operator', 'attacker'],
    ZeroIcon: ['zero', 'icon', 'operator', 'attacker', 'info'],
    ZofiaIcon: ['zofia', 'icon', 'operator', 'attacker'],
    StrikerIcon: ['striker', 'icon', 'operator', 'attacker'],
    SentryIcon: ['sentry', 'icon', 'operator', 'defender'],
    AceGadget: ['ace', 'primary', 'gadget'],
    AlibiGadget: ['alibi', 'primary', 'gadget'],
    AmaruGadget: ['amaru', 'primary', 'gadget'],
    AruniGadget: ['aruni', 'primary', 'gadget'],
    AshGadget: ['ash', 'primary', 'gadget'],
    AzamiGadget: ['azami', 'primary', 'gadget'],
    BanditGadget: ['bandit', 'primary', 'gadget'],
    BlackbeardGadget: ['blackbeard', 'primary', 'gadget'],
    BlitzGadget: ['blitz', 'primary', 'gadget'],
    BravaGadget: ['brava', 'primary', 'gadget'],
    BuckGadget: ['buck', 'primary', 'gadget'],
    CapitaoFireGadget: ['capitao', 'primary', 'gadget'],
    CapitaoSmokeGadget: ['capitao', 'primary', 'gadget'],
    CastleGadget: ['castle', 'primary', 'gadget'],
    CaveiraGadget: ['caveira', 'primary', 'gadget'],
    ClashGadget: ['clash', 'primary', 'gadget'],
    DeimosGadget: ['deimos', 'primary', 'gadget'],
    DocGadget: ['doc', 'primary', 'gadget'],
    DokkaebiGadget: ['dokkaebi', 'primary', 'gadget'],
    EchoGadget: ['echo', 'primary', 'gadget'],
    ElaGadget: ['ela', 'primary', 'gadget'],
    FenrirGadget: ['fenrir', 'primary', 'gadget', 'trap'],
    FinkaGadget: ['finka', 'primary', 'gadget'],
    FloresGadget: ['flores', 'primary', 'gadget'],
    FrostGadget: ['frost', 'primary', 'gadget'],
    FuzeGadget: ['fuze', 'primary', 'gadget'],
    GlazGadget: ['glaz', 'primary', 'gadget'],
    GoyoGadget: ['goyo', 'primary', 'gadget'],
    GridlockGadget: ['gridlock', 'primary', 'gadget'],
    GrimGadget: ['grim', 'primary', 'gadget'],
    HibanaGadget: ['hibana', 'primary', 'gadget'],
    HibanaGadgetx4: ['hibana', 'primary', 'gadget'],
    HibanaGadgetx2: ['hibana', 'primary', 'gadget'],
    IanaGadget: ['iana', 'primary', 'gadget'],
    IqGadget: ['iq', 'primary', 'gadget'],
    JackalGadget: ['jackal', 'primary', 'gadget'],
    JagerGadget: ['jager', 'primary', 'gadget'],
    KaidGadget: ['kaid', 'primary', 'gadget'],
    KaliGadget: ['kali', 'primary', 'gadget'],
    KapkanGadget: ['kapkan', 'primary', 'gadget'],
    LesionGadget: ['lesion', 'primary', 'gadget'],
    LionGadget: ['lion', 'primary', 'gadget'],
    MaestroGadget: ['maestro', 'primary', 'gadget'],
    MaverickGadget: ['maverick', 'primary', 'gadget'],
    MelusiGadget: ['melusi', 'primary', 'gadget'],
    MiraGadget: ['mira', 'primary', 'gadget'],
    MontagneGadget: ['montagne', 'primary', 'gadget'],
    MozzieGadget: ['mozzie', 'primary', 'gadget'],
    MuteGadget: ['mute', 'primary', 'gadget'],
    NokkGadget: ['nokk', 'primary', 'gadget'],
    NomadGadget: ['nomad', 'primary', 'gadget'],
    OryxGadget: ['oryx', 'primary', 'gadget'],
    OsaGadget: ['osa', 'primary', 'gadget'],
    PulseGadget: ['pulse', 'primary', 'gadget'],
    RamGadget: ['ram', 'primary', 'gadget'],
    RookGadget: ['rook', 'primary', 'gadget'],
    SensGadget: ['sens', 'primary', 'gadget'],
    SledgeGadget: ['sledge', 'primary', 'gadget'],
    SmokeGadget: ['smoke', 'primary', 'gadget'],
    SolisGadget: ['solis', 'primary', 'gadget'],
    TachankaGadget: ['tachanka', 'primary', 'gadget'],
    ThatcherGadget: ['thatcher', 'primary', 'gadget'],
    ThermiteGadget: ['thermite', 'primary', 'gadget'],
    ThornGadget: ['thorn', 'primary', 'gadget'],
    ThunderbirdGadget: ['thunderbird', 'primary', 'gadget'],
    TubaraoGadget: ['tubarao', 'primary', 'gadget'],
    TwitchGadget: ['twitch', 'primary', 'gadget'],
    ValkyrieGadget: ['valkyrie', 'primary', 'gadget'],
    VigilGadget: ['vigil', 'primary', 'gadget'],
    WamaiGadget: ['wamai', 'primary', 'gadget'],
    WardenGadget: ['warden', 'primary', 'gadget'],
    YingGadget: ['ying', 'primary', 'gadget'],
    ZeroGadget: ['zero', 'primary', 'gadget'],
    ZofiaConcussionGadget: ['zofia', 'primary', 'gadget'],
    ZofiaImpactGadget: ['zofia', 'primary', 'gadget'],
    SmokeGrenade: ['grenade', 'smokegrenade', 'secondary', 'gadget',],
    Claymore: ['claymore', 'secondary', 'gadget'],
    ImpactEmp: ['impact', 'emp', 'impactemp', 'secondary', 'gadget'],
    ImpactGrenade: ['impact', 'grenade', 'impactgrenade', 'secondary', 'gadget'],
    NitroCell: ['nitrocell', 'secondary', 'gadget'],
    StunGrenade: ['stungrenade', 'secondary', 'gadget', 'flashgrenade'],
    BulletproofCamera: ['bulletproofcamera', 'secondary', 'gadget'],
    BarbedWire: ['barbedwire', 'secondary', 'gadget'],
    HardbreachTool: ['hardbreachtool', 'secondary', 'gadget'],
    BreachCharge: ['breachcharge', 'secondary', 'gadget'],
    ProximityAlarm: ['proximityalarm', 'secondary', 'gadget'],
    FragGrenade: ['fraggrenade', 'secondary', 'gadget'],
    DeployableShield: ['deployableshield', 'secondary', 'gadget'],
    UnknownIcon: ['unknown'],
    Drone: ['drone'],
    RotateIcon: ['rotate'],
    BarricadeIcon: ['barricade'],
    Reinforcement: ['reinforcement'],
    ObservationBlocker: ['observationblocker', 'secondary', 'gadget'],
    SkoposIcon: ['skopos', 'icon', 'operator', 'defener'],
    SkoposKolossosGadget: ['skopos', 'primary', 'utility'],
    SkoposTalosGadget: ['skopos', 'primary', 'utility'],
};

function matches_tags(user_strings, tags) {
    
    const matches = [];
    for (const filter of user_strings.trim().split(' ')) {
        const comparisonString = filter.toLowerCase().trim();
        if (comparisonString.length === 0) matches.push(true);

        matches.push(tags.some(tag => tag.includes(comparisonString)));
    }

    return matches.every(val => val);
}

function get_matches(user_string) {
    const matches = [];

    for (const [key, iconTags] of Object.entries(tags)) {
        if (matches_tags(user_string, iconTags)) matches.push(key);
    }
    return matches;
}

function filter_matches(matches, tag_filter) {
    return matches.filter(icon => matches_tags(tag_filter, tags[icon]));
}

function filter_doesnt_match(matches, tag_filter) {
    return matches.filter(icon => !matches_tags(tag_filter, tags[icon]));
}

export default tags;
export {get_matches, matches_tags, filter_matches, filter_doesnt_match};
