import React, { useContext } from 'react';
import styled from '@emotion/styled';
import theme from '../../../../../theme';

import { ToolOptionsContext } from '../../../../../contexts/tool_options';
import {Highlight} from '../../../../../models/drawables';
import { MapBackgroundContext } from '../../../../../contexts/map_background';
import { freehandLineExample } from '../../../../../constants';

export default function HighlighterOptions(props) {
    const {
        highlighterColor,
        setHighlighterColor
    } = useContext(ToolOptionsContext);

    const {floor} = useContext(MapBackgroundContext);

    const ExampleLine = new Highlight(floor, highlighterColor, freehandLineExample, null);

    return (
        <OptionsWrapper {...props}>
            <OptionLabel htmlFor="highlighter-color-input">Highlighter Color</OptionLabel>
            <ColorInputWrapper>
                <SvgWrapper><SvgExample viewBox='0 0 100 40'><ExampleLine.render /></SvgExample></SvgWrapper>
                <ColorInput
                    id="highlighter-color-input"
                    type="color"
                    value={highlighterColor}
                    onChange={(event) => setHighlighterColor(event.target.value)}
                />
            </ColorInputWrapper>
        </OptionsWrapper>
    );
}

const SvgExample = styled.svg`
    border: 1px solid black;
    padding: 5px;
    background-color: white;

    width: 150px;
    height: 90px;
`;

const SvgWrapper = styled.div`
    cursor: pointer;
`;

const OptionsWrapper = styled.div`
    display: grid;
    place-items: center;
`;

const ColorInputWrapper = styled.div`
    position: relative;
    :hover {
        filter: brightness(0.8);
    }
`;

const OptionLabel = styled.label`
    color: ${theme.text.colorSecondary};
    font-size: ${theme.text.fontSizePrimary};

    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;

    font-weight: bold;
    text-transform: uppercase;
`;

const ColorInput = styled.input`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    width: 100%;

    cursor: pointer;
`;
