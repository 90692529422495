import React, { useContext } from 'react';
import {v4} from 'uuid';

import { ToolOptionsContext } from '../../contexts/tool_options';
import { DrawingContext } from '../../contexts/drawing';
import { MapBackgroundContext } from '../../contexts/map_background';
import { OperatorContext } from '../../contexts/operators';

export function getLineString(linePath) {
    const svgPath = linePath.reduce((pathString, [partX, partY], indx) => {
        if (indx === 0) {
            return `M${partX} ${partY}`;
        }

        return `${pathString} L${partX} ${partY}`;
    }, '');

    return svgPath;
}

function getLineStyleDashArray(lineStyle) {
    switch(lineStyle) {
    case 'dashed':
        return '10 5';
    case 'dotted': 
        return '3 3';
    default:
        return '';
    }
}

function getMarkerRefY(endCap) {
    switch(endCap) {
    case 'arrow':
        return 10;
    case 'line':
        return 10;
    case 'x': 
        return 10;
    default:
        return 0;
    }
}

function getMarkerRefX(endCap) {
    switch (endCap) {
    case 'x':
        return 10;
    case 'arrow':
    case 'line':
    default:
        return 0;
    }
}

function getMarkerOrientation(endCap) {
    switch (endCap) {
    case 'x':
        return 0;
    case 'arrow':
    case 'line':
    default:
        return 'auto';
    }
}

// Line caps.
const ArrowCap = ({color}) => <polyline fill={color} points="0 0, 20 10, 0 20" />;
const LineCap = ({line, color}) => <line x1='0' x2='0' y1='0' y2='20' strokeWidth={line.width + 3} stroke={color} />;
const XCap = ({line, color}) => <path stroke={color} strokeWidth={line.width} d='M 0 0 L 20 20 Z M 20 0 L 0 20' />;

function SVGLine(line, {id: key, groupClassName, ...props}) {
    if (!groupClassName) groupClassName = 'line';

    const {floor} = useContext(MapBackgroundContext);
    const {tool, eraseLines, eraseHighlights} = useContext(ToolOptionsContext);
    const {removeLine, removeHighlight, mouseDown} = useContext(DrawingContext);
    const {operators, mainOperatorVisible} = useContext(OperatorContext);

    if (floor !== line.floor) return null;
    else if (line.operator !== null && !operators.map(({main, alt}, index) => mainOperatorVisible[index] ? main.name : alt.name).includes(line.operator)) return null;

    const lineOperator = operators
        .map(({main, alt}) => [main, alt])
        .flat()
        .filter(({name}) => { return name === line.operator;})?.[0];

    const svgPath = getLineString(line.relativePoints);
    const renderOpacity = line.opacity ? line.opacity : 1;

    const shouldEraseLines = tool === 'Eraser' && eraseLines;
    const shouldEraseHighlights = tool === 'Eraser' && eraseHighlights;
    
    const color = lineOperator ? lineOperator.color : line.color;
    return (
        <g
            key={key}
            className={groupClassName}
            id={key}
            onMouseOver={() => {
                if (shouldEraseLines && mouseDown.left) removeLine(line.id);
                if (shouldEraseHighlights && mouseDown.left) removeHighlight(line.id);
            }}
            onMouseDown={(e) => {
                if (shouldEraseLines && e.button === 0) removeLine(line.id);
                if (shouldEraseHighlights && e.button === 0) removeHighlight(line.id);
            }}
        >
            {/* Invisible path exists for eraser. */}
            <defs>
                <marker 
                    id={`${line.id}-linecap`}
                    viewBox='0 0 20 20'
                    fill={color}
                    markerUnits='strokeWidth'
                    markerWidth="5"
                    markerHeight="5"
                    refX={getMarkerRefX(line.endCap)}
                    refY={getMarkerRefY(line.endCap)}
                    orient={getMarkerOrientation(line.endCap)}
                >
                    {line.endCap === 'arrow' && <ArrowCap color={color}/>}
                    {line.endCap === 'line' && <LineCap line={line} color={color} />}
                    {line.endCap === 'x' && <XCap line={line} color={color} />}
                </marker>
                <marker 
                    id={`${line.id}-line-start`}
                    viewBox='0 0 20 20'
                    fill={color}
                    markerUnits='strokeWidth'
                    markerWidth="5"
                    markerHeight="5"
                    refX={getMarkerRefX(line.lineStart)}
                    refY={getMarkerRefY(line.lineStart)}
                    orient={getMarkerOrientation(line.lineStart)}
                >
                    {line.lineStart === 'arrow' && <ArrowCap color={color}/>}
                    {line.lineStart === 'line' && <LineCap line={line} color={color} />}
                    {line.lineStart === 'x' && <XCap line={line} color={color} />}
                </marker>
            </defs>
            {line.isFreehand ? (
                <>
                    <path
                        d={svgPath}
                        stroke="black"
                        strokeWidth={25}
                        fill="none"
                        opacity={0}
        
                        {...props}
                    />
                    <path 
                        d={svgPath}
                        stroke={color}
                        strokeWidth={line.width}
                        strokeDasharray={getLineStyleDashArray(line.lineStyle)}
                        fill="none"
                        opacity={renderOpacity}
                        markerEnd={`url('#${line.id}-linecap')`}
                        markerStart={`url('#${line.id}-line-start')`}
        
                        {...props}
                    />
                </>
            ) : (
                <line
                    x1={line.relativePoints[0][0]}
                    y1={line.relativePoints[0][1]}
                    x2={line.relativePoints[1][0]}
                    y2={line.relativePoints[1][1]}
                    strokeWidth={line.width}
                    strokeDasharray={getLineStyleDashArray(line.lineStyle)}
                    markerEnd={`url('#${line.id}-linecap')`}
                    markerStart={`url('#${line.id}-line-start')`}
                    stroke={color}
                />
            )}
        </g>
    );
}

export default class Line {
    constructor(floor, width, color, points=[], opacity=1, operator, lineStart, endCap, lineStyle, isFreehand=true) {
        this.id = v4();
        this.floor = floor;
        this.width = width;
        this.color = color;
        this.points = points;
        this.relativePoints = points;
        this.opacity = opacity;
        this.operator = operator;
        this.lineStart = lineStart;
        this.endCap = endCap;
        this.lineStyle = lineStyle;
        this.isFreehand = isFreehand;
        this.render = (props) => SVGLine(this, props);
    }

    extend(x, y) {
        if (this.isFreehand) {
            if (this.points.length === 0) {
                this.points.push([x, y]);
            } else {
                const [lastX, lastY] = this.points.at(-1);
                const diffX = Math.abs(lastX - x);
                const diffY = Math.abs(lastY - y);
    
                if (diffX >= 5 || diffY >= 5 || diffX + diffY >= 8) {
                    this.points.push([x, y]);
                }
            }
        }
        else {
            if (this.points.length === 0) {
                this.points = [[x, y], [x, y]];
            } else {
                this.points = [this.points[0], [x, y]];
            }
        }

        return this;
    }

    getRelative(xOffset, yOffset) {
        this.relativePoints = this.points.map(([x, y]) => [x + xOffset, y + yOffset]);

        return this;
    }
}
