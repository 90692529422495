import React, { useContext } from 'react';
import {v4} from 'uuid';

import { DrawingContext } from '../../contexts/drawing';
import { MapBackgroundContext } from '../../contexts/map_background';
import { ToolOptionsContext } from '../../contexts/tool_options';
import { OperatorContext } from '../../contexts/operators';

// A circled drawable object. To get a basic 
// starting position for a new drawable, simply
// replace 'circle' => newThing, 'Circle' => NewThing
function SVGCircle(circle, {...props}) {
    const {floor} = useContext(MapBackgroundContext);
    const {tool, eraseShapes} = useContext(ToolOptionsContext);
    const {removeShape, mouseDown} = useContext(DrawingContext);
    const {operators, mainOperatorVisible} = useContext(OperatorContext);

    if (floor !== circle.floor) return null;
    else if (circle.operator !== null && !operators.map(({main, alt}, index) => mainOperatorVisible[index] ? main.name : alt.name).includes(circle.operator)) return null;

    const circleOperator = operators
        .map(({main, alt}) => [main, alt])
        .flat()
        .filter(({name}) => { return name === circle.operator;})?.[0];

    const [x, y] = circle.relativePosition;
    const [width, height] = circle.size;
    const realX = width >= 0 ? x : x + width;
    const realY = height >= 0 ? y : y + height;
    const realWidth = Math.abs(width);
    const realHeight = Math.abs(height);

    // Find the distance from the top left corner to the center,
    // this will be the centre of the circle. IE always draw the
    // center of the circle in the middle of the box.
    const cx = realX + (realWidth / 2);
    const cy = realY + (realHeight / 2);
    const rx = realWidth / 2;
    const ry = realHeight / 2;

    const shouldErase = tool === 'Eraser' && eraseShapes;
    const color = circleOperator ? circleOperator.color : circle.stroke;
    return (
        <g
            id={`circle-${circle.id}`}
            onMouseOver={() => {
                if (shouldErase && mouseDown.left) removeShape(circle.id);
            }}
            onMouseDown={e => {
                if (shouldErase && e.button === 0) removeShape(circle.id);
            }}

            {...props}
        >
            <ellipse
                cx={cx}
                cy={cy}
                rx={rx}
                ry={ry}
                stroke={color}
                strokeWidth={circle.strokeWidth}
                fill={circle.filled ? color : 'none'}
            />
        </g>
    );
}

export default class Circle {
    constructor(floor, position, size, operator, color, thickness, filled=false) {
        this.id = v4();
        this.shapeType = 'circle';
        this.floor = floor;
        this.position = position;
        this.operator = operator;
        this.size = size;
        this.stroke = color;
        this.strokeWidth = thickness;
        this.filled = filled;

        this.relativePosition = position;
        this.render = (props) => SVGCircle(this, props);
    }

    getRelative(xOffset, yOffset) {
        const [x, y] = this.position;
        this.relativePosition = [x + xOffset, y + yOffset];
        return this;
    }

    resize(dx, dy) {
        const [width, height] = this.size;
        this.size = [width + dx, height + dy];

        return this;
    }
}
