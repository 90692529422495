import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import { OperatorContext } from '../../../contexts/operators';
import { filter_doesnt_match, filter_matches, get_matches } from '../../../models/tags';
import { Button, Filter } from '../../../elements';
import { IconButton, IconTray } from '../select_operator_modal/elements';
import icons from '../../../assets/icons';
import { get_by_icon, Unknown } from '../../../models/operators';
import { ModalContext } from '../../../contexts/modal';

export default function OperatorBanModal({isAttacker, updateMain, ...props}) {
    
    const {hideModal} = useContext(ModalContext);
    const {
        attackBan,
        defenceBan,
        setAttackBan,
        setDefenceBan,
        operators
    } = useContext(OperatorContext);
    const operatorIconsInUse = operators
        .map(({alt, main}) => [alt, main])
        .flat()
        .filter(operator => operator.name !== Unknown.name)
        .map(({icon}) => icon);

    const [filter, setFilter] = useState('');

    const currentBan = isAttacker ? attackBan : defenceBan;
    const currentBanOperator = updateMain ? currentBan.main : currentBan.alt;
    const setCurrentBan = isAttacker ? setAttackBan : setDefenceBan;
    const banTypeFilter = isAttacker ? 'attacker' : 'defender';
    const [currentSelection, setCurrentSelection] = useState(currentBanOperator);

    const matchingIcons = [
        ...filter_doesnt_match(filter_matches(get_matches(filter), banTypeFilter), 'recruit'),
        'UnknownIcon', 
    ];

    return (
        <ModalBody {...props}>
            <Filter filter={filter} setFilter={setFilter} />
            <FullSizeIconTray columns="4">
                {
                    matchingIcons.map(icon => {
                        const operatorIsInUse = operatorIconsInUse.includes(icons[icon]);
                        const operatorIsSelected = currentSelection.icon === icons[icon];
                        const operatorIsMainBan = !updateMain && currentBan.main.icon === icons[icon];

                        return (
                            <IconButton
                                key={icon}
                                selected={operatorIsSelected}
                                locked={operatorIsMainBan}
                                disabled={operatorIsInUse}
                                src={icons[icon]}
                                alt="Icon Button"
                                onClick={() => {
                                    if (operatorIsMainBan || operatorIsSelected || operatorIsInUse) return;

                                    setCurrentSelection(get_by_icon(icons[icon]));
                                }}
                            />
                        );
                    })
                }    
            </FullSizeIconTray>
            <SaveButton
                onClick={() => {
                    if (updateMain && currentSelection.name === Unknown.name && currentBan.alt.name !== Unknown.name) {
                        setCurrentBan(currentBan.alt, true);
                        setCurrentBan(Unknown, false);
                    } else {
                        setCurrentBan(currentSelection, updateMain);
                    }
                    hideModal();
                }}
            >
                Save    
            </SaveButton>    
        </ModalBody>
    );
}

const ModalBody = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FullSizeIconTray = styled(IconTray)`
    flex-grow: 1;
    width: 100%;

    place-items: start;
`;

const SaveButton = styled(Button)`
    height: 40px;
    margin-top: 5px;
`;
