export const initialState = {
    visible: false,
    content: (null),
    modalHeight: 500,
    modalWidth: 350
};

export const ModalActions = {
    revealModal: 'reveal_modal',
    hideModal: 'hide_modal',
    setContent: 'set_modal_content',
};

export const ModalReducer = (state, action) => {
    switch (action.type) {
    case ModalActions.revealModal: {
        return {
            ...state,
            visible: true,
        };
    }
    case ModalActions.hideModal: {
        return {
            ...state,
            visible: false,
        };
    }
    case ModalActions.setContent: {        
        return {
            ...state,
            content: action.content,
            modalHeight: (action.height ?? initialState.modalHeight),
            modalWidth: (action.width ?? initialState.modalWidth)
        };
    }
    default: 
        console.log(`WARNING: Invalid action ${action.type} provided.`);
        return state;
    }
};
