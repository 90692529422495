import React from 'react';

import styled from '@emotion/styled';
import theme from '../../theme';

import filterIcon from '../../assets/filter.png';

export default function Filter({filter, setFilter, iconLeft, ...props}) {
    const actualIconLeft = iconLeft ? iconLeft : '10%';

    return (
        <FilterWrapper iconLeft={actualIconLeft} {...props}>
            <FilterIcon iconLeft={actualIconLeft} alt="" src={filterIcon}/>
            <FilterInput
                type="text"
                value={filter}
                placeholder="Filter Icons (ex 'attacker')"
                onChange={(event) => setFilter(event.target.value)}
            />
        </FilterWrapper>
    );
}

const FilterWrapper = styled.div`
    display: grid;
    place-items: center;
    position: relative;
    
    padding: 5px 5px 5px calc(5% + ${({iconLeft}) => iconLeft});
    margin-bottom: 10px;

    border-radius: 4px;
    background-color: ${theme.colors.UI300};

    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid ${theme.colors.traySecondary};
`;

const FilterIcon = styled.img`
    position: absolute;
    top: 50%;
    left: ${({iconLeft}) => iconLeft};
    transform: translate(-50%, -50%);

    opacity: .8;

    height: 13px;
    width: auto;
`;

const FilterInput = styled.input`
    outline: none;
    border: none;

    background-color: #e6e6ee;
    
    font-family: ${theme.text.fontSecondary};
    font-color: ${theme.text.colorSecondary};
    font-size: ${theme.text.fontSizeSecondary};

    width: 100%;
    height: 100%;

`;
