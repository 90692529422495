import * as mapImages from '../assets/maps';

function Map(basement, floors, roof) {
    this.basement = basement;
    this.floors = floors;
    this.roof = roof;
}

const Bank = new Map(mapImages.BankBasement, [mapImages.Bank1F, mapImages.Bank2F], mapImages.BankRoof);
const Border = new Map(undefined, [mapImages.Border1F, mapImages.Border2F], mapImages.BorderRoof);
const Chalet = new Map(mapImages.ChaletBasement, [mapImages.Chalet1F, mapImages.Chalet2F], mapImages.ChaletRoof);
const Clubhouse = new Map(mapImages.ClubhouseBasement, [mapImages.Clubhouse1F, mapImages.Clubhouse2F], mapImages.ClubhouseRoof);
const Coastline = new Map(undefined, [mapImages.Coastline1F, mapImages.Coastline2F], mapImages.CoastlineRoof);
const Consulate = new Map(mapImages.ConsulateBasement, [mapImages.Consulate1F, mapImages.Consulate2F], mapImages.ConsulateRoof);
const EmeraldPlains = new Map(undefined, [mapImages.EmeraldPlains1F, mapImages.EmeraldPlains2F], mapImages.EmeraldPlainsRoof);
const Kafe = new Map(undefined, [mapImages.Kafe1F, mapImages.Kafe2F, mapImages.Kafe3F], mapImages.KafeRoof);
const NightHavenLab = new Map(mapImages.NightHavenLabBasement, [mapImages.NightHavenLab1F, mapImages.NightHavenLab2F], mapImages.NightHavenLabRoof);
const Oregon = new Map(mapImages.OregonBasement, [mapImages.Oregon1F, mapImages.Oregon2F, mapImages.Oregon3F], mapImages.OregonRoof);
const Villa = new Map(mapImages.VillaBasement, [mapImages.Villa1F, mapImages.Villa2F], mapImages.VillaRoof);
const ThemePark = new Map(undefined, [mapImages.ThemePark1F, mapImages.ThemePark2F], mapImages.ThemeParkRoof);
const SkyScraper = new Map(undefined, [mapImages.SkyScraper1F, mapImages.SkyScraper2F], mapImages.SkyScraperRoof);
const Lair = new Map(mapImages.LairBasement, [mapImages.Lair1F, mapImages.Lair2F], mapImages.LairRoof);

const allMaps = {
    Bank, 
    Border,
    Chalet,
    Clubhouse,
    Coastline,
    Consulate,
    EmeraldPlains,
    Kafe,
    NightHavenLab,
    Oregon,
    Villa,
    ThemePark,
    SkyScraper,
    Lair,
};

const enabledMaps = {
    Border,
    Chalet,
    Clubhouse,
    Consulate,
    Bank,
    Kafe,
    NightHaven: NightHavenLab,
    Oregon,
    SkyScraper,
    Lair,
};

export function getMapFromFloors(floors) {
    for (const [key, {basement, floors: mapFloors, roof}] of Object.entries(allMaps)) {
        const comparisonList = [basement, ...mapFloors, roof].filter(floor => floor);
        if (JSON.stringify(comparisonList) === JSON.stringify(floors)) return allMaps[key];
    }

    return {basement: undefined, floors: [], roof: undefined};
}

export default enabledMaps;
export {
    Bank,
    Chalet,
    Clubhouse,
    Coastline,
    EmeraldPlains,
    Kafe,
    Oregon,
    Villa,
    SkyScraper,
    Lair,
    allMaps,
    enabledMaps
};
