import BankBasement from './bank/Basement.jpg';
import Bank1F from './bank/1F.jpg';
import Bank2F from './bank/2F.jpg';
import BankRoof from './bank/Roof.jpg';

import Border1F from './border/1F.jpg';
import Border2F from './border/2F.jpg';
import BorderRoof from './border/Roof.jpg';

import ChaletBasement from './chalet/Basement.jpg';
import Chalet1F from './chalet/1F.jpg';
import Chalet2F from './chalet/2F.jpg';
import ChaletRoof from './chalet/Roof.jpg';

import ClubhouseBasement from './clubhouse/Basement.jpg';
import Clubhouse1F from './clubhouse/1F.jpg';
import Clubhouse2F from './clubhouse/2F.jpg';
import ClubhouseRoof from './clubhouse/Roof.jpg';

import Coastline1F from './coastline/1F.jpg';
import Coastline2F from './coastline/2F.jpg';
import CoastlineRoof from './coastline/Roof.jpg';

import ConsulateBasement from './consulate/Basement.jpg';
import Consulate1F from './consulate/1F.jpg';
import Consulate2F from './consulate/2F.jpg';
import ConsulateRoof from './consulate/Roof.jpg';

import EmeraldPlains1F from './emeraldplains/1F.jpg';
import EmeraldPlains2F from './emeraldplains/2F.jpg';
import EmeraldPlainsRoof from './emeraldplains/Roof.jpg';

import Kafe1F from './kafe/1F.jpg';
import Kafe2F from './kafe/2F.jpg';
import Kafe3F from './kafe/3F.jpg';
import KafeRoof from './kafe/Roof.jpg';

import NightHavenLabBasement from './nighthavenlab/Basement.jpg';
import NightHavenLab1F from './nighthavenlab/1F.jpg';
import NightHavenLab2F from './nighthavenlab/2F.jpg';
import NightHavenLabRoof from './nighthavenlab/Roof.jpg';

import OregonBasement from './oregon/Basement.jpg';
import Oregon1F from './oregon/1F.jpg';
import Oregon2F from './oregon/2F.jpg';
import Oregon3F from './oregon/3F.jpg';
import OregonRoof from './oregon/Roof.jpg';

import VillaBasement from './villa/Basement.jpg';
import Villa1F from './villa/1F.jpg';
import Villa2F from './villa/2F.jpg';
import VillaRoof from './villa/Roof.jpg';

import ThemePark1F from './themepark/1F.jpg';
import ThemePark2F from './themepark/2F.jpg';
import ThemeParkRoof from './themepark/Roof.jpg';

import SkyScraper1F from './skyscraper/1F.jpg';
import SkyScraper2F from './skyscraper/2F.jpg';
import SkyScraperRoof from './skyscraper/Roof.jpg';

import LairBasement from './lair/Basement.jpg';
import Lair1F from './lair/1F.jpg';
import Lair2F from './lair/2F.jpg';
import LairRoof from './lair/Roof.jpg';

const maps = {
    Bank: [BankBasement, Bank1F, Bank2F, BankRoof],
    Border: [Border1F, Border2F, BorderRoof],
    Chalet: [ChaletBasement, Chalet1F, Chalet2F, ChaletRoof],
    Clubhouse: [ClubhouseBasement, Clubhouse1F, Clubhouse2F, ClubhouseRoof],
    Coastline: [Coastline1F, Coastline2F, CoastlineRoof],
    consulate: [ConsulateBasement, Consulate1F, Consulate2F, ConsulateRoof],
    EmeraldPlains: [EmeraldPlains1F, EmeraldPlains2F, EmeraldPlainsRoof],
    Kafe: [Kafe1F, Kafe2F, Kafe3F, KafeRoof],
    NightHavenLab: [NightHavenLabBasement, NightHavenLab1F, NightHavenLab2F, NightHavenLabRoof],
    Oregon: [OregonBasement, Oregon1F, Oregon2F, Oregon3F, OregonRoof],
    Villa: [VillaBasement, Villa1F, Villa2F, VillaRoof],
    ThemePark: [ThemePark1F, ThemePark2F, ThemeParkRoof],
    SkyScraper: [SkyScraper1F, SkyScraper2F, SkyScraperRoof],
    Lair: [LairBasement, Lair1F, Lair2F, LairRoof],
};

const imageToReadable = {
    [BankBasement]: 'Bank: Basement',
    [Bank1F]: 'Bank: 1F',
    [Bank2F]: 'Bank: 2F',
    [BankRoof]: 'Bank: Roof',
    [Border1F]: 'Border: 1F',
    [Border2F]: 'Border: 2F',
    [BorderRoof]: 'Border: Roof',
    [ChaletBasement]: 'Chalet: Basement',
    [Chalet1F]: 'Chalet: 1F',
    [Chalet2F]: 'Chalet: 2F',
    [ChaletRoof]: 'Chalet: Roof',
    [ClubhouseBasement]: 'Clubhouse: Basement',
    [Clubhouse1F]: 'Clubhouse: 1F',
    [Clubhouse2F]: 'Clubhouse: 2F',
    [ClubhouseRoof]: 'Clubhouse: Roof',
    [Coastline1F]: 'Coastline: 1F',
    [Coastline2F]: 'Coastline: 2F',
    [CoastlineRoof]: 'Coastline: Roof',
    [ConsulateBasement]: 'Consulate: Basement',
    [Consulate1F]: 'Consulate: 1F',
    [Consulate2F]: 'Consulate: 2F',
    [ConsulateRoof]: 'Consulate: Roof',
    [EmeraldPlains1F]: 'Emerald Plains 1F',
    [EmeraldPlains2F]: 'Emerald Plains 2F',
    [EmeraldPlains2F]: 'Emerald Plains Roof',
    [Kafe1F]: 'Kafe: 1F',
    [Kafe2F]: 'Kafe: 2F',
    [Kafe3F]: 'Kafe: 3F',
    [KafeRoof]: 'Kafe: Roof',
    [NightHavenLabBasement]: 'NightHaven Lab: Basement',
    [NightHavenLab1F]: 'NightHaven Lab: 1F',
    [NightHavenLab2F]: 'NightHaven Lab: 2F',
    [NightHavenLabRoof]: 'NightHaven Lab: Roof',
    [OregonBasement]: 'Oregon: Basement',
    [Oregon1F]: 'Oregon: 1F',
    [Oregon2F]: 'Oregon: 2F',
    [Oregon3F]: 'Oregon: 3F',
    [OregonRoof]: 'Oregon: Roof',
    [VillaBasement]: 'Villa: Basement',
    [Villa1F]: 'Villa: 1F',
    [Villa2F]: 'Villa: 2F',
    [VillaRoof]: 'Villa: Roof',
    [ThemePark1F]: 'ThemePark: 1F',
    [ThemePark2F]: 'ThemePark: 2F',
    [ThemeParkRoof]: 'ThemePark: Roof',
    [SkyScraper1F]: 'SkyScraper: 1F',
    [SkyScraper2F]: 'SkyScraper: 2F',
    [SkyScraperRoof]: 'SkyScraper: Roof',
    [LairBasement]: 'Lair: Basement',
    [Lair1F]: 'Lair: 1F',
    [Lair2F]: 'Lair: 2F',
    [LairRoof]: 'Lair: Roof',
};

export function getMapFromFloor(floor) {
    for (const [mapName, floors] of Object.entries(maps)) {
        if (floors.includes(floor)) return maps[mapName];
    }
}

export default maps;
export {
    BankBasement,
    Bank1F,
    Bank2F,
    BankRoof,
    Border1F,
    Border2F,
    BorderRoof,
    ChaletBasement,
    Chalet1F,
    Chalet2F,
    ChaletRoof,
    ClubhouseBasement,
    Clubhouse1F,
    Clubhouse2F,
    ClubhouseRoof,
    Coastline1F,
    Coastline2F,
    CoastlineRoof,
    ConsulateBasement,
    Consulate1F,
    Consulate2F,
    ConsulateRoof,
    EmeraldPlains1F,
    EmeraldPlains2F,
    EmeraldPlainsRoof,
    Kafe1F,
    Kafe2F,
    Kafe3F,
    KafeRoof,
    NightHavenLabBasement,
    NightHavenLab1F,
    NightHavenLab2F,
    NightHavenLabRoof,
    OregonBasement,
    Oregon1F,
    Oregon2F,
    Oregon3F,
    OregonRoof,
    VillaBasement,
    Villa1F,
    Villa2F,
    VillaRoof,
    ThemePark1F,
    ThemePark2F,
    ThemeParkRoof,
    SkyScraper1F,
    SkyScraper2F,
    SkyScraperRoof,
    LairBasement,
    Lair1F,
    Lair2F,
    LairRoof,
    imageToReadable
};
