import React, { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import { ToolOptionsContext } from '../../../../contexts/tool_options';
import { Icon } from '../../../../models/drawables';
import { DrawingContext } from '../../../../contexts/drawing';
import { Reinforcement } from '../../../../models/utility';
import { OperatorContext } from '../../../../contexts/operators';

export default function UtilityTile({utility, operator, dontColor, ...props}) {
    const {icons} = useContext(DrawingContext);
    const {setTool, setIcon, setIconColor} = useContext(ToolOptionsContext); 
    const {visibleOperators} = useContext(OperatorContext);

    const visibleOperatorUtilities = useCallback((operatorName, utilityIcon) => {
        if (utilityIcon === Reinforcement.icon) {
            const visibleOperatorNames = visibleOperators.map(({name}) => name);
            return icons.filter(icon => icon.icon === Reinforcement.icon && visibleOperatorNames.includes(icon.operator)).length;
        }
        else return icons.filter(icon => icon.operator === operatorName && icon.icon === utilityIcon).length;
    }, [icons, visibleOperators]);

    const operatorColor = dontColor ? null : operator?.color ?? null;
    const ThisIcon = useMemo(() => {
        const iconObject = new Icon(utility.icon, '', 1, 1, 0, operatorColor, [25, 25], null);
        return <iconObject.render
            height={50}
            width={50}
        />;
    }, [utility, operatorColor]);

    // Get the utility name, accounting for if this is an
    // AlternateUtility. Get the total of them this operator
    // should have, taking into account that Reinforcements are a shared
    // pool of 10. Get the remaining.
    const utilityName = utility?.alternateFor?.name ?? utility.name;
    const utilityTotal = utility.name === Reinforcement.name ? 10 : operator.utilityCounts()[utilityName];
    const utilityCount =  utilityTotal - visibleOperatorUtilities(operator.name, utility.icon);
    return (
        <TileWrapper {...props} title={'Draw this icon'} onClick={() => {
            setTool('Icon', operator.name);
            setIcon(utility.icon);
            setIconColor(operatorColor);
        }}>
            <UtilityBox>
                <ColoredIcon>
                    {ThisIcon}
                </ColoredIcon>
            </UtilityBox>
            {utilityCount !== undefined && isFinite(utilityCount) && 
                <TextBox>
                    <HintText># Unused</HintText>
                    <UtilityCountText>{utilityCount}</UtilityCountText>
                </TextBox>
            }
        </TileWrapper>
    );
}

const TileWrapper = styled.div`
    border: 2px solid #aaa;
    height: 100px;
    width: 75px;

    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.UI100};

    cursor: pointer;

    :hover {
        filter: brightness(.8);
    }
`;

const UtilityBox = styled.div`
    height: 60%;
    width: 100%;

    display: grid;
    place-items: center;
`;

const ColoredIcon = styled.svg`
    width: 50px;
    height: 50px;
`;

const TextBox = styled.div`
    height: 40%;
    width: 100%;

    display: column;
    align-items: center;
`;

const HintText = styled.p`
    margin: unset;
    padding: unset;
    text-align: center;
    align-self: flex-start;

    font-size: 7px;
    color: ${theme.colors.textPrimary};
`;

const UtilityCountText = styled.p`
    margin: unset;
    padding: unset;
    text-align: center;

    font-size: ${theme.text.fontSizeSecondary};
    color: ${({children}) => children >= 0 ? '#000' : theme.text.colorError};
    font-weight: bold;
`;
