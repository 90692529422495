import React, { useContext } from 'react';

import { PreviewWindow } from '../..';
import { PencilOptions, EraserOptions, HighlighterOptions, IconOptions, TextOptions, ShapeOptions } from './options';
import { ToolOptionsContext } from '../../../../contexts/tool_options';

export default function ToolPreviewWindow(props) {
    const {
        tool,
    } = useContext(ToolOptionsContext);

    return (    
        <PreviewWindow {...props} visible={Boolean(tool) && tool !== 'Cursor'}>
            {tool === 'Pencil' && <PencilOptions />}
            {tool === 'Eraser' && <EraserOptions />}
            {tool === 'Highlighter' && <HighlighterOptions />}
            {tool === 'Icon' && <IconOptions />}
            {tool === 'Text' && <TextOptions />}
            {tool === 'Shape' && <ShapeOptions />}
        </PreviewWindow>
    );
}
