import styled from '@emotion/styled';

import { Tray } from '../../../elements';

export const IconButton = styled.img`
    height: 60px;
    width: 60px;
    margin: ${({selected, locked, disabled}) => {
        if (selected || locked || disabled) return '0';
        else return '2';
    }}px;
    border: ${({selected, locked, disabled}) => {
        if (selected) return '2px orange solid';
        else if (locked) return '2px red solid';
        else if (disabled) return '2px grey solid';
        else return '';
    }};

    filter: ${({disabled}) => disabled ? 'grayscale(1)' : ''};
    cursor: ${({selected, locked, disabled}) => selected || locked || disabled ? 'default' : 'pointer'};
`;

export const IconTray = styled(Tray)`
    place-items: center;
    grid-area: ${({gridArea}) => gridArea};
`;
