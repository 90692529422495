import React, { useContext } from 'react';
import styled from '@emotion/styled';
import ClipLoader from 'react-spinners/ClipLoader';
import { MapBackgroundContext } from '../../contexts/map_background';
import { keyframes } from '@emotion/react';

export default function LoadingScreen() {
    const {initialLoad} = useContext(MapBackgroundContext);
    if (!initialLoad) return null;

    return (
        <LoadingScreenWrapper>
            <ClipLoader size={100} color={'#fff'} css={{borderWidth: 10}}/>
            <LoadingScreenText>Preloading Textures...</LoadingScreenText>
        </LoadingScreenWrapper>
    );
}

const LoadingScreenWrapper = styled.div`
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #35637c;
`;

const FontSizeBounce = keyframes`
    0% {font-size: 32px;}
    100% {font-size: 38px;}
`;

const LoadingScreenText = styled.h1`
    color: white;
    animation-name: ${FontSizeBounce};
    animation-duration: 400ms;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: linear;
`;
