import React from 'react';
import styled from '@emotion/styled';
import theme from '../../theme';

export default function Tray({children, ...props}) {
    return (
        <StyledTray {...props}>
            {children}
        </StyledTray>
    );
}

const StyledTray = styled.div`
    border: 2px solid ${theme.colors.traySecondary};
    background-color: ${theme.colors.trayPrimary};

    display: grid;
    grid-template-columns: repeat(${({columns}) => columns}, ${({columnWidth}) => columnWidth ? columnWidth : '1fr'});
    gap: 15px;

    overflow-y: scroll;
    overflow-x: hidden;
`;
