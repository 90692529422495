const animations = {};

const colors = {
    UI100: '#fff',
    UI200: '#fff',
    UI300: '#e6e6ee',
    UI400: '#606060',
    textPrimary: '#777',
    textSecondary: '#000',
    textTertiary: '#fff',
    trayPrimary: '#333',
    traySecondary: '#000',
    button: 'linear-gradient(to left, #626262, #000)',
    buttonHover: '#3963a2',
    buttonPress: '#800',
    buttonText: '#fff',
    buttonInactive: '#787b82',
    error: '#400000',
    contextMenuTextColor: '#000',
    lightGreen: '#00a000',
    green: '#004000',
    red: '#400000'
};

const text = {
    colorPrimary: colors.textPrimary,
    colorSecondary: colors.textSecondary,
    colorTertiary: colors.textTertiary,
    colorError: colors.error,
    buttonColor: colors.buttonText,
    fontPrimary: 'sans-serif',
    fontSecondary: 'Segoe UI',
    fontTertiary: 'sans-serif',
    fontError: 'sans-serif',
    fontButton: 'Courier New',
    fontSizePrimary: '18px',
    fontSizeSecondary: '16px',
    fontSizeTertiary: '13px',
    fontSizeError: '12px',
    fontSizeButton: '18px',
    fontWeightButton: 'bold',
    decorationPrimary: 'none',
    decorationSecondary: 'none',
    decorationTertiary: 'none',
    decorationError: 'none'
};

const sizing = {
    contextMenuCellHeight: 30,
    contextMenuDividerHeight: 10
};

const shadows = {
    UI100: 'rgba(0, 0, 0, 0.12) 0px 0px 9px 0px, rgba(0, 0, 0, 0.12) 0px 9px 9px 0px',
    UI200: 'rgba(0, 0, 0, 0.15) 0px 0px 9px 0px, rgba(0, 0, 0, 0.15) 0px 9px 9px 0px',
    UI300: 'rgba(0, 0, 0, 0.2) 0px 0px 9px 0px, rgba(0, 0, 0, 0.15) 0px 9px 9px 0px',
};

const transitions = {
    slideSpeed: '250ms'
};

const zIndices = {
    dropdownMenu: 1000,
    contextMenu: 12,
    modal: 11,
    contentObscure: 10,
    recenterIcon: 6,
    sliderLabel: 5,
    popoutTray: 5,
    iconPreview: 4,
    lineMap: 3,
    iconMap: 2
};

const theme = {
    animations,
    colors,
    sizing,
    shadows,
    text,
    transitions,
    zIndices
};


export default theme;
