import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import theme from '../../../../../theme';

import { ToolOptionsContext } from '../../../../../contexts/tool_options';
import {Line} from '../../../../../models/drawables';
import Slider from '../../../../../elements/slider';
import Dropdown from '../../../../../elements/dropdown';
import ToggleSwitch from '../../../../../elements/toggle_switch';
import { MapBackgroundContext } from '../../../../../contexts/map_background';
import { freehandLineExample } from '../../../../../constants';

export default function PencilOptions(props) {
    const {
        pencilColor,
        pencilWidth,
        pencilLineStart,
        pencilEndCap,
        pencilLineStyle,
        pencilLineIsFreehand,
        setPencilColor,
        setPencilWidth,
        setPencilLineStart,
        setPencilEndCap,
        setPencilLineStyle,
        setPencilLineIsFreehand
    } = useContext(ToolOptionsContext);

    const {floor} = useContext(MapBackgroundContext);
 
    const SolidLine = useMemo(() => {
        return new Line(floor, 3, 'black', [[5, 10], [90, 10]], 1, null, 'none', 'none', 'none', true);
    }, [floor]);
    const DottedLine = useMemo(() => {
        return new Line(floor, 3, 'black', [[5, 10], [90, 10]], 1, null, 'none', 'none', 'dotted', true);
    }, [floor]);
    const DashedLine = useMemo(() => {
        return new Line(floor, 3, 'black', [[5, 10], [90, 10]], 1, null, 'none', 'none', 'dashed', true);
    }, [floor]);
    const ArrowStart = useMemo(() => {
        return new Line(floor, 3, 'black', [[5,  10], [90, 10]], 1, null, 'arrow', 'none', 'none', true);
    }, [floor]);
    const LineStart = useMemo(() => {
        return new Line(floor, 3, 'black', [[5, 10], [90, 10]], 1, null, 'line', 'none', 'none', true);
    }, [floor]);
    const XStart = useMemo(() => {
        return new Line(floor, 3, 'black', [[10, 10], [90, 10]], 1, null, 'x', 'none', 'none', true);
    }, [floor]);
    const ArrowEnd = useMemo(() => {
        return new Line(floor, 3, 'black', [[5,  10], [75, 10]], 1, null, 'none', 'arrow', 'none', true);
    }, [floor]);
    const LineEnd = useMemo(() => {
        return new Line(floor, 3, 'black', [[5, 10], [77, 10]], 1, null, 'none', 'line', 'none', true);
    }, [floor]);
    const XEnd = useMemo(() => {
        return new Line(floor, 3, 'black', [[5, 10], [80, 10]], 1, null, 'none', 'x', 'none', true);
    }, [floor]);

    const exampleLine = useMemo(() => {
        const straightPoints = [[5, 20], [95, 20]];

        const linePoints = pencilLineIsFreehand ? freehandLineExample : straightPoints;
        const line = new Line(floor, pencilWidth, pencilColor, linePoints, 1, null, pencilLineStart, pencilEndCap, pencilLineStyle, true);

        line.getRelative(0, 0);
        return (
            <ExampleLine viewBox='0 0 100 40'>
                <line.render />
            </ExampleLine>
        );
    }, [floor, pencilWidth, pencilColor, pencilLineIsFreehand, pencilLineStart, pencilEndCap, pencilLineStyle]);
 
    const widthSliderStages = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const freehandSwitchOptions = ['Freehand', 'Straight'];
    return (
        <OptionsWrapper {...props}>
            <OptionLabel htmlFor="pencil-color-input">Color</OptionLabel>
            <ColorWrapper>
                {exampleLine}
                <ColorInput
                    id="pencil-color-input"
                    type="color"
                    value={pencilColor}
                    onChange={(event) => setPencilColor(event.target.value)}
                />
            </ColorWrapper>
            <StyledToggleSwitch
                option1={freehandSwitchOptions[0]}
                option2={freehandSwitchOptions[1]}
                defaultOption={pencilLineIsFreehand ? freehandSwitchOptions[0] : freehandSwitchOptions[1]}
                onClick={() => setPencilLineIsFreehand(!pencilLineIsFreehand)}
            />
            <OptionLabel htmlFor="pencil-width-input">Width</OptionLabel>
            <WidthSlider
                stages={widthSliderStages}
                defaultValue={pencilWidth}
                onChange={(value) => setPencilWidth(value)}
            />
            <DropdownInputs>
                <HorizontalItemWrapper>
                    <StyledDropdown
                        id='pencil-line-start-input'
                        onSelect={value => setPencilLineStart(value)}
                        value={pencilLineStart}
                        placeHolder='Line Start'
                    >
                        <svg height={17} viewBox='0 0 90 20' value='none'><SolidLine.render /></svg>
                        <svg height={17} viewBox='0 0 90 20' value='arrow'><ArrowStart.render /></svg>
                        <svg height={17} viewBox='0 0 90 20' value='line'><LineStart.render /></svg>
                        <svg height={17} viewBox='0 0 90 20' value='x'><XStart.render /></svg>
                    </StyledDropdown>
                    <StyledDropdown
                        id='pencil-endcap-input'
                        value={pencilEndCap}
                        onSelect={value => setPencilEndCap(value)}
                        placeHolder='Line End'
                    >
                        <svg height={17} viewBox='0 0 90 20' value='none'><SolidLine.render /></svg>
                        <svg height={17} viewBox='0 0 90 20' value='arrow'><ArrowEnd.render /></svg>
                        <svg height={17} viewBox='0 0 90 20' value='line'><LineEnd.render /></svg>
                        <svg height={17} viewBox='0 0 90 20' value='x'><XEnd.render /></svg>
                    </StyledDropdown>
                </HorizontalItemWrapper>
                <LineStyleDropdown
                    id='pencil-linestyle-input'
                    value={pencilLineStyle}
                    onSelect={value => setPencilLineStyle(value)}
                    placeHolder='Line Style'
                >
                    <svg height={17} viewBox='0 0 90 20' value='none'><SolidLine.render /></svg>
                    <svg height={17} viewBox='0 0 90 20' value='dotted'><DottedLine.render /></svg>
                    <svg height={17} viewBox='0 0 90 20' value='dashed'><DashedLine.render /></svg>
                </LineStyleDropdown>
            </DropdownInputs>
        </OptionsWrapper>
    );
}

const ColorWrapper = styled.div`
    width: 100%;
    display: grid;
    place-items: center;
    position: relative;
    margin-bottom: 25px;

    :hover {
        filter: brightness(0.8);
    }
`;

const ExampleLine = styled.svg`
    height: 40px;
    width: 100px;

    border: 1px solid black;
    padding: 5px;
    background-color: white;
`;

const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
`;

const OptionLabel = styled.label`
    color: ${theme.text.colorSecondary};
    font-size: ${theme.text.fontSizePrimary};

    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;

    font-weight: bold;
    text-transform: uppercase;
`;

const WidthSlider = styled(Slider)`
    width: 80%;
    margin-bottom: 25px;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
    width: 150px;
    height: 30px;
    border-radius: 0;

    margin-bottom: 20px;
`;

const StyledDropdown = styled(Dropdown)`
    width: 110px;
    height: 20px;

    margin-inline: 5px;
    margin-block: 5px;

    background-color: white;
`;

const LineStyleDropdown = styled(StyledDropdown)`
    grid-column: -1/1;
`;

const DropdownInputs = styled.div`
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
`;

const ColorInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    height: 100%;
    width: 100%;

    cursor: pointer;
`;

const HorizontalItemWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
