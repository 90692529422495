import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { BottomBanner } from '../..';
import { Button } from '../../../../elements';
import { DrawingContext } from '../../../../contexts/drawing';
import { MapBackgroundContext } from '../../../../contexts/map_background';
import { OverlayContext } from '../../../../contexts/overlay';
import { ToolOptionsContext } from '../../../../contexts/tool_options';

export default function ContinueRibbon({...props}) {

    const {changeOverlay} = useContext(OverlayContext);
    const {setTool} = useContext(ToolOptionsContext);
    const {mapCropping, unlockMapCropping} = useContext(MapBackgroundContext);
    const {mouseDown} = useContext(DrawingContext);

    return mouseDown.left ? null : (
        <TransformedBottomBanner
            left='50%'
            width="700px"
            hiddenHeight="0px"
            expandedHeight="125px"
            label="Select Export Region"
        >
            <TrayBody {...props}>
                <TrayText>Please click &amp; drag to select the area to be exported.</TrayText>
                <TrayButtons>
                    <TrayButton
                        onClick={() => {
                            setTool('Cursor');
                            changeOverlay('Siege');
                        }}
                    >Cancel</TrayButton>
                    {
                        mapCropping.x - mapCropping.width === 0 || mapCropping.y - mapCropping.height === 0 ? (
                            <TrayText><i>Please select something to export</i></TrayText>
                        ) : (
                            <TrayButton
                                onClick={unlockMapCropping}
                            >Ok</TrayButton>
                        )
                    }
                </TrayButtons>
            </TrayBody>
        </TransformedBottomBanner>
    );
}

const TransformedBottomBanner = styled(BottomBanner)`
    transform: translate(-50%, 0);
`;

const TrayBody = styled.div`

`;

const TrayText = styled.p`

`;

const TrayButtons = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const TrayButton = styled(Button)`
    width: 100px;
`;
