import React, {useState, useLayoutEffect, useContext} from 'react';
import styled from '@emotion/styled';
import theme from '../../theme';

import { headerHeight } from '../../constants';
import {LeftPin, RightPin} from '../../assets';
import { ToolOptionsContext } from '../../contexts/tool_options';

const StyledLeftPin = styled(LeftPin)`
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    
    cursor: pointer;

    opacity: ${({isPinned}) => isPinned? 1 : 0.5};
    transition: opacity 250ms;

    :hover {
        opacity: 1;
    }
`;

const StyledRightPin = styled(RightPin)`
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    
    cursor: pointer;

    opacity: ${({isPinned}) => isPinned? 1 : 0.5};
    transition: opacity 250ms;

    :hover {
        opacity: 1;
    }
`;

const CloseButton = styled.div`
    position: absolute;
    top: 5px;
    left: 10px;

    cursor: pointer;
`;

export function LeftBanner({...props}) {
    return <LeftPopout defaultisPinned={true} pinningEnabled={false} {...props}/>;
}

export function LeftPopout({label, children, defaultisPinned=false, pinningEnabled=true, ...props}) {
    const [isPinned, setIsPinned] = useState(defaultisPinned);

    return (
        <LeftPopoutDiv isPinned={isPinned} {...props}>
            <TrayText rightAligned={true}>{label}</TrayText>
            {pinningEnabled && <StyledLeftPin isPinned={isPinned} onClick={() => setIsPinned(!isPinned)}/>}
            {children}
        </LeftPopoutDiv>
    );
}

export function RightBanner({...props}) {
    return <RightPopout defaultisPinned={true} pinningEnabled={false} {...props}/>;
}

export function RightPopout({label, children, defaultisPinned=false, pinningEnabled=true, ...props}) {
    const [isPinned, setIsPinned] = useState(defaultisPinned);

    return (
        <RightPopoutDiv isPinned={isPinned} {...props}>
            <TrayText leftAligned={true}>{label}</TrayText>
            {pinningEnabled && <StyledRightPin isPinned={isPinned} onClick={() => setIsPinned(!isPinned)}/>}
            {children}
        </RightPopoutDiv>
    );
}

export function BottomBanner({...props}) {
    return <BottomPopout defaultisPinned={true} pinningEnabled={false} {...props}/>;
}

export function BottomPopout({label, children, defaultisPinned=false, pinningEnabled=true, ...props}) {
    const [isPinned, setIsPinned] = useState(defaultisPinned);

    return (
        <BottomPopoutDiv isPinned={isPinned} {...props}>
            <TrayText topAligned={true}>{label}</TrayText>
            {pinningEnabled && <StyledLeftPin isPinned={isPinned} onClick={() => setIsPinned(!isPinned)}/>}
            {children}
        </BottomPopoutDiv>
    );
}

export function PreviewWindow({children, visible, ...props}) {
    const [display, setDisplay] = useState(false);
    const {setTool} = useContext(ToolOptionsContext);

    useLayoutEffect(() => {
        if (visible) {
            setDisplay(() => true);
        }
    }, [visible]);


    return display ? (
        <PreviewWindowDiv
            onTransitionEnd={() => {if (!visible) setDisplay(false);}}
            visible={visible}
            {...props}
        >
            <CloseButton
                onClick={() => {
                    setTool('Cursor');
                }}
            >✖</CloseButton>
            {children}
        </PreviewWindowDiv>
    ) : null;
}

export const PreviewWindowDiv = styled.div`
    position: absolute; 
    top: ${headerHeight};
    left: calc(100vw - 300px);
    height: 350px;
    width: 300px;
    padding: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.UI100};
    border: 1px solid black;

    opacity: ${({visible}) => visible ? 1 : 0};
    transition: opacity 250ms;

    animation: fadein 250ms;

    z-index: ${theme.zIndices.iconPreview};

    ${({visible}) => visible &&
    `
        :hover {
            opacity: 1;
            z-index: ${theme.zIndices.popoutTray + 1}
        }
    `
}
`;

export const LeftPopoutDiv = styled.div`
    position: absolute;
    top: ${({top}) => top};
    left: ${({isPinned, expandedWidth, hiddenWidth}) => isPinned ? '0px' : `calc(${hiddenWidth} - ${expandedWidth})`};
    height: ${({height}) => height};
    width: ${({expandedWidth}) => expandedWidth};
    z-index: ${theme.zIndices.popoutTray};

    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    background-color: ${theme.colors.UI200};
    border-radius: 0px 5px 5px 0px;
    box-shadow: ${theme.shadows.UI100};

    transition: left 250ms;
    transition-delay: 150ms;

    :hover {
        left: 0px;
        transition-delay: 0s;
    }
`;

export const RightPopoutDiv = styled.div`
    position: absolute;
    top: ${({top}) => top};
    right: ${({isPinned, expandedWidth, hiddenWidth}) => isPinned ? '0px' : `calc(${hiddenWidth} - ${expandedWidth})`};
    height: ${({height}) => height};
    width: ${({expandedWidth}) => expandedWidth};
    z-index: ${theme.zIndices.popoutTray};

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: ${theme.colors.UI200};
    border-radius: 5px 0px 0px 5px;
    box-shadow: ${theme.shadows.UI100};

    transition: right 250ms;
    transition-delay: 150ms;

    :hover {
        right: 0px;
        transition-delay: 0s;
    }
`;

export const BottomPopoutDiv = styled.div`
    position: absolute;
    bottom: ${({isPinned, expandedHeight, hiddenHeight}) => isPinned ? '0px' : `calc(${hiddenHeight} - ${expandedHeight})`};
    left: ${({left}) => left};
    width: ${({width}) => width};
    height: ${({expandedHeight}) => expandedHeight};
    z-index: ${theme.zIndices.popoutTray};

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: ${theme.colors.UI200};
    border-radius: 5px 5px 0px 0px;
    box-shadow: ${theme.shadows.UI100};

    transition: bottom 250ms;
    transition-delay: 150ms;

    :hover {
        bottom: 0px;
        transition-delay: 0s;
    }
`;

export const TrayText = styled.p`
    ${({leftAligned, rightAligned, topAligned}) => {
        if (leftAligned) return 'margin-left: 2px;';
        else if (rightAligned) return 'margin-right: 5px; margin-left: 2px;';
        else if (topAligned) return `
            margin-bottom: 2px;
            margin-top: 2px;
        `;
    }}

    ${({topAligned}) => topAligned ? '' : `
        text-orientation: upright;
        writing-mode: vertical-lr;
    `}

    text-decoration: ${theme.text.decorationPrimary};
    color: ${theme.text.colorPrimary};
    font-family: ${theme.text.fontPrimary};
    font-size: ${theme.text.fontSizePrimary};
`;
