import React, { useContext } from 'react';
import styled from '@emotion/styled';
import UtilityTile from './utility_tile';
import { Barricade, Drone, Reinforcement, Rotate } from '../../../../models/utility';
import { OperatorContext } from '../../../../contexts/operators';
import { Unknown } from '../../../../models/operators';
import RadioButton from '../../../../elements/radio_button';
import { ToolOptionsContext } from '../../../../contexts/tool_options';
import { Button } from '../../../../elements';

export default function ToolWindow({operatorIndex, closeToolWindow, ...props}) {
    const {operators, secondaryUtility, mainOperatorVisible, setOperator} = useContext(OperatorContext);
    const {
        tool,
        toolOperator,
        setTool,
        setPencilColor,
        setHighlighterColor,
        setTextFontColor,
        setShapeColor
    } = useContext(ToolOptionsContext);

    const operator = mainOperatorVisible[operatorIndex] ? operators[operatorIndex].main : operators[operatorIndex].alt;

    const pencilSelected = tool === 'Pencil' && toolOperator == operator.name;
    const highlighterSelected = tool === 'Highlighter' && toolOperator == operator.name;
    const textSelected = tool === 'Text' && toolOperator == operator.name;

    const operatorSecondaryUtilityIndex = mainOperatorVisible[operatorIndex] ? secondaryUtility[operatorIndex].main : secondaryUtility[operatorIndex].alt;
    return (
        <ToolWindowWrapper>
            <IconButtonsWrapper {...props} >
                <CloseButton onClick={() => closeToolWindow()}>✖</CloseButton>
                <UtilityTile utility={{icon: operator.icon}} operator={operator} dontColor={true} />
                {operator.primaryGadget?.[0] && operator.primaryGadget.map((primaryGadget) => 
                    <>
                        <UtilityTile key={primaryGadget.name} utility={primaryGadget} operator={operator}/>
                        {primaryGadget?.alternateUtilities.length > 0 && primaryGadget.alternateUtilities.map((alternateUtility) => 
                            <UtilityTile key={alternateUtility.name} utility={alternateUtility} operator={operator}></UtilityTile>
                        )}
                    </>
                )}
                {operator.name !== Unknown.name && typeof operatorSecondaryUtilityIndex !== 'object' /* aka list */ &&
                    <UtilityTile utility={operator.secondaryGadgets[operatorSecondaryUtilityIndex]} operator={operator}/>
                }
                { operator.name !== Unknown.name && typeof operatorSecondaryUtilityIndex === 'object' /* aka List */ &&
                    operatorSecondaryUtilityIndex.map(gadgetIndex => 
                        <UtilityTile key={gadgetIndex} utility={operator.secondaryGadgets[gadgetIndex]} operator={operator}/>
                    )
                }
                {operator.isAttacker ? (
                    <UtilityTile utility={Drone} operator={operator} />
                ) : (
                    <>
                        <UtilityTile utility={Reinforcement} operator={operator}/>
                        <UtilityTile utility={Rotate} operator={operator}/>
                        <UtilityTile utility={Barricade} operator={operator}/>
                        {operator.name === Unknown.name && <UtilityTile utility={Drone} operator={operator}/>}
                    </>
                )}
            </IconButtonsWrapper>
            <ToolButtonsWrapper>
                <RadioButton
                    selected={pencilSelected}
                    onClick={() => {
                        setTool('Pencil', operator.name);
                        setPencilColor(operator.color);
                    }}
                >Pencil</RadioButton>
                <RadioButton
                    selected={highlighterSelected}
                    onClick={() => {
                        setTool('Highlighter', operator.name);
                        setHighlighterColor(operator.color);
                    }}
                >Highlight</RadioButton>
                <RadioButton
                    selected={textSelected}
                    onClick={() => {
                        setTool('Text', operator.name);
                        setTextFontColor(operator.color);
                    }}
                >Text</RadioButton>
                <RadioButton
                    selected={textSelected}
                    onClick={() => {
                        setTool('Shape', operator.name);
                        setShapeColor(operator.color);
                    }}
                >Shape</RadioButton>
                <ColorChangeButton>
                    <OperatorColorChangeInput
                        type='color'
                        value={operator.color}
                        onChange={event => {
                            const updatedOperator = {
                                ...operator,
                                color: event.target.value
                            };
                            setOperator(operatorIndex, updatedOperator, mainOperatorVisible[operatorIndex]);
                        }}
                    />
                    Color
                </ColorChangeButton>
            </ToolButtonsWrapper>
        </ToolWindowWrapper>
    );
}

const ToolWindowWrapper = styled.div`
    height: calc(100% - 20px);
    width: calc(100% - 40px);
    padding: 10px 20px;

    display: flex;
    flex-direction: row;

    background: white;
`;

const IconButtonsWrapper = styled.div`
    width: 80%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

const ToolButtonsWrapper = styled.div`
    width: 20%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

const CloseButton = styled.div`
    display: grid;
    place-item: center;

    position: absolute;
    top: -30px;
    left: 10px;

    font-size: 24px;
    color: #000;

    cursor: pointer;
`;

const ColorChangeButton = styled(Button)`
    position: relative;
    width: 100px;
`;

const OperatorColorChangeInput = styled.input`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
`;
