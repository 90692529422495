import React from 'react';
import styled from '@emotion/styled';
import theme from '../../theme';

export default function ContextMenuCell({children, ...props}) {
    return (
        <Cell id='context-menu-cell' {...props}>
            {children}
        </Cell>
    );
}

const Cell = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    font-size: ${theme.text.fontSizeSecondary};
    color: ${theme.colors.contextMenuTextColor};    

    height: ${theme.sizing.contextMenuCellHeight}px;
    width: calc(100% - 25px);
    padding: 0px 5px 0px 20px;

    :hover {
        background-color: rgba(0, 0, 0, .3);
        cursor: pointer;
    }
`;
