import React, { createContext, useCallback, useMemo, useReducer} from 'react';

import {
    initialState,
    DrawingActions,
    DrawingReducer
} from '../../reducers/drawing';

export const DrawingContext = createContext();

export const DrawingProvider = ({children}) => {
    const [state, dispatch] = useReducer(DrawingReducer, initialState);

    const setMouseDown = useCallback((button) => dispatch({type: DrawingActions.setMouseDown, button}), [dispatch]);
    const setMouseUp = useCallback((button) => dispatch({type: DrawingActions.setMouseUp, button}), [dispatch]);
    const startLine = useCallback((floor, color, width, operator, lineStart, endCap, lineStyle, isFreehand) => dispatch({
        type: DrawingActions.startLine,
        floor,
        color,
        width,
        operator,
        lineStart,
        endCap,
        lineStyle,
        isFreehand
    }), [dispatch]);
    const extendLine = useCallback((x, y) => dispatch({
        type: DrawingActions.extendLine,
        x,
        y
    }), [dispatch]);
    const removeLine = useCallback((id) => dispatch({
        type: DrawingActions.removeLine,
        id
    }), [dispatch]);
    const clearLines = useCallback((floors) => dispatch({
        type: DrawingActions.clearLines,
        floors
    }), [dispatch]);
    const startHighlight = useCallback((floor, color, operator) => dispatch({
        type: DrawingActions.startHighlight,
        floor,
        color,
        operator
    }), [dispatch]);
    const extendHighlight = useCallback((x, y) => dispatch({
        type: DrawingActions.extendHighlight,
        x,
        y
    }), [dispatch]);
    const removeHighlight = useCallback((id) => dispatch({
        type: DrawingActions.removeHighlight,
        id
    }), [dispatch]);
    const clearHighlights = useCallback((floors) => dispatch({
        type: DrawingActions.clearHighlights,
        floors
    }), [dispatch]);
    const drawIcon = useCallback((icon, floor, width, height, rotation, color, x, y, operator) => dispatch({
        type: DrawingActions.drawIcon,
        icon,
        floor,
        width,
        height,
        rotation,
        color,
        x, 
        y,
        operator
    }), [dispatch]);
    const removeIcon = useCallback((id) => dispatch({
        type: DrawingActions.removeIcon,
        id
    }), [dispatch]);
    const clearIcons = useCallback((floors) => dispatch({
        type: DrawingActions.clearIcons,
        floors
    }), [dispatch]);
    const drawText = useCallback((floor, text, operator, x, y, options) => dispatch({
        type: DrawingActions.drawText,
        floor,
        text,
        operator,
        x, 
        y, 
        options
    }), [dispatch]);
    const setSelectedText = useCallback(id => dispatch({
        type: DrawingActions.setSelectedText,
        id
    }), [dispatch]);
    const updateText = useCallback((text) => dispatch({
        type: DrawingActions.updateText,
        text
    }), [dispatch]);
    const updateTextOptions = useCallback((options) => dispatch({
        type: DrawingActions.updateTextOptions,
        options
    }), [dispatch]);
    const moveCursor = useCallback(position => dispatch({
        type: DrawingActions.moveCursor,
        position
    }), [dispatch]);
    const removeText = useCallback((id) => dispatch({
        type: DrawingActions.removeText,
        id
    }), [dispatch]);
    const clearText = useCallback((floors) => dispatch({
        type: DrawingActions.clearText,
        floors
    }), [dispatch]);
    const startShape = useCallback((shapeType, floor, position, operator, color, thickness, filled) => dispatch({
        type: DrawingActions.startShape,
        shapeType,
        floor,
        position,
        operator,
        color,
        thickness,
        filled
    }), [dispatch]);
    const resizeShape = useCallback((dx, dy) => dispatch({
        type: DrawingActions.resizeShape,
        dx,
        dy
    }), [dispatch]);
    const removeShape = useCallback(id => dispatch({
        type: DrawingActions.removeShape,
        id
    }), [dispatch]);
    const clearShapes = useCallback(floors => dispatch({
        type: DrawingActions.clearShapes,
        floors
    }), [dispatch]);
    const importDrawing = useCallback((lines, highlights, shapes, icons, text) => dispatch({
        type: DrawingActions.import,
        lines,
        highlights,
        shapes,
        icons,
        text
    }), [dispatch]);

    const value = useMemo(() => {
        return {
            ...state,
            setMouseDown,
            setMouseUp,
            startLine,
            extendLine,
            removeLine,
            clearLines,
            startHighlight,
            extendHighlight,
            removeHighlight,
            clearHighlights,
            drawIcon,
            removeIcon,
            clearIcons,
            drawText,
            setSelectedText,
            moveCursor,
            updateText,
            updateTextOptions,
            removeText,
            clearText,
            startShape,
            resizeShape,
            removeShape,
            clearShapes,
            importDrawing
        };
    }, [
        state,
        setMouseDown,
        setMouseUp,
        startLine,
        extendLine,
        removeLine,
        clearLines,
        startHighlight,
        extendHighlight,
        removeHighlight,
        clearHighlights,
        drawIcon,
        removeIcon,
        clearIcons,
        drawText,
        setSelectedText,
        moveCursor,
        updateText,
        updateTextOptions,
        removeText,
        clearText,
        startShape,
        resizeShape,
        removeShape,
        clearShapes,
        importDrawing
    ]);

    return (
        <DrawingContext.Provider value={value}>
            {children}
        </DrawingContext.Provider>
    );
};
