import React from 'react';
import styled from '@emotion/styled';
import { headerHeight } from '../../../../constants';

import { LeftPopout } from '../..';
import MapButtons from './map_button';
import FloorButtons from './floor_button';

export default function MapPopout(props) {
    return (
        <LeftPopout
            top={`calc(${headerHeight} + 25px)`}
            height='125px'
            hiddenWidth='25px'
            expandedWidth='675px'
            label='Maps'
            {...props}
        >
            <TrayBody>
                <MapButtons />
                <FloorButtons />
            </TrayBody>
        </LeftPopout>
    );
}

const TrayBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
