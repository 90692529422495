import icons from '../assets/icons';

function Utility(name, count, icon, usagePer=1, alternateUtilities=[]) {
    this.name = name;
    this.count = count;
    this.icon = icon;
    this.usagePer = usagePer;
    this.alternateUtilities = alternateUtilities;
}

function UtilityAlternate(name, alternateFor, icon, usagePer=1) {
    this.name = name;
    this.alternateFor = alternateFor;
    this.icon = icon;
    this.usagePer = usagePer;
}

// Primary Utility
const AceGadget = new Utility('AceGadget', 3, icons.AceGadget);
const AlibiGadget = new Utility('AlibiGadget', 3, icons.AlibiGadget);
const AmaruGadget = new Utility('AmaruGadget', Infinity, icons.AmaruGadget);
const AruniGadget = new Utility('AruniGadget', 3, icons.AruniGadget);
const AshGadget = new Utility('AshGadget', 2, icons.AshGadget);
const AzamiGadget = new Utility('AzamiGadget', 5, icons.AzamiGadget);
const BanditGadget = new Utility('BanditGadget', 4, icons.BanditGadget);
const BlackbeardGadget = new Utility('BlackbeardGadget', 2, icons.BlackbeardGadget);
const BlitzGadget = new Utility('BlitzGadget', 4, icons.BlitzGadget);
const BravaGadget = new Utility('BravaGadget', 2, icons.BravaGadget);
const BuckGadget = new Utility('BuckGadget', 31, icons.BuckGadget);
const CapitaoSmokeGadget = new Utility('CapitaoSmokeGadget', 2, icons.CapitaoSmokeGadget);
const CapitaoFireGadget = new Utility('CapitaoFireGadget', 2, icons.CapitaoFireGadget);
const CastleGadget = new Utility('CastleGadget', 4, icons.CastleGadget);
const CaveiraGadget = new Utility('CaveiraGadget', Infinity, icons.CaveiraGadget);
const ClashGadget = new Utility('ClashGadget', Infinity, icons.ClashGadget);
const DeimosGadget = new Utility('DeimosGadget', 3, icons.DeimosGadget);
const DocGadget = new Utility('DocGadget', 3, icons.DocGadget);
const DokkaebiGadget = new Utility('DokkaebiGadget', 2, icons.DokkaebiGadget);
const EchoGadget = new Utility('EchoGadget', 2, icons.EchoGadget);
const ElaGadget = new Utility('ElaGadget', 3, icons.ElaGadget);
const FenrirGadget = new Utility('FenrirGadget', 4, icons.FenrirGadget);
const FinkaGadget = new Utility('FinkaGadget', 3, icons.FinkaGadget);
const FloresGadget = new Utility('FloresGadget', 4, icons.FloresGadget);
const FrostGadget = new Utility('FrostGadget', 3, icons.FrostGadget);
const FuzeGadget = new Utility('FuzeGadget', 4, icons.FuzeGadget);
const GlazGadget = new Utility('GlazGadget', Infinity, icons.GlazGadget);
const GoyoGadget = new Utility('GoyoGadget', 4, icons.GoyoGadget);
const GridlockGadget = new Utility('GridlockGadget', 4, icons.GridlockGadget);
const GrimGadget = new Utility('GrimGadget', 5, icons.GrimGadget);
const HibanaGadget = new Utility('HibanaGadget', 18, icons.HibanaGadget, 6);
const IanaGadget = new Utility('IanaGadget', Infinity, icons.IanaGadget);
const IqGadget = new Utility('IqGadget', Infinity, icons.IqGadget);
const JackalGadget = new Utility('JackalGadget', 3, icons.JackalGadget);
const JagerGadget = new Utility('JagerGadget', 3, icons.JagerGadget);
const KaliGadget = new Utility('KaliGadget', 4, icons.KaliGadget);
const KaidGadget = new Utility('KaidGadget', 2, icons.KaidGadget);
const KapkanGadget = new Utility('KapkanGadget', 5, icons.KapkanGadget);
const LesionGadget = new Utility('LesionGadget', 8, icons.LesionGadget);
const LionGadget = new Utility('LionGadget', 3, icons.LionGadget);
const MaestroGadget = new Utility('MaestroGadget', 3, icons.MaestroGadget);
const MaverickGadget = new Utility('MaverickGadget', 6, icons.MaverickGadget);
const MelusiGadget = new Utility('MelusiGadget', 4, icons.MelusiGadget);
const MiraGadget = new Utility('MiraGadget', 2, icons.MiraGadget);
const MontagneGadget = new Utility('MontagneGadget', Infinity, icons.MontagneGadget);
const MozzieGadget = new Utility('MozzieGadget', 3, icons.MozzieGadget);
const MuteGadget = new Utility('MuteGadget', 4, icons.MuteGadget);
const NokkGadget = new Utility('NokkGadget', Infinity, icons.NokkGadget);
const NomadGadget = new Utility('NomadGadget', 3, icons.NomadGadget);
const OryxGadget = new Utility('OryxGadget', Infinity, icons.OryxGadget);
const OsaGadget = new Utility('OsaGadget', 2, icons.OsaGadget);
const PulseGadget = new Utility('PulseGadget', Infinity, icons.PulseGadget);
const RamGadget = new Utility('RamGadget', 3, icons.RamGadget);
const RookGadget = new Utility('RookGadget', 1, icons.RookGadget);
const SensGadget = new Utility('SensGadget', 4, icons.SensGadget);
const SledgeGadget = new Utility('SledgeGadget', 25, icons.SledgeGadget);
const SmokeGadget = new Utility('SmokeGadget', 3, icons.SmokeGadget);
const SolisGadget = new Utility('SolisGadget', Infinity, icons.SolisGadget);
const TachankaGadget = new Utility('TachankaGadget', 14, icons.TachankaGadget);
const ThatcherGadget = new Utility('ThatcherGadget', 3, icons.ThatcherGadget);
const ThermiteGadget = new Utility('ThermiteGadget', 2, icons.ThermiteGadget);
const ThornGadget = new Utility('ThornGadget', 3, icons.ThornGadget);
const ThunderbirdGadget = new Utility('ThunderbirdGadget', 3, icons.ThunderbirdGadget);
const TubaraoGadget = new Utility('TubaraoGadget', 3, icons.TubaraoGadget);
const TwitchGadget = new Utility('TwitchGadget', 2, icons.TwitchGadget);
const ValkyrieGadget = new Utility('ValkyrieGadget', 3, icons.ValkyrieGadget);
const VigilGadget = new Utility('VigilGadget', Infinity, icons.VigilGadget);
const WamaiGadget = new Utility('WamaiGadget', 6, icons.WamaiGadget);
const WardenGadget = new Utility('WardenGadget', Infinity, icons.WardenGadget);
const YingGadget = new Utility('YingGadget', 4, icons.YingGadget);
const ZeroGadget = new Utility('ZeroGadget', 4, icons.ZeroGadget);
const ZofiaConcussionGadget = new Utility('ZofiaConcussionGadget', 2, icons.ZofiaConcussionGadget);
const ZofiaImpactGadget = new Utility('ZofiaImpactGadget', 2, icons.ZofiaImpactGadget);
const SkoposTalosGadget = new Utility('SkoposTalosGadget', Infinity, icons.SkoposTalosGadget);
const SkoposKolossosGadget = new Utility('SkoposKolossosGadget', Infinity, icons.SkoposKolossosGadget);

// Secondary Utility
const SmokeGrenade = new Utility('SmokeGrenade', 2, icons.SmokeGrenade);
const Claymore = new Utility('Claymore', 2, icons.Claymore);
const ImpactEmp = new Utility('ImpactEmp', 2, icons.ImpactEmp);
const ImpactGrenade = new Utility('ImpactGrenade', 2, icons.ImpactGrenade);
const NitroCell = new Utility('NitroCell', 1, icons.NitroCell);
const StunGrenade = new Utility('StunGrenade', 3, icons.StunGrenade);
const BulletproofCamera = new Utility('BulletproofCamera', 1, icons.BulletproofCamera);
const BarbedWire = new Utility('BarbedWire', 2, icons.BarbedWire);
const HardbreachTool = new Utility('HardbreachTool', 2, icons.HardbreachTool);
const BreachCharge = new Utility('BreachCharge', 3, icons.BreachCharge);
const ProximityAlarm = new Utility('ProximityAlarm', 2, icons.ProximityAlarm);
const FragGrenade = new Utility('FragGrenade', 2, icons.FragGrenade);
const DeployableShield = new Utility('DeployableShield', 1, icons.DeployableShield);
const ObservationBlocker = new Utility('ObservationBlocker', 3, icons.ObservationBlocker);

// Tertiary Utility
const GonneSix = new Utility('GonneSix', 1, icons.GonneSix);

// Misc Icons
const Unknown = new Utility('UnknownIcon', Infinity, icons.UnknownIcon);
const Drone = new Utility('Drone', 10, icons.Drone);
const Rotate = new Utility('RotateIcon', Infinity, icons.RotateIcon);
const Barricade = new Utility('BarricadeIcon', Infinity, icons.BarricadeIcon);
const Reinforcement = new Utility('Reinforcement', 10, icons.Reinforcement);

// Alternate Utilities
const HibanaGadgetx4 = new UtilityAlternate('HibanaGadgetx4', HibanaGadget, icons.HibanaGadgetx4, 4);
const HibanaGadgetx2 = new UtilityAlternate('HibanaGadgetx2', HibanaGadget, icons.HibanaGadgetx2, 2);
HibanaGadget.alternateUtilities = [HibanaGadgetx4, HibanaGadgetx2];

export const utilities = {
    AceGadget,
    AlibiGadget,
    AmaruGadget,
    AruniGadget,
    AshGadget,
    AzamiGadget,
    BanditGadget,
    BlackbeardGadget,
    BlitzGadget,
    BravaGadget,
    BuckGadget,
    CapitaoSmokeGadget,
    CapitaoFireGadget,
    CastleGadget,
    CaveiraGadget,
    ClashGadget,
    DeimosGadget,
    DocGadget,
    DokkaebiGadget,
    EchoGadget,
    ElaGadget,
    FenrirGadget,
    FinkaGadget,
    FloresGadget,
    FrostGadget,
    FuzeGadget,
    GlazGadget,
    GoyoGadget,
    GridlockGadget,
    GrimGadget,
    HibanaGadget,
    IanaGadget,
    IqGadget,
    JackalGadget,
    JagerGadget,
    KaliGadget,
    KaidGadget,
    KapkanGadget,
    LesionGadget,
    LionGadget,
    MaestroGadget,
    MaverickGadget,
    MelusiGadget,
    MiraGadget,
    MontagneGadget,
    MozzieGadget,
    MuteGadget,
    NokkGadget,
    NomadGadget,
    OryxGadget,
    OsaGadget,
    PulseGadget,
    RamGadget,
    RookGadget,
    SensGadget,
    SledgeGadget,
    SmokeGadget,
    SolisGadget,
    TachankaGadget,
    ThatcherGadget,
    ThermiteGadget,
    ThornGadget,
    ThunderbirdGadget,
    TubaraoGadget,
    TwitchGadget,
    ValkyrieGadget,
    VigilGadget,
    WamaiGadget,
    WardenGadget,
    YingGadget,
    ZeroGadget,
    ZofiaConcussionGadget,
    ZofiaImpactGadget,
    SmokeGrenade,
    Claymore,
    ImpactEmp,
    ImpactGrenade,
    NitroCell,
    StunGrenade,
    BulletproofCamera,
    BarbedWire,
    HardbreachTool,
    BreachCharge,
    ProximityAlarm,
    FragGrenade,
    DeployableShield,
    ObservationBlocker,
    GonneSix,
    Unknown,
    Drone,
    Rotate,
    Barricade,
    Reinforcement,
    SkoposTalosGadget,
    SkoposKolossosGadget,
};

export const alternateUtilities = {
    HibanaGadgetx2,
    HibanaGadgetx4
};

//Default 0 everything.
const defaultUtilityCounts = Object.fromEntries(Object.entries(utilities).map(([key,]) => [key, 0]));
// Update utility counts for items which always 
// exist.
defaultUtilityCounts.Reinforcement = Reinforcement.count;
defaultUtilityCounts.Rotate = Rotate.count;
defaultUtilityCounts.Barricade = Barricade.count;

const iconToUtility = Object.fromEntries(Object.entries(utilities).map(([key, utility]) => [utility.icon, key]));
const iconToAltUtility = Object.fromEntries(Object.entries(alternateUtilities).map(([key, utility]) => [utility.icon, key]));

export {
    AceGadget,
    AlibiGadget,
    AmaruGadget,
    AruniGadget,
    AshGadget,
    AzamiGadget,
    BanditGadget,
    BlackbeardGadget,
    BlitzGadget,
    BravaGadget,
    BuckGadget,
    CapitaoSmokeGadget,
    CapitaoFireGadget,
    CastleGadget,
    CaveiraGadget,
    ClashGadget,
    DocGadget,
    DokkaebiGadget,
    DeimosGadget,
    EchoGadget,
    ElaGadget,
    FenrirGadget,
    FinkaGadget,
    FloresGadget,
    FrostGadget,
    FuzeGadget,
    GlazGadget,
    GoyoGadget,
    GridlockGadget,
    GrimGadget,
    HibanaGadget,
    HibanaGadgetx4,
    HibanaGadgetx2,
    IanaGadget,
    IqGadget,
    JackalGadget,
    JagerGadget,
    KaliGadget,
    KaidGadget,
    KapkanGadget,
    LesionGadget,
    LionGadget,
    MaestroGadget,
    MaverickGadget,
    MelusiGadget,
    MiraGadget,
    MontagneGadget,
    MozzieGadget,
    MuteGadget,
    NokkGadget,
    NomadGadget,
    OryxGadget,
    OsaGadget,
    PulseGadget,
    RamGadget,
    RookGadget,
    SensGadget,
    SledgeGadget,
    SmokeGadget,
    SolisGadget,
    TachankaGadget,
    ThatcherGadget,
    ThermiteGadget,
    ThunderbirdGadget,
    TubaraoGadget,
    TwitchGadget,
    ValkyrieGadget,
    VigilGadget,
    WamaiGadget,
    WardenGadget,
    YingGadget,
    ZeroGadget,
    ZofiaConcussionGadget,
    ZofiaImpactGadget,
    SmokeGrenade,
    Claymore,
    ImpactEmp,
    ImpactGrenade,
    NitroCell,
    StunGrenade,
    BulletproofCamera,
    BarbedWire,
    HardbreachTool,
    BreachCharge,
    ProximityAlarm,
    FragGrenade,
    DeployableShield,
    ObservationBlocker,
    GonneSix,
    Unknown,
    Drone,
    Rotate,
    Barricade,
    Reinforcement,
    defaultUtilityCounts,
    iconToUtility,
    iconToAltUtility,
    SkoposTalosGadget,
    SkoposKolossosGadget,
};
