import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import theme from '../../theme';

export default function Slider({onChange, stages, defaultValue, displayValue=true, ...props}) {
    const [sliderValue, setSliderValue] = useState(defaultValue);
    const [sliderActive, setSliderActive] = useState(false);

    const onValueChange = useCallback((event) => {
        const value = event.target.value;
        const closestStage = stages.reduce((accumulator, stage, index, arr) => {
            if (Math.abs(value - stage) <= Math.abs(value - arr[accumulator])) {
                return index;
            }

            return accumulator;
        }, 0);

        const snappedValue = stages[closestStage];
        onChange(snappedValue);
        setSliderValue(snappedValue);
    }, [stages, onChange, setSliderValue]);

    return (
        <SliderWrapper {...props}>
            <SliderBar>
                <SliderBarIncluded sliderIndex={stages.indexOf(sliderValue)} sliderLength={stages.length - 1}/>
                <SliderBarExcluded sliderIndex={stages.indexOf(sliderValue)} sliderLength={stages.length - 1}/>
                <SliderGrabber 
                    sliderIndex={stages.indexOf(sliderValue)}
                    sliderLength={stages.length - 1}
                >{sliderActive && displayValue && sliderValue}</SliderGrabber>
                <SliderInput
                    type='range'
                    onChange={onValueChange}
                    onMouseDown={() => setSliderActive(true)}
                    onMouseUp={() => setSliderActive(false)}
                    min={stages[0]}
                    max={stages.at(-1)}
                />
                {stages.map((stage, index) => 
                    <Dot 
                        key={stage}
                        title={stage}
                        index={index} 
                        totalDots={stages.length}
                        onClick={() => onValueChange({
                            target: {
                                value: stage
                            }
                        })} />
                )}
            </SliderBar>
        </SliderWrapper>
    );
} 

const SliderInput = styled.input`
    position: absolute;
    top: 0;
    left: -7%;
    height: 100%;
    width: 114%;
    opacity: 0;

    cursor: pointer;
    :active {
        cursor: none;
    }
`;

const SliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
`;

const SliderBar = styled.div`
    position: relative;
    width: 100%;
    height: 5px;

    margin-block: 10px;
    border-radius: 5px;
`;

const SliderBarIncluded = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: calc(100% * ${({sliderIndex, sliderLength}) => (sliderLength - sliderIndex) / (sliderLength)});
    background-color: ${theme.colors.lightGreen};
    border-radius: 5px;
`;

const SliderBarExcluded = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% * ${({sliderIndex, sliderLength}) => sliderIndex / (sliderLength)});
    right: 0;
    background-color: ${theme.colors.lightGreen};
    border-radius: 5px;

    opacity: 0.15;
`;

const SliderGrabber = styled.div`
    position: absolute;
    top: 50%;
    right: calc(100% * ${({sliderIndex, sliderLength}) => (sliderLength - sliderIndex) / (sliderLength)});
    transform: translate(50%, -50%);

    border-radius: 100%;
    border: 1px solid black;
    background-color: white;

    height: 18px;
    width: 18px;

    display: grid;
    place-items: center;
    font-size: 9px;
    font-weight: bold;
`;

const Dot = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 100%;

    position: absolute;
    bottom: -15px;
    left: ${({index, totalDots}) => `calc((100% * ${index / (totalDots - 1)}) - 2.5px`});
    background: rgba(0, 0, 0, .5);

    cursor: pointer;
`;
