import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import theme from '../../../../../theme';

import { ToolOptionsContext } from '../../../../../contexts/tool_options';
import Dropdown from '../../../../../elements/dropdown';
import { Circle, Rect } from '../../../../../models/drawables';
import { MapBackgroundContext } from '../../../../../contexts/map_background';
import Slider from '../../../../../elements/slider';
import ToggleSwitch from '../../../../../elements/toggle_switch';

export default function ShapeOptions(props) {
    const {
        shapeType,
        shapeColor,
        shapeThickness,
        shapeIsFilled,
        setShapeType,
        setShapeColor,
        setShapeThickness,
        setShapeIsFilled
    } = useContext(ToolOptionsContext);

    const {floor} = useContext(MapBackgroundContext);

    const ExampleShape = useMemo(() => {
        switch (shapeType) {
        case 'placeholder':
        case 'rect': {
            return new Rect(floor, [3, 3], [44, 44], null, shapeColor, shapeThickness, shapeIsFilled);
        }
        case 'circle': {
            return new Circle(floor, [3, 3], [44, 44], null, shapeColor, shapeThickness, shapeIsFilled);
        }
        }
    }, [floor, shapeType, shapeColor, shapeThickness, shapeIsFilled]);

    const shapeThicknessStages = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10
    ];
    const filledOptions = [
        'Shape Hollow',
        'Shape Filled'
    ];
    return (
        <OptionsWrapper {...props}>
            <LabeledInput>
                <InputLabel htmlFor='shape-color-selector'>Shape Color</InputLabel>
                <ColorPickerWrapper>
                    <ExampleSvg viewBox='0 0 50 50'><ExampleShape.render /></ExampleSvg>
                    <ColorPickerContainer>
                        <InvisibleColorPicker
                            id='shape-color-selector'
                            type='color'
                            value={shapeColor}
                            onChange={e => setShapeColor(e.target.value)}
                        />
                    </ColorPickerContainer>
                </ColorPickerWrapper>
            </LabeledInput>

            <StyledDropdown
                onSelect={value => setShapeType(value)}
                value={shapeType}
                placeHolder='Shape Type'
            >
                <span value='rect'>Rectangle</span>
                <span value='circle'>Circle</span>
            </StyledDropdown>

            <LabeledInput>
                <InputLabel htmlFor='shape-thickness-selector'>Shape Thickness</InputLabel>
                <StyledSlider
                    stages={shapeThicknessStages}
                    defaultValue={shapeThickness}
                    onChange={value => setShapeThickness(value)}
                />
            </LabeledInput>

            <StyledToggleSwitch
                option1={filledOptions[0]}
                option2={filledOptions[1]}
                defaultOption={shapeIsFilled ? filledOptions[1] : filledOptions[0]}
                onClick={() => setShapeIsFilled(!shapeIsFilled)}
            />
        </OptionsWrapper>
    );
}

const StyledToggleSwitch = styled(ToggleSwitch)`
    width: 200px;
    height: 25px;
    border-radius: 0px;
`;

const StyledDropdown = styled(Dropdown)`
    width: 200px;
`;

const StyledSlider = styled(Slider)`
    width: 200px;
`;

const LabeledInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const OptionsWrapper = styled.div`
    position: relative;
    display: grid;
    place-items: center;

    width: 100%;
    height: 100%;
`;

const ColorPickerWrapper = styled.div`
    display: grid;
    place-items: center;
    position: relative;

    padding: 5px;
    border: 1px solid black;
    
    cursor: pointer;
    background-color: white;
    :hover {
        filter: brightness(0.8);
    }
`;

const ExampleSvg = styled.svg`
    height: 50px;
    width: 50px;
`;

const ColorPickerContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const InvisibleColorPicker = styled.input`
    width: 100%;
    height: 100%;

    opacity: 0;
    cursor: pointer;
`;

const InputLabel = styled.label`
    color: ${theme.text.colorSecondary};
    font-size: ${theme.text.fontSizePrimary};

    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;

    font-weight: bold;
    text-transform: uppercase;
`;
