import React, {useState, useContext, useMemo} from 'react';
import {v4} from 'uuid';
import {drawMapHeight, drawMapWidth} from '../../constants';

import useBase64 from '../../hooks/useBase64';
import {MapBackgroundContext} from '../../contexts/map_background';

const baseHeight = 700;

function FloorSVG(floor, {...props}) {
    const {
        mapWidth, 
        mapHeight,  
        mapHeight: boundingBoxHeight,
        mapWidth: boundingBoxWidth,
        finishInitialLoad
    } = useContext(MapBackgroundContext);

    // State for dealing with the background stuff.
    const [backgroundImageSize, setBackgroundImageSize] = useState({height: 0, width: 0});
    
    // useMemo to calculate value before render.
    useMemo(() => {
        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            setBackgroundImageSize({height: baseHeight, width: (baseHeight * aspectRatio)});
            finishInitialLoad();
        };
        img.src=floor.floor;
    }, [floor]);
    
    // Background Image Encoding
    const [encodedLoading, floorEncoding] = useBase64(floor.floor);
    const floorHref = encodedLoading ? floorEncoding : floor.floor;

    return (
        <g {...props} transform={floor.transform}>
            { mapHeight !== 0 && mapWidth !== 0 &&
            <image
                id='background-map'
                href={floorHref}
                height={`${baseHeight}px`}
                y={Math.round((mapHeight / 2) - (baseHeight / 2))}
                x={Math.round((mapWidth / 2) - (backgroundImageSize.width / 2))}
            />
            }
            {floor.drawables.map(Drawable => {
                const xOffset = (boundingBoxWidth - drawMapWidth) / 2;
                const yOffset = (boundingBoxHeight - drawMapHeight) / 2;
                const RelativeDrawable = Drawable.getRelative(xOffset, yOffset);
                return <RelativeDrawable.render key={Drawable.id} isEraseable={true}/>;
            })}
        </g>
    );
}

export default class Floor {
    constructor(floor, drawables, transform=null) {
        this.id = v4();
        this.floor = floor;
        this.drawables = drawables;
        this.transform = transform;
        this.render = (props) => FloorSVG(this, props);
    }

    changeFloor(newFloor) {
        this.floor = newFloor;
    }

    changeTransform(newTransform) {
        this.transform = newTransform;
    }

    changeDrawables(newDrawables) {
        this.drawables = newDrawables;
    }
}
