import styled from '@emotion/styled';
import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';

import theme from '../../../../../theme';
import { Button } from '../../../../../elements';
import { ToolOptionsContext } from '../../../../../contexts/tool_options';
import { MapBackgroundContext } from '../../../../../contexts/map_background'; 
import {LinkedIcon, UnlinkedIcon} from '../../../../../assets';
import { Icon } from '../../../../../models/drawables';
import {drawMapHeight, drawMapWidth} from '../../../../../constants';
import MinimalMap from '../../../../map/minimalMap';
import Slider from '../../../../../elements/slider';

export default function IconOptions(props) {
    const {
        mapLocked,
        hoverX,
        hoverY,
        mapHeight,
        mapWidth
    } = useContext(MapBackgroundContext);

    const {
        toolOperator,
        icon,
        iconColor,
        iconWidthFactor,
        iconHeightFactor,
        iconRotation,
        setIconWidthFactor,
        setIconHeightFactor,
        rotateIconCW,
        rotateIconCCW
    } = useContext(ToolOptionsContext);

    const [linkWidthHeight, setLinkWidthHeight] = useState(true);
    const [lastNonNullColor, setLastNonNullColor] = useState(iconColor || '#ff0000');

    const toggleLink = useCallback(() => setLinkWidthHeight(!linkWidthHeight), [setLinkWidthHeight, linkWidthHeight]);

    useEffect(() => {
        if (iconColor !== null) setLastNonNullColor(iconColor);
    }, [iconColor, lastNonNullColor]);

    const viewPortSize = {
        height: 125,
        width: 125
    };

    const sizeSliderStages = [
        0,
        25,
        50,
        75,
        100,
        125,
        150,
        175,
        200
    ];

    const xTranslate = Math.round((drawMapWidth / 2) - (hoverX + (mapWidth / 2)) + (viewPortSize.width / 2));
    const yTranslate = Math.round((drawMapHeight / 2) - (hoverY + (mapHeight / 2)) + (viewPortSize.height / 2));
    const helpText = mapLocked ? 'Right-click the map and "Unlock Icon Preview" to unlock' : 'Right-click the map and "Lock Icon Preview" to lock the preview';

    const iconPreview = useMemo(() => {
        const iconX = Math.round(viewPortSize.width / 2);
        const iconY = Math.round(viewPortSize.height / 2);
        const iconPreviewObject = new Icon(icon, '', iconWidthFactor, iconHeightFactor, iconRotation, iconColor, [iconY, iconX], toolOperator);
        return <iconPreviewObject.render id='preview-icon' />;
    }, [icon, iconColor, iconRotation, iconWidthFactor, iconHeightFactor, viewPortSize]);

    const mapPreviewTranslation = `translate(${xTranslate}, ${yTranslate})`;
    return (
        <ContentWrapper>
            <ViewPort {...props} size={viewPortSize}>
                <MapPreview transform={mapPreviewTranslation} />
                <IconPreview size={viewPortSize} >
                    {iconPreview}
                </IconPreview>
            </ViewPort>
            <MapHelpText>{helpText}</MapHelpText>
            <IconToolsDiv>
                { linkWidthHeight ? (
                    <SliderWrapper>
                        <LabeledInput>
                            <Label htmlFor="size-slider">Size</Label>
                            <Slider
                                stages={sizeSliderStages}
                                defaultValue={iconWidthFactor * 100}
                                onChange={value => {
                                    const newWidthFactor = value / 100;

                                    if (linkWidthHeight) setIconHeightFactor(newWidthFactor);
                                    setIconWidthFactor(newWidthFactor);
                                }}
                            />
                            <StyledLinkedIcon
                                onClick={() => {
                                    toggleLink();
                                    setIconHeightFactor(iconWidthFactor);
                                }} 
                            />
                        </LabeledInput>
                    </SliderWrapper>
                ) : (
                    <SliderWrapper>
                        <LabeledInput>
                            <Label htmlFor="width-slider">Width</Label>
                            <Slider
                                stages={sizeSliderStages}
                                defaultValue={iconWidthFactor * 100}
                                onChange={value => {
                                    const newWidthFactor = value / 100;

                                    if (linkWidthHeight) setIconHeightFactor(newWidthFactor);
                                    setIconWidthFactor(newWidthFactor);
                                }}
                            />
                        </LabeledInput>
                        <LabeledInput>
                            <Label htmlFor="height-slider">Height</Label>
                            <Slider
                                stages={sizeSliderStages}
                                defaultValue={iconHeightFactor * 100}
                                onChange={value => {
                                    const newHeightFactor = value / 100;

                                    if (linkWidthHeight) setIconWidthFactor(newHeightFactor);
                                    setIconHeightFactor(newHeightFactor);
                                }}
                            />
                        </LabeledInput>
                        <StyledUnlinkedIcon onClick={toggleLink} />
                    </SliderWrapper>
                )}
                <RotationButtonsWrapper>
                    <RotateButton onClick={() => rotateIconCCW(45)}>⭯</RotateButton>
                    <RotateButton onClick={ () => rotateIconCW(45)}>⭮</RotateButton>
                </RotationButtonsWrapper>
            </IconToolsDiv>
        </ContentWrapper>
    );
}

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: 15fr 10fr 75fr;
    place-items: center;
`;

const IconToolsDiv = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: flex-start;

    height: 100%;
    width: 50%;
`;

const linkIconStyling = `
    position: absolute;
    top: 20px;
    left: -40px;

    height: 25px;
    width: auto;

    cursor: pointer;
`;

const SliderWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
`;

const RotationButtonsWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-evenly
`;

const LabeledInput = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;
`;

const StyledLinkedIcon = styled(LinkedIcon)`${linkIconStyling}`;

const StyledUnlinkedIcon = styled(UnlinkedIcon)`${linkIconStyling}`;

const Label = styled.label``;

const RotateButton = styled(Button)``;

const IconPreview = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({size}) => size.width}px;
    height: ${({size}) => size.height}px;
    transform: translate(-50%, -50%);
`;

const MapPreview = styled(MinimalMap)`
    transform: translate(${({mouseX}) => mouseX}px, ${({mouseY}) => mouseY}px);
`;

const MapHelpText = styled.p`
    margin: 5px 0 10px 0;
    width: 200px;

    text-align: center;
    justify-self: center;
    overflow-wrap: break-word;

    font-family: ${theme.text.fontTertiary};
    font-size: ${theme.text.fontSizeTertiary};
    color: ${theme.text.colorPrimary};
`;

const ViewPort = styled.div`
    height: ${({size}) => size.height}px;
    width: ${({size}) => size.width}px;
    border: 1px black dotted;

    position: relative;
    overflow: hidden;
`;
