import React, { useContext } from 'react';
import styled from '@emotion/styled';

import maps, { getMapFromFloors } from '../../../../../models/maps';
import { RadioButton } from '../../../../../elements';
import { MapBackgroundContext } from '../../../../../contexts/map_background';
import { getMapFromFloor } from '../../../../../assets/maps';

export default function MapButtons(props) {
    const {
        floor,
        setMap,
    } = useContext(MapBackgroundContext);
    
    const mapButtons = Object.entries(maps).map((entry) => {
        const [key, map] = entry;
        const currentMap = getMapFromFloors(getMapFromFloor(floor));
        const selected = currentMap === map;

        const onClick = selected ? () => {} : () => {setMap(map);};
    
        return (
            <MapRadioButton onClick={onClick} key={key} selected={selected}>
                {key}
            </MapRadioButton>
        );
    });

    return (
        <MapButtonWrapper {...props}>
            {mapButtons}
        </MapButtonWrapper>
    );
}


const MapButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 7px;
    border-bottom-color: black;
    border-bottom-width: 1px;
    border-bottom-style: dotted;
`;

const MapRadioButton = styled(RadioButton)`
    width: 110px;
`;
