import React, {createContext, useCallback, useMemo, useReducer} from 'react';

import {
    initialState,
    ContextMenuActions,
    ContextMenuReducer
} from '../../reducers/context_menu';

export const ContextMenuContext = createContext();

export const ContextMenuProvider = ({children}) => {
    const [state, dispatch] = useReducer(ContextMenuReducer, initialState);

    const openContextMenu = useCallback((x, y) => dispatch({type: ContextMenuActions.openContextMenu, x, y}), [dispatch]);
    const closeContextMenu = useCallback(() => dispatch({type: ContextMenuActions.closeContextMenu}), [dispatch]);
    const setContextMenuContent = useCallback(content => dispatch({type: ContextMenuActions.setContent, content}), [dispatch]);

    const value = useMemo(() => {
        return {
            ...state,
            openContextMenu,
            closeContextMenu,
            setContextMenuContent,
        }; 
    }, [
        state,
        openContextMenu,
        closeContextMenu,
        setContextMenuContent,
    ]);

    return (
        <ContextMenuContext.Provider value={value}>
            {children}
        </ContextMenuContext.Provider>
    );
};
