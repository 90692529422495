import React, { useContext, useEffect, useMemo, useRef } from 'react';

import styled from '@emotion/styled';
import { DrawingContext } from '../../../../../contexts/drawing';
import { ToolOptionsContext } from '../../../../../contexts/tool_options';
import { Button } from '../../../../../elements';
import Slider from '../../../../../elements/slider';
import ToggleSwitch from '../../../../../elements/toggle_switch';
import { Text } from '../../../../../models/drawables';
import { MapBackgroundContext } from '../../../../../contexts/map_background';

export default function TextOptions({props}) {
    const {text, selectedText, setSelectedText, updateText, updateTextOptions, moveCursor} = useContext(DrawingContext);
    const {
        textFontSize,
        textFontWeight,
        textFontColor,
        textHasBackground,
        textRotation,
        setTextFontSize,
        setTextFontWeight,
        setTextFontColor,
        setTextHasBackground,
        setTextRotation
    } = useContext(ToolOptionsContext);

    const {floor} = useContext(MapBackgroundContext);

    const inputRef = useRef();
    const popupRef = useRef();

    useEffect(() => {
        // Timeout so that the clicking of the
        // text doesn't cause focus on this
        // input to be lost.
        setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
            
            // The use effect which handles blurring will automatically
            // set selected text back to '' when a new text is created.
            // This is because setSelectedText(id) => useEffect => setSelectedText('').
            // Solve this by 25ms later resetting the selected text. A little Jank,
            // but it works...
            if (selectedText !== '') setSelectedText(selectedText);
        }, 25);
    }, [inputRef.current, selectedText]);

    // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
    useEffect(() => {
        function handlePopupBlur(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setSelectedText('');
                moveCursor(-1);
            }
        }
        document.addEventListener('mousedown', handlePopupBlur);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handlePopupBlur);
        };
    }, [inputRef, popupRef]);

    const exampleText = useMemo(() => {
        const text = new Text(floor, 'Text', ['50%', '55%'], null, {
            fontSize: textFontSize,
            fontColor: textFontColor,
            fontWeight: textFontWeight,
        });
        return (
            <ExampleSvg>
                <g transform={`rotate(${textRotation})`} transform-origin='center center'>
                    <text.render textProps={{textAnchor: 'middle', dominantBaseline: 'middle'}}/>
                </g>
            </ExampleSvg>
        );
    }, [floor, textFontSize, textFontColor, textFontWeight, textRotation]);

    const editingText = text.find(textValue => textValue.id === selectedText);
    const textSizeStages = [
        8, 10, 12, 14, 16, 18, 20, 22, 24
    ];
    const backgroundFillOptions = [
        'Background Invisible',
        'Background Filled'
    ];
    const boldOptions = [
        'Not Bold',
        'Bold'
    ];
    return (
        <OptionsWrapper {...props} ref={popupRef}>
            <ExampleWrapper>
                <label htmlFor='color-selector'>Color</label>
                <ColorWrapper>
                    {exampleText}
                    <ColorInput
                        type='color'
                        id='color-selector'
                        value={textFontColor}
                        onChange={event => {
                            setTextFontColor(event.target.value);
                            updateTextOptions({fontColor: event.target.value});
                        }}
                    />
                </ColorWrapper>
            </ExampleWrapper>
            <StyledSlider
                stages={textSizeStages}
                defaultValue={textFontSize}
                onChange={value => {
                    setTextFontSize(value);
                    updateTextOptions({fontSize: value});
                }}
            />
            <StyledToggleSwitch
                option1={backgroundFillOptions[0]}
                option2={backgroundFillOptions[1]}
                defaultOption={textHasBackground ? backgroundFillOptions[1] : backgroundFillOptions[0]}
                onClick={() => {
                    setTextHasBackground(!textHasBackground);
                    updateTextOptions({hasBackground: !textHasBackground});
                }}
            />
            <StyledToggleSwitch
                option1={boldOptions[0]}
                option2={boldOptions[1]}
                defaultOption={textFontWeight === 'bold' ? boldOptions[1] : boldOptions[0]}
                onClick={() => {
                    const nextWeight = textFontWeight === 'bold' ? 'normal' : 'bold';
                    setTextFontWeight(nextWeight);
                    updateTextOptions({fontWeight: nextWeight});
                }}
            />
            <ButtonsWrapper>
                <Button
                    onClick={() => {
                        const newRotation = textRotation - 90;
                        setTextRotation(newRotation);
                        updateTextOptions({rotation: newRotation});
                    }}
                >⭯</Button>
                <Button
                    onClick={() => {
                        const newRotation = textRotation + 90;
                        setTextRotation(newRotation);
                        updateTextOptions({rotation: newRotation});
                    }}
                >⭮</Button>
            </ButtonsWrapper>

            {editingText &&
                <TextInput
                    type='text'
                    ref={inputRef}
                    value={editingText.text}
                    onChange={event => {
                        updateText(event.target.value);
                    }}
                    onKeyDown={event => {
                        if (event.key === 'Enter'&& !event.shiftKey) {
                            setSelectedText('');
                            moveCursor(-1);
                        } else {
                            setTimeout(() => {
                                if (inputRef.current) {
                                    moveCursor(inputRef.current.selectionStart);
                                }
                            }, 25);
                        }
                    }}
                />
            }
        </OptionsWrapper>
    );
}

const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 30px 25px 0px 0px;
`;

const ExampleWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    margin: 0 0 30px 0;

    justify-content: space-evenly;
    align-items: center;  
`;

const ColorWrapper = styled.div`
    position: relative;
    width: 50px;
    height: 50px;

    background-color: white;
    border: 1px solid black;

    :hover {
        filter: brightness(0.8);
    }
`;

const ExampleSvg = styled.svg`
    width: 100%;
    height: 100%;
`;

const ColorInput = styled.input`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;

    opacity: 0;
    cursor: pointer;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    justify-content: space-evenly;
    align-items: center;  
`;

const StyledSlider = styled(Slider)`
    width: 75%;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
    width: 75%;
    height: 30px;
    font-size: 14px;
    border-radius: 0px;
    margin-bottom: 10px;
`;

const TextInput = styled.input`
    width: 75%;
    height: 20px;

    border-radius: 0px;
    margin-top: auto;
`;
