import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { headerHeight } from '../../constants';
import theme from '../../theme';
import { ModalContext } from '../../contexts/modal';
import { ClearModal, ExportModal, ImportModal } from '../modals';

export default function Header(props) {

    const {revealModal, setModalContent} = useContext(ModalContext);
    const openClearModal = () => {
        setModalContent(<ClearModal />);
        revealModal();
    };
    const openExportModal = () => {
        setModalContent(<ExportModal />, 300, 350);
        revealModal();
    };
    const openImportModal = () => {
        setModalContent(<ImportModal />, 300, 350);
        revealModal();
    };

    return (
        <HeaderDiv {...props}>
            <LogoBox>
                <Logo src='/highres-logo-sm.png' alt="StratBook.gg" />
            </LogoBox>
            <HeaderTools>
                <HeaderTool onClick={openClearModal}>Clear</HeaderTool>
                <HeaderTool onClick={openExportModal}>Export</HeaderTool>
                <HeaderTool onClick={openImportModal}>Import</HeaderTool>
            </HeaderTools>
        </HeaderDiv>
    );
}

const HeaderDiv = styled.div`
    height: ${headerHeight};
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.UI100};
`;

const HeaderTools = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 10%;
`;

const HeaderTool = styled.div`
    margin-right: 50px;
    
    text-decoration: ${theme.text.decorationPrimary};
    color: ${theme.text.colorPrimary};
    font-family: ${theme.text.fontPrimary};
    font-size: ${theme.text.fontSizePrimary};

    transition: filter 900ms, font-size 900ms;

    :hover {
        color: red;
        cursor: pointer;
        filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, .25));
        font-size: 20px;
    }
`;

const LogoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    height: 100%;
    padding-block: 10px;
    margin-left: 10%;
`;

const Logo = styled.img`
    height: 100%;
    aspect-ratio: 1;
`;
