import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';


export default function AnimatedDiv({display, children, animationIn, animationOut, cleanUp, ...props}) {
    const [visible, setVisible] = useState(false);
    
    useEffect(() => {
        if (display) setVisible(true);
    }, [display, setVisible]);

    return display || visible ? (
        <AnimationDiv
            {...props}
            shouldDisplay={display}
            animationIn={animationIn}
            animationOut={animationOut}
            onAnimationEnd={() => {
                if (!display) {
                    setVisible(false);
                    if (cleanUp) cleanUp();
                }
            }}
        >
            {children}
        </AnimationDiv>
    ) : null;
}

const AnimationDiv = styled.div`
    animation-name: ${({shouldDisplay, animationIn, animationOut}) => shouldDisplay ? animationIn : animationOut};
    animation-iteration-count: 1;
`;
