import React, {createContext, useCallback, useMemo, useReducer} from 'react';

import {
    initialState,
    ToolOptionsActions,
    ToolOptionsReducer
} from '../../reducers/tool_options';

export const ToolOptionsContext = createContext();

export const ToolOptionsProvider = ({children}) => {
    const [state, dispatch] = useReducer(ToolOptionsReducer, initialState);

    const setTool = useCallback(
        (tool, toolOperator=null) => dispatch({type: ToolOptionsActions.setTool, tool, toolOperator}),
        [dispatch]
    );
    const setIcon = useCallback(
        (icon) => dispatch({type: ToolOptionsActions.setIcon, icon}),
        [dispatch]
    );
    const setPencilColor = useCallback(
        (color) => dispatch({type: ToolOptionsActions.setPencilColor, color}),
        [dispatch]
    );
    const setPencilWidth = useCallback(
        (width) => dispatch({type: ToolOptionsActions.setPencilWidth, width}),
        [dispatch]
    );
    const setPencilLineStart = useCallback(
        (lineStart) => dispatch({type: ToolOptionsActions.setPencilLineStart, lineStart}),
        [dispatch]
    );
    const setPencilEndCap = useCallback(
        (endCap) => dispatch({type: ToolOptionsActions.setPencilEndCap, endCap}),
        [dispatch]
    );
    const setPencilLineStyle = useCallback(
        (lineStyle) => dispatch({type: ToolOptionsActions.setPencilLineStyle, lineStyle}),
        [dispatch]
    );
    const setPencilLineIsFreehand = useCallback(
        (isFreehand) => dispatch({type: ToolOptionsActions.setPencilLineIsFreehand, isFreehand}),
        [dispatch]
    );
    const setHighlighterColor = useCallback(
        (color) => dispatch({type: ToolOptionsActions.setHighlighterColor, color}),
        [dispatch]
    );
    const setShapeType = useCallback(
        (shapeType) => dispatch({type: ToolOptionsActions.setShapeType, shapeType}),
        [dispatch]
    );
    const setShapeColor = useCallback(
        (color) => dispatch({type: ToolOptionsActions.setShapeColor, color}),
        [dispatch]
    );
    const setShapeThickness = useCallback(
        (shapeThickness) => dispatch({type: ToolOptionsActions.setShapeThickness, shapeThickness}),
        [dispatch]
    );
    const setShapeIsFilled = useCallback(
        (shapeIsFilled) => dispatch({type: ToolOptionsActions.setShapeIsFilled, shapeIsFilled}),
        [dispatch]
    );
    const toggleEraseLines = useCallback(
        () => dispatch({type: ToolOptionsActions.toggleEraseLines}),
        [dispatch]
    );
    const toggleEraseHighlights = useCallback(
        () => dispatch({type: ToolOptionsActions.toggleEraseHighlights}),
        [dispatch]
    );
    const toggleEraseText = useCallback(
        () => dispatch({type: ToolOptionsActions.toggleEraseText}),
        [dispatch]
    );
    const toggleEraseShapes = useCallback(
        () => dispatch({type: ToolOptionsActions.toggleEraseShapes}),
        [dispatch]
    );
    const toggleEraseIcons = useCallback(
        () => dispatch({type: ToolOptionsActions.toggleEraseIcons}),
        [dispatch]
    ); 
    const setIconColor = useCallback(
        (iconColor) => dispatch({type: ToolOptionsActions.setIconColor, iconColor}),
        [dispatch]
    );
    const setIconWidthFactor = useCallback(
        (widthFactor) => dispatch({type: ToolOptionsActions.setIconWidthFactor, widthFactor}),
        [dispatch]
    );
    const setIconHeightFactor = useCallback(
        (heightFactor) => dispatch({type: ToolOptionsActions.setIconHeightFactor, heightFactor}),
        [dispatch]
    );
    const rotateIconCW = useCallback(
        (rotation) => dispatch({type: ToolOptionsActions.rotateIconCW, rotation}),
        [dispatch]
    );
    const rotateIconCCW = useCallback(
        (rotation) => dispatch({type: ToolOptionsActions.rotateIconCCW, rotation}),
        [dispatch]
    );
    const setTextFontFamily = useCallback(
        (fontFamily) => dispatch({type: ToolOptionsActions.setTextFontFamily, fontFamily}),
        [dispatch]
    );
    const setTextFontStyle = useCallback(
        (fontStyle) => dispatch({type: ToolOptionsActions.setTextFontStyle, fontStyle}),
        [dispatch]
    );
    const setTextFontSize = useCallback(
        (fontSize) => dispatch({type: ToolOptionsActions.setTextFontSize, fontSize}),
        [dispatch]
    );
    const setTextFontWeight = useCallback(
        (fontWeight) => dispatch({type: ToolOptionsActions.setTextFontWeight, fontWeight}),
        [dispatch]
    );
    const setTextFontColor = useCallback(
        (fontColor) => dispatch({type: ToolOptionsActions.setTextFontColor, fontColor}),
        [dispatch]
    );
    const setTextHasBackground = useCallback(
        (hasBackground) => dispatch({type: ToolOptionsActions.setTextHasBackground, hasBackground}),
        [dispatch]
    );
    const setTextBackgroundColor = useCallback(
        (backgroundColor) => dispatch({type: ToolOptionsActions.setTextBackgroundColor, backgroundColor}),
        [dispatch]
    );
    const setTextRotation = useCallback(
        (rotation) => dispatch({type: ToolOptionsActions.setTextRotation, rotation}),
        [dispatch]
    );

    const value = useMemo(() => {
        return {
            ...state,
            setTool,
            setIcon,
            setPencilColor,
            setPencilWidth,
            setPencilLineStart,
            setPencilEndCap,
            setPencilLineStyle,
            setPencilLineIsFreehand,
            setHighlighterColor,
            setShapeType,
            setShapeColor,
            setShapeThickness,
            setShapeIsFilled,
            toggleEraseLines,
            toggleEraseHighlights,
            toggleEraseShapes,
            toggleEraseText,
            toggleEraseIcons,
            setIconColor,
            setIconWidthFactor,
            setIconHeightFactor,
            rotateIconCW,
            rotateIconCCW,
            setTextFontFamily,
            setTextFontStyle,
            setTextFontSize,
            setTextFontWeight,
            setTextFontColor,
            setTextHasBackground,
            setTextBackgroundColor,
            setTextRotation
        };
    }, [
        state,
        setTool,
        setIcon,
        setPencilColor,
        setPencilWidth,
        setPencilLineStart,
        setPencilEndCap,
        setPencilLineStyle,
        setPencilLineIsFreehand,
        setHighlighterColor,
        setShapeType,
        setShapeColor,
        setShapeThickness,
        setShapeIsFilled,
        toggleEraseLines,
        toggleEraseHighlights,
        toggleEraseShapes,
        toggleEraseText,
        toggleEraseIcons,
        setIconColor,
        setIconWidthFactor,
        setIconHeightFactor,
        rotateIconCW,
        rotateIconCCW,
        setTextFontFamily,
        setTextFontStyle,
        setTextFontSize,
        setTextFontWeight,
        setTextFontColor,
        setTextHasBackground,
        setTextBackgroundColor,
        setTextRotation
    ]);

    return (
        <ToolOptionsContext.Provider value={value}>
            {children}
        </ToolOptionsContext.Provider>
    );
};
