export async function loadImage(src) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = err => {
            reject(err);
        };
        img.src = src;
    });
}

export async function getMinimalMap() {
    const minimalMap = document.getElementById('minimal-map');
    const serializedSvg = new XMLSerializer().serializeToString(minimalMap);
    const encodedSvg = `data:image/svg+xml;base64,${window.btoa(serializedSvg)}`;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const image = await loadImage(encodedSvg);

    canvas.height = image.height;
    canvas.width = image.width;

    context.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width, image.height);
    return canvas.toDataURL('image/png');
}
