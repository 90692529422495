import { useEffect, useState } from 'react';

let memoizationCache = {}; 

export function invalidateBase64Cache() {
    memoizationCache = {};
}

export async function getImageBase64(imageSrc) {

    let data;
    let blob;
    let reader;
    if (!(imageSrc in memoizationCache)) {
        data = await fetch(imageSrc);
        blob = await data.blob();
    
        reader = new FileReader();
        reader.readAsDataURL(blob); 
    }

    return new Promise((resolve) => {
        if (imageSrc in memoizationCache) {
            if (memoizationCache[imageSrc] === 'loading') {

                // Another process is currently figuring out what the B64
                // is for this imageSrc. Recursively set a timeout
                // and wait for it to resolve to an actual cache hit.
                setTimeout(() => resolve(getImageBase64(imageSrc)), 100);
            } else {
                
                // Cache hit, resolve the promise with the cached value.
                resolve(memoizationCache[imageSrc]);
            }
        }
        else {
            memoizationCache[imageSrc] = 'loading';
            reader.onloadend = () => {
                const base64data = reader.result;  
                
                memoizationCache[imageSrc] = base64data;
                resolve(base64data);
            };
        }
    });
}

export default function useBase64(imageSrc) {
    const [encoded, setEncoded] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getImageBase64(imageSrc).then(result => {
            setEncoded(result);
            setLoaded(true);
        });
    }, [imageSrc]);

    return [loaded, encoded];
}
