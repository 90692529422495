import React, { useState, useMemo, useLayoutEffect, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import theme from '../../theme';

export default function Dropdown({children, multi, value=(multi ? [] : 'placeholder'), placeHolder, onSelect, ...props}) {
    const defaultSelection = multi ? (
        []
    ) : (
        placeHolder ? -1 : 0
    );

    const [currentSelection, setCurrentSelection] = useState(defaultSelection);
    const [expanded, setExpanded] = useState(false);
    const [elevated, setElevated] = useState(false);

    const dropdownRef = useRef();
    useEffect(() => {
        function handlePopupBlur(event) {
            if (expanded && dropdownRef?.current && !dropdownRef?.current.contains(event.target)) {
                setElevated(true);
                setExpanded(false);
            }
        }
        window.addEventListener('mousedown', handlePopupBlur, multi);

        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener('mousedown', handlePopupBlur);
        };
    }, [dropdownRef, expanded]);

    useLayoutEffect(() => {
        React.Children.forEach(children, (child, index) => {
            if (multi && value.includes(child.props.value)) {
                setCurrentSelection(prev => [...prev, index]);
                onSelect(child.props.value);
            }
            
            if (!multi && value === child.props.value) {
                setCurrentSelection(index);
                onSelect(child.props.value);
            }
        });
    }, []);
    
    const dropdownChildren = useMemo(() => {
        return React.Children.map(children, (child, index) => {
            if (!multi && index === currentSelection) return;

            if (React.isValidElement(child)) {
                // The animation index needs to be 0, 1, 2, 3, ...
                // thus, we can't just use index as the [selectedIndex]
                // th child would be removed. Instead, if we're greater
                // than the selection index, then decrement the animation
                // index by one to account for this
                let totalAnimatedChildren;
                let animationIndex;
                if (multi) {
                    animationIndex = index;
                    totalAnimatedChildren = children.length;
                }
                else {
                    totalAnimatedChildren = children.length - (currentSelection !== -1 ? 1 : 0);
                    animationIndex = (currentSelection !== -1 && currentSelection < index) ? index - 1 : index;
                }

                const selected = multi ? currentSelection.includes(index) : currentSelection === index;
                return (
                    <Cell 
                        animationIndex={animationIndex} 
                        totalChildren={totalAnimatedChildren}
                        onClick={e => {
                            if (multi) e.stopPropagation();

                            if (multi) {
                                if (currentSelection.includes(index)) setCurrentSelection(currentSelection.filter(value => value !== index));
                                else setCurrentSelection([...currentSelection, index]);
                            } else {
                                setCurrentSelection(index);
                            }

                            onSelect(child.props.value);
                        }}
                        multi={multi}
                        selected={selected}
                    >
                        {child}
                        {multi && (currentSelection.includes(index) ? '✔️' : '❌')}
                    </Cell> 
                );
            }
        });
    }, [children, currentSelection, multi]);

    return (
        <DropdownWrapper {...props} >
            <DropdownBody
                ref={dropdownRef}
                onClick={() => {
                    setExpanded(!expanded);
                    setElevated(true);
                }}
                onTransitionEnd={() => setElevated(false)}
                expanded={expanded}
                elevated={elevated}
                expandedSize={children.length + (currentSelection !== -1 && !multi ? 0 : 1)}
            >
                <SelectedCell>
                    {multi || currentSelection < 0 ? placeHolder : children[currentSelection]}
                </SelectedCell>
                {expanded && dropdownChildren}
            </DropdownBody>
        </DropdownWrapper>
    );
}

const DropdownWrapper = styled.div`
    position: relative;
    height: 20px;
    width: 100%;

    margin-block: 2px;
    margin-inline: 2px;
`;

const DropdownBody = styled.div`
    border: 2px solid black;
    background-color: white;
    cursor: pointer;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% * ${({expanded, expandedSize}) => expanded ? expandedSize : 1} + ${({expanded, expandedSize}) => expanded ? expandedSize * 2 : 1}px);
    z-index: ${({elevated, expanded}) => (elevated || expanded) ? theme.zIndices.dropdownMenu : 'inherit'};
    transition: height 250ms;

    display: grid;
    grid-template-rows: repeat(${({expanded, expandedSize}) => expanded ? expandedSize : 1}, 1fr);

    :hover {
        filter: ${({expanded}) => !expanded && 'brightness(0.8)'};
    }

    :after {
        position: absolute;
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.279 34.184"><path d="M2.39 2.867l31.75 26.459L65.89 2.867" fill="none" stroke="%23000" stroke-width="7.465"/></svg>');
        right: 10px;
        top: 11px;

        transition: transform 250ms;
        transform-origin: center;
        transform: translate(0, -50%) rotate(${({expanded}) => expanded ? 180 : 0}deg);

        width: 15px;
        height: 15px;
        display: grid;
        place-items: center;
        z-index: ${({elevated, expanded}) => (elevated || expanded)  ? theme.zIndices.dropdownMenu + 1 : 'inherit'};
    }
`;

const FadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const Cell = styled.div`
    width: calc(100% - 5px);
    height: calc(100% - 1px);
    padding-left: 5px;

    border-top: 1px solid gray;
    background-color: white;

    animation-name: ${FadeIn};
    animation-delay: ${({animationIndex, totalChildren}) => (animationIndex) * (250 / totalChildren)}ms;
    animation-duration: ${({totalChildren}) => (250 / totalChildren)}ms;
    animation-fill-mode: both;

    cursor: pointer;
    :hover {
        filter: brightness(0.75);
    }

    ${({selected}) => selected && `
        background-color: ${theme.colors.lightGreen}40;
        :hover {
            filter: brightness(0.25);
        }
    `} 

    ${({multi}) => multi && `
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `}
`;

const SelectedCell = styled(Cell)`
    height: calc(100% - 2px);
    animation: unset;
    :hover {
        filter: unset;
    }
`;
