import React, { useContext } from 'react';

import styled from '@emotion/styled';
import { headerHeight } from '../../../../constants';
import { LeftPopout } from '../..';
import { RadioButton } from '../../../../elements';
import { ToolOptionsContext } from '../../../../contexts/tool_options';

export default function ToolPopout(props) {
    const {
        tool,
        toolOperator,
        setTool
    } = useContext(ToolOptionsContext);

    const cursorSelected = tool === 'Cursor';
    const pencilSelected = tool === 'Pencil' && toolOperator === null;
    const eraserSelected = tool === 'Eraser';
    const highlighterSelected = tool === 'Highlighter' && toolOperator === null;
    const textSelected = tool === 'Text' && toolOperator === null;
    const shapeSelected = tool === 'Shape' && toolOperator === null;

    return (
        <LeftPopout
            height="125px"
            hiddenWidth="25px"
            expandedWidth="400px"
            top={`calc(${headerHeight} + 175px)`}
            label="Tools"
        >
            <TrayBody {...props}>
                <ButtonWrapper>
                    <RadioButton onClick={() => setTool('Cursor')} selected={cursorSelected}>Cursor</RadioButton>
                    <RadioButton onClick={() => setTool('Pencil')} selected={pencilSelected}>Pencil</RadioButton>
                    <RadioButton onClick={() => setTool('Eraser')} selected={eraserSelected}>Eraser</RadioButton>
                    <RadioButton onClick={() => setTool('Highlighter')} selected={highlighterSelected}>Highlight</RadioButton>
                    <RadioButton onClick={() => setTool('Text')} selected={textSelected}>Text</RadioButton>
                    <RadioButton onClick={() => setTool('Shape')} selected={shapeSelected}>Shape</RadioButton>
                </ButtonWrapper>
            </TrayBody>
        </LeftPopout>
    );
}

const TrayBody = styled.div`
    height: 100%;
    display: grid;
    place-items: center;
    flex-grow: 1;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 75px;
    align-items: center;
    justify-content: center;
`;
