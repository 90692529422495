import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import {v4} from 'uuid';

import { DrawingContext } from '../../contexts/drawing';
import { MapBackgroundContext } from '../../contexts/map_background';
import { ToolOptionsContext } from '../../contexts/tool_options';
import { OperatorContext } from '../../contexts/operators';

function SVGText(text, {textProps, ...props}) {
    const {floor} = useContext(MapBackgroundContext);
    const {tool, eraseText} = useContext(ToolOptionsContext);
    const {setSelectedText, selectedText, removeText, cursorPosition, mouseDown} = useContext(DrawingContext);
    const {operators, mainOperatorVisible} = useContext(OperatorContext);

    const [textSize, setTextSize] = useState({height: 0, width: 0});
    const [cursorXOffset, setCursorXOffset] = useState(0);

    if (floor !== text.floor) return null;
    else if (text.operator !== null && !operators.map(({main, alt}, index) => mainOperatorVisible[index] ? main.name : alt.name).includes(text.operator)) return null;

    const textOperator = operators
        .map(({main, alt}) => [main, alt])
        .flat()
        .filter(({name}) => { return name === text.operator;})?.[0];

    const [x, y] = text.relativePosition;
    const shouldErase = tool === 'Eraser' && eraseText;

    const TextElement = ({children, refFun, ...props}) => (
        <text
            ref={refFun}
            x={x}
            y={y}
            dominantBaseline='hanging'
            fontFamily={text.fontFamily}
            fontStyle={text.fontStyle}
            fontSize={text.fontSize}
            fontWeight={text.fontWeight}
            fill={textOperator ? textOperator.color : text.color}
            xmlSpace='preserve'
            white-space='pre'
            whiteSpace='pre'
            {...props}
        >{`${children || ' '}`}</text>
    );

    const rotation = text.rotation;
    let rotationOffsetX = 0;
    let rotationOffsetY = 0;
    if (rotation === 90) {
        rotationOffsetX = textSize.height / 2;
        rotationOffsetY = -2;
    } else if (rotation === 180) {
        rotationOffsetY = textSize.height / 2;
    } else if (rotation === 270) {
        rotationOffsetY = textSize.height / 2;
        rotationOffsetX = -2;
    }

    return (
        <g
            id={`text-${text.id}`}
            onMouseOver={() => {
                if (shouldErase && mouseDown.left) removeText(text.id);
            }}
            onMouseDown={e => {
                if (shouldErase && e.button === 0) removeText(text.id);
                else if (tool === 'Text') {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    setSelectedText(text.id);
                }
            }}
            transform-origin={`${x} ${y}`}
            transform={`translate(${rotationOffsetX} ${rotationOffsetY}) rotate(${rotation})`}

            {...props}
        >
            <rect
                x={x - 4}
                y={y - (textSize.height / 8) - 4} // why / 8 works I don't know, but it does :)
                height={textSize.height + 8}
                width={textSize.width + 8}
                fill={text.hasBackground ? text.backgroundColor : 'none'}
            />
            <TextElement
                refFun={textRef => {
                    if (textRef) {
                        const {width, height} = textRef.getBBox();
                        if (height !== textSize.height || width !== textSize.width) setTextSize({width, height});
                    }
                }}
                {...textProps}
            >
                {text.text}
            </TextElement>
            {selectedText === text.id && 
                <>
                    <TextElement
                        opacity={0}
                        refFun={cursorTextRef => {
                            if (cursorTextRef) {
                                const {width} = cursorTextRef.getBBox();
                                if (width !== cursorXOffset) setCursorXOffset(width);
                            }
                        }}
                    >
                        {cursorPosition === -1 ? text.text : text.text.slice(0, cursorPosition)}
                    </TextElement>
                    <TextRect
                        x={x - 5}
                        y={y - (textSize.height / 8) - 5} // why / 8 works I don't know, but it does :)
                        height={textSize.height ? textSize.height + 10 : text.fontSize}
                        width={textSize.width ? textSize.width + 10 : 20}
                        fill='none'
                        strokeDasharray='1px'
                        strokeWidth='1px'
                        stroke='black'
                    />
                    <rect
                        x={x + cursorXOffset + 1}
                        y={y - (textSize.height / 8)} // why / 8 works I don't know, but it does :)
                        width={1}
                        height={textSize.height}
                    >
                        <animate 
                            attributeType="XML"
                            attributeName="opacity"
                            values={'1;0;'}
                            dur="1s"
                            repeatCount="indefinite"
                        />
                    </rect>
                </>
            }
        </g>
    );
}

export default class Text {
    constructor(floor, text, position, operator, {fontFamily='Arial, Helvetica, sans-serif', fontStyle='normal', fontSize=16, fontWeight='normal', fontColor='#000000', hasBackground=false, backgroundColor='#ffffff', rotation=0} = {}) {
        this.id = v4();
        this.floor = floor;
        this.text = text;
        this.position = position;
        this.operator = operator;
        this.fontFamily = fontFamily;
        this.fontStyle = fontStyle;
        this.fontSize = fontSize;
        this.fontWeight = fontWeight;
        this.color = fontColor;
        this.hasBackground = hasBackground;
        this.backgroundColor = backgroundColor;
        this.rotation = rotation;

        this.relativePosition = position;
        this.render = (props) => SVGText(this, props);
    }

    updateOptions({fontFamily=this.fontFamily, fontStyle=this.fontStyle, fontSize=this.fontSize, fontWeight=this.fontWeight, fontColor=this.color, hasBackground=this.hasBackground, backgroundColor=this.backgroundColor, rotation=this.rotation} = {}) {
        this.fontFamily = fontFamily;
        this.fontStyle = fontStyle;
        this.fontSize = fontSize;
        this.fontWeight = fontWeight;
        this.color = fontColor;
        this.hasBackground = hasBackground;
        this.backgroundColor = backgroundColor;
        this.rotation = rotation;
    }

    setText(newText) {
        this.text = newText;
    }
    
    getRelative(xOffset, yOffset) {
        const [x, y] = this.position;
        this.relativePosition = [x + xOffset, y + yOffset];
        return this;
    }
}

const TextRect = styled.rect`
    transition: width 150ms, height 150ms;
`;
