import React, { useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import theme from '../../../theme';

import { Button } from '../../../elements';
import { ModalContext } from '../../../contexts/modal';

import OperatorButtons from './operator_buttons';
import UtilityButtons from './utility_buttons';
import { OperatorContext } from '../../../contexts/operators';
import { Sentry, Striker, Unknown } from '../../../models/operators';
import { OPERATOR_MAX_SECONDARY_UTILITIES } from '../../../constants';

export default function SelectOperatorModal({trayIndex, updateMain, ...props}) {

    const {hideModal} = useContext(ModalContext);
    const {
        operators, 
        secondaryUtility,
        setOperator,
        setSecondaryUtility
    } = useContext(OperatorContext);
    
    const currentTrayOperator = updateMain ? operators[trayIndex].main : operators[trayIndex].alt;
    const trayOperatorUtilityIndex = updateMain ? secondaryUtility[trayIndex].main : secondaryUtility[trayIndex].alt;
    const strikerSentrySecondGadgetSelected = trayOperatorUtilityIndex instanceof Array;

    const [selectedOperator, setSelectedOperator] = useState({...currentTrayOperator}); // Destructure for guarding if user has lots of Unknown
    const [selectedGadget, setSelectedGadget] = useState(strikerSentrySecondGadgetSelected ? trayOperatorUtilityIndex[0] : trayOperatorUtilityIndex);
    const [strikerSentrySecondSelectedGadget, setStrikerSentrySecondSelectedGadget] = useState(strikerSentrySecondGadgetSelected ? trayOperatorUtilityIndex[1] : 1);
    const [strikerSentryLastSetGadget, setStrikerSentryLastSetGadget] = useState('alternate');

    const [playerName, setPlayerName] = useState(currentTrayOperator.player);
    const [playerColor, setPlayerColor] = useState(currentTrayOperator.color);

    const strikerSentryAwareSetSelectedGadget = useCallback((nextGadgetIndex) => {
        if (selectedOperator.name === Sentry.name || selectedOperator.name === Striker.name) {
            if (strikerSentryLastSetGadget === 'alternate') {
                setStrikerSentrySecondSelectedGadget(nextGadgetIndex);
                setStrikerSentryLastSetGadget('normal');
            } else {
                setSelectedGadget(nextGadgetIndex);
                setStrikerSentryLastSetGadget('alternate');
            }
        } else {
            setSelectedGadget(nextGadgetIndex);
        }
    }, [selectedOperator.name, strikerSentryLastSetGadget, setSelectedGadget, setStrikerSentryLastSetGadget]);

    return (
        <ModalBody {...props}>
            <OperatorButtons
                isMain={updateMain}
                currentTrayOperator={currentTrayOperator}
                selectedOperator={selectedOperator}
                setSelectedOperator={setSelectedOperator}
                setPlayerColor={setPlayerColor}
            />
            <UtilityButtons
                selectedOperator={selectedOperator}
                selectedGadget={selectedGadget}
                strikerSentrySelectedGadget={ selectedOperator.name === Sentry.name || selectedOperator.name === Striker.name ? strikerSentrySecondSelectedGadget : -1 }
                setSelectedGadget={strikerSentryAwareSetSelectedGadget}
            />
            <InputWrapper gridArea="name">
                <ModalLabel htmlFor="operator-player-input">Operator Player</ModalLabel>
                <NameInput
                    id="operator-player-input"
                    type="text"
                    value={playerName}
                    placeholder="Player Name"
                    onChange={event => {
                        setPlayerName(event.target.value);
                    }}
                />
            </InputWrapper>
            <InputWrapper gridArea="color-picker">
                <ModalLabel htmlFor="operator-color-input">Operator Color</ModalLabel>
                <ColorInput
                    id="operator-color-input"
                    type="color"
                    value={playerColor}
                    onChange={event => {
                        setPlayerColor(event.target.value);
                    }}
                />
            </InputWrapper>
            <InputWrapper gridArea="close">
                <Button
                    onClick={() => {
                        // Set the player name (max 15 characters) and the
                        // selected color of the operator.
                        const sanitizedPlayerName = playerName.slice(0, 15);
                        selectedOperator.player = sanitizedPlayerName;
                        selectedOperator.color = playerColor;

                        // If the selected operator is the Unknown operator,
                        // and the operator being updated's secondary utility is
                        // the 0th index utility (ie a special placeholder), then 
                        // the user has unselected an operator.
                        const selectedOperatorUnselected = selectedOperator.name === Unknown.name && (updateMain ? secondaryUtility[trayIndex].main === 0 : secondaryUtility[trayIndex].alt === 0);
                        
                        // Decide whether or not the alternate operator has been 
                        // selected or not.
                        // If it's name is unknown and it's using the 0th utility 
                        // then this operator is unselected as the unknown operator's
                        // 0th utility is a special placeholder that a user cannot select.
                        const altOperator = operators[trayIndex].alt;
                        const altUnselected = altOperator.name === Unknown.name && secondaryUtility[trayIndex].alt === 0;
                        
                        // If the main operator is unselected while the alternate operator
                        // is selected, then we should swap the alternate operator with
                        // the primary one. Otherwise just set (or unset) the operator.
                        if (selectedOperatorUnselected && !altUnselected && updateMain) {
                            // Set the primary operator to have the alternate operators info.
                            // and unset the alternate operator (ie set it to Unknown).
                            setOperator(trayIndex, {...altOperator, player: playerName}, true);
                            setOperator(trayIndex, {...Unknown, player: playerName}, false);

                            // Same for the utility, set the primary as what the alternates was,
                            // and unset the alternate.
                            setSecondaryUtility(trayIndex, secondaryUtility[trayIndex].alt, true);
                            setSecondaryUtility(trayIndex, 0, false);
                        } else {
                            setOperator(trayIndex, selectedOperator, updateMain);

                            // Only the Unknown operator, Striker, and Sentry can have more than
                            // [OPERATOR_MAX_SECONDARY_UTILITIES] secondary utilities.
                            // Thus if we encounter a case where the selected gadget's index
                            // is greater than [OPERATOR_MAX_SECONDARY_UTILITIES] - 1, then
                            // this must be an error, so set it to the 0th utility.
                            if (selectedOperator.name === Unknown.name) {
                                setSecondaryUtility(trayIndex, selectedGadget, updateMain);
                            } else if (selectedOperator.name === Sentry.name || selectedOperator.name === Striker.name) {
                                if (selectedGadget instanceof Array) {
                                    setSecondaryUtility(trayIndex, [selectedGadget[1], strikerSentrySecondSelectedGadget]);
                                } else {
                                    setSecondaryUtility(trayIndex, [selectedGadget, strikerSentrySecondSelectedGadget], updateMain);
                                }
                            } else if (selectedGadget > (OPERATOR_MAX_SECONDARY_UTILITIES - 1)) {
                                setSecondaryUtility(trayIndex, 0, updateMain);
                            } else {
                                setSecondaryUtility(trayIndex, selectedGadget, updateMain);
                            }

                            if (updateMain) setOperator(trayIndex, {...operators[trayIndex].alt, player: sanitizedPlayerName}, false);
                            else setOperator(trayIndex, {...operators[trayIndex].main, player: sanitizedPlayerName}, true);
                        }
                        hideModal();
                    }}
                >
                    Save
                </Button>
            </InputWrapper>
            <InputWrapper gridArea='clear'>
                <Button
                    onClick={() => {
                        setSelectedOperator({...Unknown});
                        setSelectedGadget(0);
                        setStrikerSentrySecondSelectedGadget(0);
                        setStrikerSentryLastSetGadget('normal');
                        setPlayerName('');
                        setPlayerColor('black');
                    }}
                >
                        Reset
                </Button>
            </InputWrapper>
        </ModalBody>
    );
}

const InputWrapper = styled.div`
    grid-area: ${({gridArea}) => gridArea};

    display: grid;
    place-items: center;
`;

const ModalBody = styled.div`
    display: grid;
    grid-template: 
        ". filter filter filter filter filter ." .3fr
        ". tray tray tray tray tray ." 1fr
        ". tray tray tray tray tray ." 1fr
        ". tray tray tray tray tray ." 1fr
        ". tray tray tray tray tray ." 1fr
        ". . . . . . ." 20px
        ". tray2 tray2 tray2 tray2 tray2 ." 1fr
        ". tray2 tray2 tray2 tray2 tray2 ." 1fr
        ". . . . . . ." 10px
        ". name name . color-picker color-picker ." 60px 
        ". . clear . close . ." 60px / 10px 1fr 1fr 1fr 1fr 1fr 10px;

    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ModalLabel = styled.label`
    font-size: ${theme.text.fontSizeSecondary};
    font-family: ${theme.text.fontSecondary};
    color: ${theme.text.colorSecondary};
    text-decoration: ${theme.text.decorationSecondary};

    ${({gridArea}) => gridArea ? `grid-area: ${gridArea}` : ''}
`;

const NameInput = styled.input`
    width: 90%;
`;

const ColorInput = styled.input`
    cursor: pointer;
`;
