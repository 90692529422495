import React, {createContext, useCallback, useMemo, useReducer} from 'react';

import {
    initialState,
    OverlayActions,
    OverlayReducer
} from '../../reducers/overlay';

export const OverlayContext = createContext();

export const OverlayProvider = ({children}) => {
    const [state, dispatch] = useReducer(OverlayReducer, initialState);

    const changeOverlay = useCallback((overlay) => dispatch({type: OverlayActions.changeOverlay, overlay}), [dispatch]);

    const value = useMemo(() => {
        return {
            currentOverlay: state.currentOverlay,
            changeOverlay
        };
    }, [
        state,
        changeOverlay
    ]);

    return (
        <OverlayContext.Provider value={value}>
            {children}
        </OverlayContext.Provider>
    );
};
