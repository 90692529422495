import React, { createContext, useCallback, useMemo, useReducer } from 'react';

import {
    initialState,
    ModalActions,
    ModalReducer
} from '../../reducers/modal';

export const ModalContext = createContext();

export const ModalProvider = ({children}) => {
    const [state, dispatch] = useReducer(ModalReducer, initialState);

    const hideModal = useCallback(
        () => dispatch({type: ModalActions.hideModal}),
        [dispatch]
    );
    const revealModal = useCallback(
        () => dispatch({type: ModalActions.revealModal}),
        [dispatch]
    );
    const setModalContent = useCallback(
        (content, height, width) => dispatch({type: ModalActions.setContent, content, height, width}),
        [dispatch]
    );

    const value = useMemo(() => {
        return {
            ...state,
            hideModal,
            revealModal,
            setModalContent
        };
    }, [
        state, 
        hideModal,
        revealModal,
        setModalContent
    ]);

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    );
};
