import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

export default function ToggleSwitch({option1, option2, onClick=() => {}, defaultOption=option1, ...props}) {
    const [optionOneSelected, setOptionOneSelected] = useState(defaultOption === option1);
    const optionClick = useCallback(() => {
        setOptionOneSelected(!optionOneSelected);
        onClick();
    }, [optionOneSelected, option1, option2, onClick]);

    return (
        <ToggleWrapper onClick={optionClick} {...props}>
            <GreenBackground selected={optionOneSelected}>
                <CheckMark>✔️</CheckMark>
                <OptionText>{option1}</OptionText>
            </GreenBackground>
            <RedBackground selected={!optionOneSelected}>
                <OptionText>{option2}</OptionText>
                <XMark>❌</XMark>
            </RedBackground>
        </ToggleWrapper>
    ); 
}

const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    border-radius: 20px;
    border: 2px solid black;

    position: relative;
    cursor: pointer;

    font-size: 16px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    transition: filter 250ms;

    :hover {
        filter: brightness(1.3);
    }
`;

const GreenBackground = styled.div`
    position: absolute;
    top: 0;
    right: ${({selected}) => selected ? '0' : '100%'};
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    transition: right 250ms;
    background-color: #004000;
`;

const RedBackground = styled.div`
    position: absolute;
    top: 0;
    left: ${({selected}) => selected ? '0' : '100%'};
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-inline: 0px;

    transition: left 250ms;
    background-color: #400000;
`;

const CheckMark = styled.span`
    font-size: 16px;
    margin-inline: 10px;
`;

const XMark = styled.span`
    font-size: 16px;
    margin-inline: 10px;
`;

const OptionText = styled.span`
`;
