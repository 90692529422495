import React from 'react';

import styled from '@emotion/styled';

export default function CursorOptions(props) {
    return (
        <OptionsWrapper {...props}>

        </OptionsWrapper>
    );
}

const OptionsWrapper = styled.div`
    display: grid;
    place-items: center;
`;
