import React, { useContext } from 'react'; 
import styled from '@emotion/styled';

import { ToolOptionsContext } from '../../../../../contexts/tool_options';
import ToggleSwitch from '../../../../../elements/toggle_switch';

export default function EraserOptions(props) {
    const {
        eraseLines,
        eraseHighlights,
        eraseIcons,
        eraseText,
        eraseShapes,
        toggleEraseLines,
        toggleEraseHighlights,
        toggleEraseShapes,
        toggleEraseIcons,
        toggleEraseText
    } = useContext(ToolOptionsContext);

    const eraseLineOptions = [
        'Erase Lines',
        'Keep Lines'
    ];
    const eraseIconOptions = [
        'Erase Icons',
        'Keep Icons'
    ];
    const eraseHighlightOptions = [
        'Erase Highlights',
        'Keep Highlights'
    ];
    const eraseTextOptions = [
        'Erase Text',
        'Keep Text'
    ];
    const eraseShapeOptions = [
        'Erase Shapes',
        'Keep Shapes'
    ];
    return (
        <OptionsWrapper {...props}>
            <StyledToggleSwitch
                option1={eraseLineOptions[0]}
                option2={eraseLineOptions[1]}
                defaultOption={eraseLines ? eraseLineOptions[0] : eraseLineOptions[1]}
                onClick={toggleEraseLines}
            />
            <StyledToggleSwitch
                option1={eraseIconOptions[0]}
                option2={eraseIconOptions[1]}
                defaultOption={eraseIcons ? eraseIconOptions[0] : eraseIconOptions[1]}
                onClick={toggleEraseIcons}
            />
            <StyledToggleSwitch
                option1={eraseHighlightOptions[0]}
                option2={eraseHighlightOptions[1]}
                defaultOption={eraseHighlights ? eraseHighlightOptions[0] : eraseHighlightOptions[1]}
                onClick={toggleEraseHighlights}
            />
            <StyledToggleSwitch
                option1={eraseTextOptions[0]}
                option2={eraseTextOptions[1]}
                defaultOption={eraseText ? eraseTextOptions[0] : eraseTextOptions[1]}
                onClick={toggleEraseText}
            />
            <StyledToggleSwitch
                option1={eraseShapeOptions[0]}
                option2={eraseShapeOptions[1]}
                defaultOption={eraseShapes ? eraseShapeOptions[0] : eraseShapeOptions[1]}
                onClick={toggleEraseShapes}
            />
        </OptionsWrapper>
    );
}

const StyledToggleSwitch = styled(ToggleSwitch)`
    width: 75%;
    height: 35px;

    border-radius: 0px;
`;

const OptionsWrapper = styled.div`
    display: grid;
    place-items: center;

    width: 100%;
    gap: 5px;
`;
