import { Unknown } from '../../models/operators';

export const initialState = {
    operators: [
        {
            main: Unknown,
            alt: Unknown,
        },
        {
            main: Unknown,
            alt: Unknown
        },
        {
            main: Unknown,
            alt: Unknown
        },
        {
            main: Unknown,
            alt: Unknown
        },
        {
            main: Unknown,
            alt: Unknown
        }
    ],
    mainOperatorVisible: [
        true,
        true,
        true,
        true,
        true
    ],
    secondaryUtility: [
        {
            main: 0,
            alt: 0,
        },
        {
            main: 0,
            alt: 0
        },
        {
            main: 0,
            alt: 0
        },
        {
            main: 0,
            alt: 0
        },
        {
            main: 0,
            alt: 0
        }
    ],
    attackBan: {
        main: Unknown,
        alt: Unknown
    },
    defenceBan: {
        main: Unknown,
        alt: Unknown
    }
};

export const OperatorActions = {
    setOperator: 'set_operator',
    setMainOperatorVisibility: 'set_main_operator_visibility',
    setSecondaryUtility: 'set_secondary_utility',
    setAttackBan: 'set_attack_ban',
    setDefenceBan: 'set_defence_ban',
    importOperators: 'import_operators',
    importBans: 'import_bans',
    resetOperators: 'reset_operators',
    resetBans: 'reset_bans'
};

export const OperatorReducer = (state, action) => {
    switch (action.type) {
    case OperatorActions.setOperator: {
        const currentOperators = [...(state.operators)];
        currentOperators[action.index] = {
            ...currentOperators[action.index]
        };

        if (action.updateMain) currentOperators[action.index].main = action.operator;
        else currentOperators[action.index].alt = action.operator;

        return {
            ...state,
            operators: currentOperators,
            secondaryUtility: state.secondaryUtility
        };
    }
    case OperatorActions.setMainOperatorVisibility: {
        const currentVisibilities = [...(state.mainOperatorVisible)];
        currentVisibilities[action.index] = action.visibility;

        return{
            ...state,
            mainOperatorVisible: currentVisibilities
        };
    }
    case OperatorActions.setSecondaryUtility: {
        const currentUtilities = [...(state.secondaryUtility)];

        currentUtilities[action.index] = {
            ...currentUtilities[action.index]
        };

        if (action.updateMain) currentUtilities[action.index].main = action.secondaryUtility;
        else currentUtilities[action.index].alt = action.secondaryUtility;

        return {
            ...state,
            operators: state.operators,
            secondaryUtility: currentUtilities
        };
    }
    case OperatorActions.setAttackBan: {
        const currentBans = {...state.attackBan};

        if (action.updateMain) currentBans.main = action.ban;
        else currentBans.alt = action.ban;

        return {
            ...state,
            attackBan: currentBans
        };
    }
    case OperatorActions.setDefenceBan: {
        const currentBans = {...state.defenceBan};

        if (action.updateMain) currentBans.main = action.ban;
        else currentBans.alt = action.ban;

        return {
            ...state,
            defenceBan: currentBans
        };
    }
    case OperatorActions.importOperators: {
        return {
            ...state,
            operators: action.operators,
            secondaryUtility: action.secondaryUtility
        };
    }
    case OperatorActions.importBans: {
        return {
            ...state,
            attackBan: action.attack,
            defenceBan: action.defence
        };
    }
    case OperatorActions.resetOperators: {
        return {
            ...state,
            operators: [...initialState.operators],
            mainOperatorVisible: [...initialState.mainOperatorVisible],
            secondaryUtility: [...initialState.secondaryUtility]
        };
    }
    case OperatorActions.resetBans: {
        return {
            ...state,
            attackBan: {...initialState.attackBan},
            defenceBan: {...initialState.defenceBan}
        };
    }
    default: {
        console.log(`WARNING: Invalid action ${action.type} provided.`);
        return state;
    }
    }
};
