import React from 'react';
import ContinueRibbon from './continue_ribbon';

export default function ExportOverlay() {
    return (
        <>
            <ContinueRibbon />
        </>
    );
}

