import * as mapAssets from '../../assets/maps';
import icons, {default as iconAssets} from '../../assets/icons';

import {
    EXPORT_VERSION,
    titleStripHeight,
    operatorStripHeight,
    leftMargin,
    rightMargin,
    verticalMargin,
    labelTextWidth,
    iconImageHeight,
    iconImageWidth,
    utilityImageHeight,
    utilityImageWidth,
    playerNameY,
} from '../../constants';
import {getMinimalMap} from './helpers';

import Stenography from '../stenographyService';

// https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
function capitalizeFirstLetter([ first, ...rest ], locale = navigator.language) {
    return first.toLocaleUpperCase(locale) + rest.join('');
}

function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function sortFloors(a, b) {
    const [mapA, floorA] = mapAssets.imageToReadable[a].split(': ');
    const [mapB, floorB] = mapAssets.imageToReadable[b].split(': ');

    if (mapA < mapB) {
        return 1;
    } else if (mapA > mapB) {
        return -1;
    } else {
        if (floorA === floorB) {
            return 0;
        }

        if (floorA.includes('Basement')) {
            return -1;
        } else if (floorB.includes('Basement')) {
            return 1;
        }

        if (floorA.includes('Roof')) {
            return 1;
        } else if (floorB.includes('Roof')) {
            return -1;
        }

        if (floorA < floorB) {
            return -1;
        } else {
            return 1;
        }
    }
}

export async function loadImage(src, width, height) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;

        if (width) {
            img.width = width;
        }
        if (height) {
            img.height = height;
        }

        img.src = src;
    });
}

async function getFloorImageSrc(waitForCropping) {
    return new Promise(resolve => {
        setTimeout( async () => {
            await waitForCropping();
            const backgroundSrc = await getMinimalMap();
            resolve(backgroundSrc);
        }, 250);
    });
}

export async function exportImage(filename, height, width, floorsToDraw, setFloor, operators, {attack: attackBans, defence: defenceBans}, secondaryUtility, waitForCropping, resetCropBounds, lockMapCropping, stratJSON) {
    // Order the floors such that:
    //      Maps are ordered alphabetically,
    //      Floors are ordered vertically (basement 1st -> roof last).
    floorsToDraw = floorsToDraw.sort(sortFloors);
    
    const floorCanvases = [];
    for (const floorToDraw of floorsToDraw) {
        setFloor(floorToDraw);
        const imageSrc = await getFloorImageSrc(waitForCropping);
        lockMapCropping();
        const floorCanvas = document.createElement('canvas');
        floorCanvas.height = height;
        floorCanvas.width = width;
        const floorCanvasContext = floorCanvas.getContext('2d');
        const floorImage = await loadImage(imageSrc);
        floorCanvasContext.drawImage(floorImage, 0, 0);

        floorCanvases.push(floorCanvas);
    }
    resetCropBounds();

    const operatorCanvas = document.createElement('canvas');
    operatorCanvas.width = width;
    operatorCanvas.height = operatorStripHeight;
    const operatorContext = operatorCanvas.getContext('2d');
    operatorContext.fillStyle = 'black';
    operatorContext.fillRect(0, 0, width, operatorStripHeight);
    
    // Create 1 column for each operator, one for 
    // the attack bans, and one for the defence
    // bans.
    const infoColumns = [
        ...operators,
        {
            main: {
                icon: attackBans.main.icon,
                player: 'Attack Bans' 
            },
            alt: {
                icon: attackBans.alt.icon
            },
        },
        {
            main: {
                icon: defenceBans.main.icon,
                player: 'Defence Bans' 
            },
            alt: {
                icon: defenceBans.alt.icon
            },
        }
    ];
    for (const [index, {main: mainOperator, alt: altOperator}] of infoColumns.entries()) {
        const mainOperatorSecondaryUtility = mainOperator.secondaryGadgets && mainOperator.secondaryGadgets[secondaryUtility[index].main];
        const altOperatorSecondaryUtility = altOperator.secondaryGadgets && altOperator.secondaryGadgets[secondaryUtility[index].alt];

        // Setup the positions for everything that is going to be placed on this iteration.
        const operatorBodySize = (width - leftMargin + rightMargin);
        const mainIconX = leftMargin + ((operatorBodySize / infoColumns.length) * index) + labelTextWidth;
        const mainIconY = playerNameY + verticalMargin;
        const altIconX = mainIconX;
        const altIconY = playerNameY + verticalMargin + iconImageHeight;
        const mainTextX = leftMargin + ((operatorBodySize / infoColumns.length) * index);
        const mainTextY = mainIconY + (iconImageHeight / 2);
        const altTextX = mainTextX;
        const altTextY = altIconY + (iconImageHeight / 2);
        const mainUtilityX = mainIconX + iconImageWidth;
        const mainUtilityY = mainIconY + (iconImageHeight / 2) - (utilityImageHeight / 2);
        const altUtilityX = altIconX + iconImageWidth;
        const altUtilityY = altIconY + (iconImageHeight / 2) - (utilityImageHeight / 2);
        const playerNameX = mainIconX + (iconImageWidth / 2);

        const drawAltOperator = (altOperator?.icon ?? icons.UnknownIcon) !== icons.UnknownIcon || (altOperatorSecondaryUtility?.icon ?? icons.UnknownIcon) !== icons.UnknownIcon;

        // Draw the operator icons
        const mainOperatorIcon = await loadImage(mainOperator.icon, iconImageWidth, iconImageHeight);
        const altOperatorIcon = await loadImage(altOperator.icon, iconImageWidth, iconImageHeight);
        operatorContext.drawImage(mainOperatorIcon, mainIconX, mainIconY, iconImageWidth, iconImageHeight);

        if (drawAltOperator) {
            operatorContext.drawImage(altOperatorIcon, altIconX, altIconY, iconImageWidth, iconImageHeight);
        }

        // Draw the main operator's secondary gadget
        if ((mainOperatorSecondaryUtility?.icon ?? icons.UnknownIcon) !== icons.UnknownIcon) {
            const utilityImage = await loadImage(mainOperatorSecondaryUtility.icon, utilityImageWidth, utilityImageHeight);
            operatorContext.drawImage(utilityImage, mainUtilityX, mainUtilityY, utilityImageWidth, utilityImageHeight);
        }

        // Draw the alt operator's secondary gadget
        if (drawAltOperator && (altOperatorSecondaryUtility?.icon ?? icons.UnknownIcon) !== icons.UnknownIcon) {
            const utilityImage = await loadImage(altOperatorSecondaryUtility.icon, utilityImageWidth, utilityImageHeight);
            operatorContext.drawImage(utilityImage, altUtilityX, altUtilityY, utilityImageWidth, utilityImageHeight);
        }

        // Draw the Opererator's name, and the main/alt text
        operatorContext.font = '20px serif';
        operatorContext.fillStyle = 'white';
        const textWidth = operatorContext.measureText(mainOperator.player).width;

        const mainTextMeasure = operatorContext.measureText('Main');
        const altTextMeasure = operatorContext.measureText('Alt');
        const mainTextWidth = mainTextMeasure.width;
        const mainTextHeight = mainTextMeasure.actualBoundingBoxAscent - mainTextMeasure.actualBoundingBoxDescent;
        const altTextHeight = altTextMeasure.actualBoundingBoxAscent - altTextMeasure.actualBoundingBoxDescent;

        operatorContext.fillText(mainOperator.player, playerNameX -(textWidth / 2), playerNameY);
        operatorContext.fillText('Main', mainTextX, mainTextY);
        if (drawAltOperator) operatorContext.fillText('Alt', altTextX, altTextY);

        const mainOperatorColorX = mainTextX;
        const mainOperatorColorY = mainTextY + (mainTextHeight / 2);
        const altOperatorColorX = altTextX;
        const altOperatorColorY = altTextY + (altTextHeight / 2);
        const colorRectHeight = mainTextHeight;
        const colorRectWidth = mainTextWidth;

        // Draw the operator's color rectangles 
        if (mainOperator.player !== 'Attack Bans' && mainOperator.player !== 'Defence Bans') {
            operatorContext.fillStyle = 'white';
            operatorContext.fillRect(mainOperatorColorX - 1, mainOperatorColorY - 1, colorRectWidth + 2, colorRectHeight + 2);
            operatorContext.fillStyle = mainOperator.color;
            operatorContext.fillRect(mainOperatorColorX, mainOperatorColorY, colorRectWidth, colorRectHeight);

            if (drawAltOperator) {
                operatorContext.fillStyle = 'white';
                operatorContext.fillRect(altOperatorColorX - 1, altOperatorColorY - 1, colorRectWidth + 2, colorRectHeight + 2);
                operatorContext.fillStyle = altOperator.color;
                operatorContext.fillRect(altOperatorColorX, altOperatorColorY, colorRectWidth, colorRectHeight);
                
            }
        }
    }

    const titleCanvas = document.createElement('canvas');
    titleCanvas.width = width;
    titleCanvas.height = titleStripHeight;
    const titleContext = titleCanvas.getContext('2d');
    titleContext.fillRect(0, 0, width, titleStripHeight);
    titleContext.font = '50px serif';
    titleContext.fillStyle = 'white';
    const titleText = capitalizeFirstLetter(filename);
    titleContext.fillText(titleText, (width / 2) - (titleContext.measureText(titleText).width / 2), titleStripHeight / 2);

    const exportCanvas = document.createElement('canvas');
    exportCanvas.width = width;
    exportCanvas.height = (height * floorsToDraw.length) + operatorStripHeight + titleStripHeight;
    const exportContext = exportCanvas.getContext('2d');

    exportContext.drawImage(titleCanvas, 0, 0, width, titleStripHeight);
    exportContext.drawImage(operatorCanvas, 0, titleStripHeight, width, operatorStripHeight);
    floorCanvases.forEach((canvas, index) => {
        exportContext.drawImage(canvas, 0, titleStripHeight + operatorStripHeight + (index * height), width, height);
    });

    const dataUrl = exportCanvas.toDataURL('image/png');
    const encodedUrl = await Stenography.encode(stratJSON, dataUrl);
    downloadURI(encodedUrl, `${filename}.png`);
}

export function exportStrat(filename, floorsToDraw, lines, highlights, shapes, icons, text, operators, {attack: attackBans, defence: defenceBans}, secondaryUtility) {
    const floorStringToKey = (floorString) => {
        return Object.keys(mapAssets).find(key => {
            return mapAssets[key] === floorString;
        });
    };
    const iconStringToKey = (iconString) => {
        return Object.keys(iconAssets).find(key => {
            return iconAssets[key] === iconString;
        });
    };

    const exportOperators = operators.map(({main: mainOperator, alt: altOperator}) => {
        return {
            main: {
                name: mainOperator.name,
                player: mainOperator.player,
                color: mainOperator.color
            },
            alt: {
                name: altOperator.name,
                player: altOperator.player,
                color: altOperator.color
            }
        };
    });

    const exportObject = {
        version: EXPORT_VERSION,
        lines: lines
            .filter(({floor: floorString}) => floorsToDraw.includes(floorString))
            .map(({floor: floorString, ...rest}) => JSON.stringify({floor: floorStringToKey(floorString), ...rest})),

        highlights: highlights
            .filter(({floor: floorString}) => floorsToDraw.includes(floorString))
            .map(({floor: floorString, ...rest}) => JSON.stringify({floor: floorStringToKey(floorString), ...rest})),

        shapes: shapes
            .filter(({floor: floorString}) => floorsToDraw.includes(floorString))
            .map(({floor: floorString, ...rest}) => JSON.stringify({floor: floorStringToKey(floorString), ...rest})),

        icons: icons
            .filter(({floor}) => floorsToDraw.includes(floor))
            .map(({icon: iconString, floor: floorString, ...rest}) => JSON.stringify({
                icon: iconStringToKey(iconString), 
                floor: floorStringToKey(floorString), 
                ...rest,
                encodedIcon: undefined
            })),

        text: text
            .filter(({floor}) => floorsToDraw.includes(floor))
            .map(({floor: floorString, ...rest}) => JSON.stringify({floor: floorStringToKey(floorString), ...rest})),

        bans: {
            attack: [attackBans.main.name, attackBans.alt.name],
            defence: [defenceBans.main.name, defenceBans.alt.name]
        },

        operators: exportOperators,
        secondaryUtility: secondaryUtility,
        setFloor: floorsToDraw.length > 0 ? floorStringToKey(floorsToDraw[0]) : undefined,
    };

    const exportJson = JSON.stringify(exportObject);
    const exportFile = 'data:text/plain;charset=utf-8,' + encodeURIComponent(exportJson);

    if (filename) downloadURI(exportFile, `${filename}.strat`);
    return exportJson;
}
