import React, { useMemo, useContext } from 'react';
import {v4} from 'uuid';

import { iconWidth, iconHeight, imageMaskHexOpacity} from '../../constants';
import {getImageBase64} from '../../hooks/useBase64';
import { MapBackgroundContext } from '../../contexts/map_background';
import { ToolOptionsContext } from '../../contexts/tool_options';
import { DrawingContext } from '../../contexts/drawing';
import { OperatorContext } from '../../contexts/operators';

export default class Icon {
    constructor(icon, floor, width, height, rotation, color, position, operator) {
        this.id = v4();
        this.icon = icon;
        this.floor = floor;
        this.width = width;
        this.height = height;
        this.rotation = rotation;
        this.position = position;
        this.color = color;
        this.relativePosition = position;
        this.encodedIcon = icon;
        this.operator = operator;
        this.render = (props) => SVGIcon(this, props);
        
        getImageBase64(icon).then(result => this.encodedIcon = result);
    }

    getRelative(xOffset, yOffset) {
        const [x, y] = this.position;
        this.relativePosition = [x + xOffset, y + yOffset];
        return this;
    }
}

function SVGIcon(icon, {height=iconHeight, width=iconWidth, isEraseable=false, ...props}) {
    const {floor} = useContext(MapBackgroundContext);
    if (floor !== icon.floor && icon.floor !== '') {
        return null;
    }

    const {operators, mainOperatorVisible} = useContext(OperatorContext);
    if (icon.operator !== null && !operators.map(({main, alt}, index) => mainOperatorVisible[index] ? main.name : alt.name).includes(icon.operator)) {
        return null;
    }
    const iconOperator = operators
        .map(({main, alt}) => [main, alt])
        .flat()
        .filter(({name}) => { return name === icon.operator;})?.[0];

    const {tool, eraseIcons} = useContext(ToolOptionsContext);
    const {mouseDown, removeIcon} = useContext(DrawingContext);

    const [left, top] = icon.relativePosition;
    const rotation = `rotate(${icon.rotation})`;
    const scale = `scale(${icon.width}, ${icon.height})`;
    const iconTransform = `${rotation} ${scale}`;
    const transformOrigin = `${left}px ${top}px`;

    const x = Math.round(left - (width / 2));
    const y = Math.round(top - (height / 2));
    const shouldErase = isEraseable && tool === 'Eraser' && eraseIcons;
    const svgIcon = useMemo(() => 
        <image
            href={icon.encodedIcon}
            width={width}
            height={height}
            x={x}
            y={y}

            left={left}
            top={top}

            {...props}
        />,
    [icon, width, height, iconTransform, x, y, left, top, JSON.stringify(props)]
    );

    return (
        <g 
            id={`icon-${icon.id}`} 
            className="icon" 
            transform={iconTransform}
            style={{transformOrigin}}
            onMouseOver={() => shouldErase && mouseDown.left ? removeIcon(icon.id) : {} }
            onMouseDown={(e) => shouldErase && e.button === 0 ? removeIcon(icon.id) : {} }
        >
            {svgIcon}
            { (iconOperator || icon.color) && 
                <>
                    <mask id={`${icon.id}-mask`}>
                        {svgIcon}
                    </mask>
                    <rect 
                        width={width}
                        height={height}
                        x={x}
                        y={y}

                        left={left}
                        top={top}

                        fill={`${icon.operator ? iconOperator.color : icon.color}${imageMaskHexOpacity}`}
                        href={icon.encodedIcon}
                        mask={`url(#${icon.id}-mask)`}
                        
                        {...props}
                    />
                </>
            }
        </g>
    );
}
