import React from 'react';
import styled from '@emotion/styled';
import theme from '../../../../theme';

export default function OperatorTile({operator, openToolWindow, ...props}) {
    return (
        <Wrapper {...props} onClick={() => openToolWindow()} >
            <TileImage 
                src={operator.icon}
                onDragStart={e => e.preventDefault()}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: grid;
    place-items: center;
    height: 50%;

    border: 2px solid #aaa;
    padding: 10px;
    background-color: ${theme.colors.UI100};

    :hover {
        filter: brightness(.8);
        cursor: pointer;
    }
`;

const TileImage = styled.img`
    width: 60px;
    height: 60px;
`;
