import React, {useContext} from 'react';
import styled from '@emotion/styled';

import { ColoredStar } from '../../../../assets';
import { ToolOptionsContext } from '../../../../contexts/tool_options';
import useBase64 from '../../../../hooks/useBase64';

export default function FavoriteableIconButton({src, alt, favorited, onFavorite, onUnfavorite, ...props}) {
    const {setIcon, setTool} = useContext(ToolOptionsContext);
    const [encodedSrcLoading, encodedSrc] = useBase64(src);

    const imageSrc = encodedSrcLoading ? encodedSrc : src;

    return (
        <IconWrapper {...props}>
            <Icon
                src={imageSrc}
                alt={alt}
                onClick={() => {
                    setTool('Icon');
                    setIcon(src);
                }}
            />
            <Star 
                favorited={favorited}
                onClick={() => {
                    // Need to do the opposite as clicking
                    // this button means inverting the state.
                    if (favorited && onUnfavorite) onUnfavorite();
                    else if (!favorited && onFavorite) onFavorite();
                }}
            />
        </IconWrapper>
    );
}

const IconWrapper = styled.div`
    position: relative;
    height: 60px;
    width: 60px;
`;

const Star = styled(ColoredStar)`
    position: absolute;
    right: -5px;
    top: -2px;

    width: 20px;
    height: 20px;

    fill: ${({favorited}) => favorited ? 'yellow' : 'rgba(0, 0, 0, 0)'};
    stroke: ${({favorited}) => favorited ? 'yellow' : 'gray'};
    stroke-width: 14;

    cursor: pointer;

    :hover {
        stroke: yellow;
    }
`;

const Icon = styled.img`
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;

    transform: translate(-50%, -50%);

    height: 50px;
    width: 50px;

    cursor: pointer;
`;
