import React, {createContext, useCallback, useMemo, useReducer} from 'react';

import {
    initialState,
    MapBackgroundActions,
    MapBackgroundReducer
} from '../../reducers/map_background';

export const MapBackgroundContext = createContext();

export const MapBackgroundProvider = ({children}) => {
    const [state, dispatch] = useReducer(MapBackgroundReducer, initialState);

    const lockViewport = useCallback(() => dispatch({type: MapBackgroundActions.lockViewport}), []);
    const unlockViewport = useCallback(() => dispatch({type: MapBackgroundActions.unlockViewport}), []);
    const setMap = useCallback((map) => dispatch({type: MapBackgroundActions.setMap, map}), []);
    const setFloor = useCallback((floor) => dispatch({type: MapBackgroundActions.setFloor, floor}), []);
    const changeHoverPosition = useCallback((x, y) => dispatch({type: MapBackgroundActions.changeHoverPosition, x, y}), []);
    const resizeMap = useCallback((height, width) => dispatch({type: MapBackgroundActions.resizeMap, height, width}), []);
    const setMapScale = useCallback((scaleUpdate) => dispatch({type: MapBackgroundActions.zoomMap, scaleUpdate}), []);
    const resetMapScale = useCallback(() => dispatch({type: MapBackgroundActions.resetMapZoom}), []);
    const panMap = useCallback((dx, dy) => dispatch({type: MapBackgroundActions.panMap, dx, dy}), []);
    const resetMapPan = useCallback(() => dispatch({type: MapBackgroundActions.resetMapPan}), []);
    const finishInitialLoad = useCallback(() => dispatch({type: MapBackgroundActions.finishInitialLoad}), []);
    const startMapCropping = useCallback((x, y) => dispatch({type: MapBackgroundActions.startMapCropping, x, y}), []);
    const extendMapCropping = useCallback((dx, dy) => dispatch({type: MapBackgroundActions.extendMapCropping, dx, dy}), []);
    const lockMapCropping = useCallback(() => dispatch({type: MapBackgroundActions.lockMapCropping}), []);
    const unlockMapCropping = useCallback(() => dispatch({type: MapBackgroundActions.unlockMapCropping}), []);

    const value = useMemo(() => {
        return {
            mapLocked: state.mapLocked,
            floor: state.floor,
            hoverX: state.hoverX,
            hoverY: state.hoverY,
            mapHeight: state.mapHeight,
            mapWidth: state.mapWidth,
            mapScale: state.mapScale,
            panX: state.panX,
            panY: state.panY,
            initialLoad: state.initialLoad,
            mapCropping: state.mapCropping,
            mapCroppingLocked: state.mapCroppingLocked,
            lockViewport,
            unlockViewport,
            setMap,
            setFloor,
            changeHoverPosition,
            resizeMap,
            setMapScale,
            resetMapScale,
            panMap,
            resetMapPan,
            finishInitialLoad,
            startMapCropping,
            extendMapCropping,
            lockMapCropping,
            unlockMapCropping
        };
    }, [
        state,
        lockViewport,
        unlockViewport,
        setMap,
        setFloor,
        changeHoverPosition,
        resizeMap,
        setMapScale,
        resetMapScale,
        panMap,
        resetMapPan,
        finishInitialLoad,
        startMapCropping,
        extendMapCropping,
        lockMapCropping,
        unlockMapCropping
    ]);

    return (
        <MapBackgroundContext.Provider value={value}>
            {children}
        </MapBackgroundContext.Provider>
    );
};
