import React, {useContext, useState} from 'react';
import styled from '@emotion/styled';
import theme from '../../../../theme';

import {SelectOperatorModal} from '../../../modals';
import { ModalContext } from '../../../../contexts/modal';
import { OperatorContext } from '../../../../contexts/operators';
import { AttackerIcon, DefenderIcon } from '../../../../assets/icons';
import { Unknown } from '../../../../models/operators';
import {VisibleIcon, InvisibleIcon} from '../../../../assets';
import OperatorBanModal from '../../../modals/operator_ban_modal';

export function BanSelectionTile({isAttacker, ...props}) {
    const [hoveringOverIcon, setHoveringOverIcon] = useState(false);
    const {attackBan, defenceBan} = useContext(OperatorContext);
    const {revealModal, setModalContent} = useContext(ModalContext);

    const mainTileBan = isAttacker ? attackBan.main : defenceBan.main;
    const altTileBan = isAttacker ? attackBan.alt : defenceBan.alt; 
    const defaultIcon = isAttacker ? AttackerIcon : DefenderIcon;
    const bottomHalfImage = 
        mainTileBan.name === Unknown.name ? defaultIcon :
            altTileBan.name === Unknown.name ? mainTileBan.icon :
                altTileBan.icon;

    return (
        <SelectBanWrapper {...props}>
            <IconButtonsWrapper>
                <BanIconWrapper 
                    onMouseEnter={() => setHoveringOverIcon(true)}
                    onMouseLeave={() => setHoveringOverIcon(false)}
                >
                    <TopHalfOperatorIcon
                        src={(mainTileBan.name === Unknown.name) ? defaultIcon : mainTileBan.icon}
                        hovering={hoveringOverIcon}
                    />
                    <BottomHalfOperatorIcon
                        src={bottomHalfImage}
                        hovering={hoveringOverIcon}
                        drawSeperator={mainTileBan.name !== Unknown.name && altTileBan.name !== Unknown.name}
                    />
                    <ExpandingSelectOperator
                        hovering={hoveringOverIcon}
                        mainSelected={mainTileBan.name !== Unknown.name}
                        onClick={() => {
                            setModalContent(<OperatorBanModal isAttacker={isAttacker} updateMain={true}/>, 700, 400);
                            revealModal();
                        }}
                    >
                        Select Ban
                    </ExpandingSelectOperator>
                    { mainTileBan.name !== Unknown.name &&
                        <BottomSelectOperator
                            hovering={hoveringOverIcon}
                            onClick={() => {
                                setModalContent(<OperatorBanModal isAttacker={isAttacker} updateMain={false}/>, 700, 400);
                                revealModal();
                            }}
                        >
                            Backup Ban
                        </BottomSelectOperator>
                    }
                </BanIconWrapper>
            </IconButtonsWrapper>
        </SelectBanWrapper>
    );
}

export default function SelectionTile({operator, altOperator, index, ...props}) {
    const [hoveringOverIcon, setHoveringOverIcon] = useState(false);
    const {setMainOperatorVisibility, secondaryUtility} = useContext(OperatorContext);
    const {revealModal, setModalContent} = useContext(ModalContext);
    const [mainOperator, setMainOperator] = useState(true);

    const mainUnselected = operator.name === Unknown.name && secondaryUtility[index].main === 0;
    const altUnselected = altOperator.name === Unknown.name && secondaryUtility[index].alt === 0;

    const tileOperator = mainOperator ? operator : altOperator;
    const bottomHalfImage = altUnselected ? operator.icon : altOperator.icon;

    return (
        <SelectOperatorWrapper {...props}>
            <IconButtonsWrapper>
                <IconWrapper 
                    onMouseEnter={() => setHoveringOverIcon(true)}
                    onMouseLeave={() => setHoveringOverIcon(false)}
                >
                    <TopHalfOperatorIcon
                        src={operator.icon}
                        hovering={hoveringOverIcon}
                    />
                    <BottomHalfOperatorIcon
                        src={bottomHalfImage}
                        hovering={hoveringOverIcon}
                        drawSeperator={!altUnselected}
                    />
                    <ExpandingSelectOperator
                        hovering={hoveringOverIcon}
                        mainSelected={!mainUnselected}
                        onClick={() => {
                            setModalContent(<SelectOperatorModal updateMain={true} trayIndex={index}/>, 700, 400);
                            revealModal();
                        }}    
                    >
                        {mainUnselected ? 'Select Operator' : 'Change Operator'}
                    </ExpandingSelectOperator>
                    {!mainUnselected &&
                        <BottomSelectOperator
                            hovering={hoveringOverIcon}
                            onClick={() => {
                                setModalContent(<SelectOperatorModal updateMain={false} trayIndex={index}/>, 700, 400);
                                revealModal();
                            }}
                        >
                            Backup Operator
                        </BottomSelectOperator>
                    }
                </IconWrapper>
                <ButtonsWrapper>
                    { !mainUnselected && (
                        mainOperator ? 
                            <StyledVisibleIcon
                                onClick={() => {
                                    setMainOperator(false);
                                    setMainOperatorVisibility(index, false);
                                }}
                            />
                            :
                            <StyledInvisibleIcon
                                onClick={()=> {
                                    setMainOperator(true);
                                    setMainOperatorVisibility(index, true);
                                }}
                            />
                    )}
                    { !altUnselected && (
                        mainOperator ? 
                            <StyledInvisibleIcon 
                                onClick={() => {
                                    setMainOperator(false);
                                    setMainOperatorVisibility(index, false);
                                }}
                            />
                            :
                            <StyledVisibleIcon
                                onClick={()=> {
                                    setMainOperator(true);
                                    setMainOperatorVisibility(index, true);
                                }}
                            />
                    )}
                </ButtonsWrapper>
            </IconButtonsWrapper>
            <PlayerName>{tileOperator.player ? tileOperator.player : 'No Name'}</PlayerName>
        </SelectOperatorWrapper>
    );
}

const StyledVisibleIcon = styled(VisibleIcon)`
    height: 30px;
    width: 30px;
    padding: 5px 0px;

    cursor: pointer;
`;

const StyledInvisibleIcon = styled(InvisibleIcon)`
    height: 30px;
    width: 30px;
    padding: 5px 0px;

    cursor: pointer;
`;

const SelectOperatorWrapper = styled.div`
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid rgba(0, 0, 0, .25);

    :last-of-type {
        border-bottom: unset;
    }
`;

const ButtonsWrapper = styled.div`
    height: 80px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SelectBanWrapper = styled(SelectOperatorWrapper)`
    border-bottom: unset;
`;

const IconButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-evenly;
`;

const PlayerName = styled.p`
    margin: 0;
    place-self: center;

    font-family: ${theme.text.fontSecondary};
    font-size: ${theme.text.fontSizeSecondary};
    color: ${theme.text.colorSecondary};
    text-decoration: ${theme.text.decorationSecondary};
`;

const IconWrapper = styled.div`
    position: relative;
    height: 80px;
    width: 80px;
    cursor: pointer;
`;

const BanIconWrapper = styled(IconWrapper)`
    height: 65px;
    width: 65px;
`;

const TopHalfOperatorIcon = styled.div`
    position: absolute;
    top: 0;
    bottom: calc(50%);
    left: 0;
    right: 0;

    background-image: url(${({src}) => src});
    background-size: cover;

    transition: filter 250ms;
    ${({hovering}) => hovering ? `
        filter: blur(3px) brightness(.5);
    ` : 'unset'
}
`;

const BottomHalfOperatorIcon = styled.div`
    position: absolute;
    bottom: 0;
    top: calc(50%);
    left: 0;
    right: 0;

    ${({drawSeperator}) => drawSeperator ? 'border-top: 2px solid black' : ''};

    background-image: url(${({src}) => src});
    background-size: cover;
    background-position-y: 100%;

    transition: filter 250ms;
    ${({hovering}) => hovering ? `
        filter: blur(3px) brightness(.5);
    ` : 'unset'
}`;

const ExpandingSelectOperator = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: ${({mainSelected}) => mainSelected ? 'calc(50% - 3px)' : '0'};

    color: white;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
    font-size: 11px;

    border: 2px dotted black;

    display: grid;
    place-items: center;
    text-align: center;

    opacity: ${({hovering}) => hovering ? 1 : 0};
    transition: opacity 250ms;

    :hover {
        border-style: solid;
    }
`;

const BottomSelectOperator = styled(ExpandingSelectOperator)`
    top: calc(50% + 1px);
    bottom: 0;
`;
