export const initialState = {
    open: false,
    content: null,
    x: 0,
    y: 0
};

export const ContextMenuActions = {
    openContextMenu: 'open_context_menu',
    closeContextMenu: 'close_context_menu',
    setContent: 'set_content',
};

export const ContextMenuReducer = (state, action) => {
    switch (action.type) {
    case ContextMenuActions.closeContextMenu: {
        return {
            ...state,
            open: false
        };
    }
    case ContextMenuActions.openContextMenu: {
        return {
            ...state,
            open: true,
            x: action.x,
            y: action.y
        };
    }
    case ContextMenuActions.setContent: {
        return {
            ...state,
            content: action.content
        };
    }
    default:
        console.log(`WARNING: Invalid action ${action.type} provided.`);
        return state;
    }
};
