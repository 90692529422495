export const initialState = {
    tool: 'Cursor',
    icon: '',
    toolOperator: null,
    iconHeightFactor: 1,
    iconWidthFactor: 1,
    iconRotation: 0,
    iconColor: null,
    pencilColor: '#000000',
    pencilWidth: 3,
    pencilLineStart: 'placeholder',
    pencilEndCap: 'placeholder',
    pencilLineStyle: 'placeholder',
    pencilLineIsFreehand: true,
    highlighterColor: '#000000',
    shapeType: 'placeholder',
    shapeColor: '#000000',
    shapeThickness: 3,
    shapeIsFilled: false,
    eraseLines: true,
    eraseHighlights: true,
    eraseShapes: true,
    eraseIcons: true,
    eraseText: true,
    textFontFamily: 'Arial, Helvetica, sans-serif',
    textFontStyle: 'normal',
    textFontSize: 16,
    textFontWeight: 'normal',
    textFontColor: '#000000',
    textHasBackground: false,
    textBackgroundColor: '#ffffff',
    textRotation: 0
};

export const ToolOptionsActions = {
    setTool: 'set_tool',
    setIcon: 'set_icon',
    setPencilColor: 'set_pencil_color',
    setPencilWidth: 'set_pencil_width',
    setPencilLineStart: 'set_pencil_line_start',
    setPencilEndCap: 'set_pencil_end_cap',
    setPencilLineStyle: 'set_pencil_line_style',
    setPencilLineIsFreehand: 'set_pencil_line_is_freehand',
    setHighlighterColor: 'set_highlighter_color',
    setShapeType: 'set_shape_type',
    setShapeColor: 'set_shape_color',
    setShapeThickness: 'set_shape_thickness',
    setShapeIsFilled: 'set_shape_is_filled',
    toggleEraseLines: 'toggle_erase_lines',
    toggleEraseHighlights: 'toggle_erase_highlights',
    toggleEraseShapes: 'toggle_erase_shapes',
    toggleEraseIcons: 'toggle_erase_icons',
    toggleEraseText: 'toggle_erase_text',
    setIconColor: 'set_icon_color',
    setIconWidthFactor: 'set_icon_width_factor',
    setIconHeightFactor: 'set_icon_height_factor',
    rotateIconCW: 'rotate_icon_clockwise',
    rotateIconCCW: 'rotate_icon_counterclockwise',
    setTextFontFamily: 'set_text_font_family',
    setTextFontStyle: 'set_text_font_style',
    setTextFontSize: 'set_text_font_size',
    setTextFontWeight: 'set_text_font_weight',
    setTextFontColor: 'set_text_font_color',
    setTextHasBackground: 'set_text_has_background',
    setTextBackgroundColor: 'set_text_background_color',
    setTextRotation: 'set_text_rotation'
};

export const ToolOptionsReducer = (state, action) => {
    switch (action.type) {
    case ToolOptionsActions.setTool: {
        return {
            ...state,
            tool: action.tool,
            iconColor: action.tool === 'Cursor' ? null : state.iconColor,
            toolOperator: action.toolOperator ?? null
        };
    }
    case ToolOptionsActions.setIcon: {
        return {
            ...state,
            icon: action.icon
        };
    }
    case ToolOptionsActions.setPencilColor: {
        return {
            ...state,
            pencilColor: action.color
        };
    }
    case ToolOptionsActions.setPencilWidth: {
        return {
            ...state,
            pencilWidth: action.width
        };
    }
    case ToolOptionsActions.setPencilLineStart: {
        return {
            ...state,
            pencilLineStart: action.lineStart
        };
    }
    case ToolOptionsActions.setPencilEndCap: {
        return {
            ...state,
            pencilEndCap: action.endCap
        };
    }
    case ToolOptionsActions.setPencilLineStyle: {
        return {
            ...state,
            pencilLineStyle: action.lineStyle
        };
    }
    case ToolOptionsActions.setPencilLineIsFreehand: {
        return {
            ...state,
            pencilLineIsFreehand: action.isFreehand
        };
    }
    case ToolOptionsActions.setHighlighterColor: {
        return {
            ...state,
            highlighterColor: action.color
        };
    }
    case ToolOptionsActions.setShapeType: {
        return {
            ...state,
            shapeType: action.shapeType
        };
    }
    case ToolOptionsActions.setShapeColor: {
        return {
            ...state,
            shapeColor: action.color
        };
    }
    case ToolOptionsActions.setShapeThickness: {
        return {
            ...state,
            shapeThickness: action.shapeThickness
        };
    }
    case ToolOptionsActions.setShapeIsFilled: {
        return {
            ...state,
            shapeIsFilled: action.shapeIsFilled
        };
    }
    case ToolOptionsActions.toggleEraseLines: {
        return {
            ...state,
            eraseLines: !state.eraseLines
        };
    }
    case ToolOptionsActions.toggleEraseHighlights: {
        return {
            ...state,
            eraseHighlights: !state.eraseHighlights
        };
    }
    case ToolOptionsActions.toggleEraseShapes: {
        return {
            ...state,
            eraseShapes: !state.eraseShapes
        };
    }
    case ToolOptionsActions.toggleEraseIcons: {
        return {
            ...state,
            eraseIcons: !state.eraseIcons
        };
    }
    case ToolOptionsActions.toggleEraseText: {
        return {
            ...state,
            eraseText: !state.eraseText
        };
    }
    case ToolOptionsActions.setIconColor: {
        return {
            ...state,
            iconColor: action.iconColor
        };
    }
    case ToolOptionsActions.setIconWidthFactor: {
        return {
            ...state,
            iconWidthFactor: action.widthFactor
        };
    }
    case ToolOptionsActions.setIconHeightFactor: {
        return {
            ...state,
            iconHeightFactor: action.heightFactor
        };
    }
    case ToolOptionsActions.rotateIconCW: {
        return {
            ...state,
            iconRotation: (state.iconRotation + action.rotation) % 360
        };
    }
    case ToolOptionsActions.rotateIconCCW: {
        return {
            ...state,
            iconRotation: (state.iconRotation - action.rotation) % 360
        };
    }
    case ToolOptionsActions.setTextFontFamily: {
        return {
            ...state,
            textFontFamily: action.fontFamily
        };
    }
    case ToolOptionsActions.setTextFontStyle: {
        return {
            ...state,
            textFontStyle: action.fontStyle
        };
    }
    case ToolOptionsActions.setTextFontSize: {
        return {
            ...state,
            textFontSize: action.fontSize
        };
    }
    case ToolOptionsActions.setTextFontWeight: {
        return {
            ...state,
            textFontWeight: action.fontWeight
        };
    }
    case ToolOptionsActions.setTextFontColor: {
        return {
            ...state,
            textFontColor: action.fontColor
        };
    }
    case ToolOptionsActions.setTextHasBackground: {
        return {
            ...state,
            textHasBackground: action.hasBackground
        };
    }
    case ToolOptionsActions.setTextBackgroundColor: {
        return {
            ...state,
            textBackgroundColor: action.backgroundColor
        };
    }
    case ToolOptionsActions.setTextRotation: {
        return {
            ...state,
            textRotation: action.rotation % 360
        };
    }
    default: 
        console.log(`WARNING: Invalid action ${action.type} provided.`);
        return state;
    }
};
