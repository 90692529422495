import React from 'react';

import { Unknown as UnknownOperator } from '../../../../models/operators';
import { Unknown as UnknownUtility } from '../../../../models/utility';
import { IconButton, IconTray } from '../elements';


export default function UtilityButtons({selectedOperator, selectedGadget, setSelectedGadget, strikerSentrySelectedGadget, ...props}) {
    const utilityIcons = selectedOperator.secondaryGadgets
        .map( (gadget, index) => {
            if (gadget.name === UnknownUtility.name) return null;
            

            const isSelectedGadget = selectedGadget === index || index === strikerSentrySelectedGadget;
            const onClick = isSelectedGadget ? () => {} : () => {
                setSelectedGadget(index);
            };

            return (
                <IconButton
                    key={index}
                    selected={isSelectedGadget}
                    src={gadget.icon}
                    alt="Utility Button"
                    onClick={onClick}
                />
            );
        });

    return (
        <IconTray gridArea="tray2" columns={selectedOperator.name === UnknownOperator.name ? 4 : 2} {...props}>
            { utilityIcons }
        </IconTray>
    );
}
