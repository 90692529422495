import {Highlight, Icon, Line, Rect, Text} from '../../models/drawables';
import Circle from '../../models/drawables/circle';

export const initialState = {
    mouseDown: {
        left: false,
        middle: false,
        right: false
    },
    selectedText: '',
    lines: [],
    icons: [],
    highlights: [],
    text: [],
    shapes: [],
    cursorPosition: -1,
};

export const DrawingActions = {
    setMouseDown: 'mouse_down',
    setMouseUp: 'mouse_up',
    startLine: 'draw_line',
    extendLine: 'extend_line',
    removeLine: 'remove_line',
    clearLines: 'clear_lines',
    startHighlight: 'draw_highlight',
    extendHighlight: 'extend_highlight',
    removeHighlight: 'remove_highlight',
    clearHighlights: 'clear_highlights',
    drawIcon: 'draw_icon',
    removeIcon: 'remove_icon',
    clearIcons: 'clear_icons',
    drawText: 'draw_text',
    setSelectedText: 'set_selected_text',
    moveCursor: 'set_cursor_position',
    updateText: 'update_text',
    updateTextOptions: 'update_text_options',
    removeText: 'remove_text',
    clearText: 'clear_text',
    startShape: 'start_shape',
    resizeShape: 'resize_shape',
    removeShape: 'remove_shape',
    clearShapes: 'clear_shapes',
    import: 'import_drawing'
};

export const DrawingReducer = (state, action) => {
    switch (action.type) {
    case DrawingActions.setMouseDown: {
        return {
            ...state,
            mouseDown: {
                ...(state.mouseDown),
                [action.button]: true
            }
        };
    }
    case DrawingActions.setMouseUp: {
        return {
            ...state,
            mouseDown: {
                ...(state.mouseDown),
                [action.button]: false
            },
        };
    }
    case DrawingActions.startLine: {
        return {
            ...state,
            lines: [
                ...(state.lines), new Line(
                    action.floor,
                    action.width,
                    action.color,
                    [],
                    1,
                    action.operator,
                    action.lineStart,
                    action.endCap,
                    action.lineStyle,
                    action.isFreehand
                )
            ]
        };
    }
    case DrawingActions.extendLine: {
        state.lines.at(-1).extend(action.x, action.y);
            
        return {...state};
    }
    case DrawingActions.removeLine: {
        return {
            ...state,
            lines: state.lines.filter(
                ({id}) => id !== action.id
            )
        };
    }
    case DrawingActions.clearLines: {
        return {
            ...state,
            lines: state.lines.filter(
                ({floor}) => !action.floors.includes(floor)
            )
        };
    }
    case DrawingActions.startHighlight: {
        return {
            ...state,
            highlights: [
                ...(state.highlights), new Highlight(
                    action.floor,
                    action.color,
                    [],
                    action.operator
                )
            ]
        };
    }
    case DrawingActions.extendHighlight: {
        state.highlights.at(-1).extend(action.x, action.y);
            
        return {...state};
    }
    case DrawingActions.removeHighlight: {
        return {
            ...state,
            highlights: state.highlights.filter(
                ({id}) => id !== action.id
            )
        };
    }
    case DrawingActions.clearHighlights: {
        return {
            ...state,
            highlights: state.highlights.filter(
                ({floor}) => !action.floors.includes(floor)
            )
        };
    }
    case DrawingActions.drawIcon: {
        return {
            ...state,
            icons: [
                ...(state.icons),
                new Icon(
                    action.icon,
                    action.floor,
                    action.width,
                    action.height,
                    action.rotation,
                    action.color,
                    [action.x, action.y],
                    action.operator
                )
            ]
        };
    }
    case DrawingActions.removeIcon: {
        return {
            ...state,
            icons: state.icons.filter(
                ({id: iconId}) => iconId !== action.id
            )
        };
    }
    case DrawingActions.clearIcons: {
        return {
            ...state,
            icons: state.icons.filter(
                ({floor}) => !action.floors.includes(floor)
            )
        };
    }
    case DrawingActions.drawText: {
        const newTextObject = new Text(action.floor, action.text, [action.x, action.y], action.operator, action.options);

        return {
            ...state,
            text: [
                ...(state.text),
                newTextObject
            ],
            selectedText: newTextObject.id
        };
    }
    case DrawingActions.setSelectedText: {
        return {
            ...state,
            selectedText: action.id,
            cursorPosition: -1
        };
    }
    case DrawingActions.updateText: {
        const textToUpdate = state.text.filter(({id}) => id === state.selectedText);
        if (textToUpdate.length !== 1) {
            return {...state};
        }
        const updatedText = textToUpdate[0];
        updatedText.setText(action.text);

        return {
            ...state,
            text: [
                ...(state.text).filter(({id}) => id !== state.selectedText),
                updatedText
            ]
        };
    }
    case DrawingActions.updateTextOptions: {
        const textToUpdate = state.text.filter(({id}) => id === state.selectedText);
        if (textToUpdate.length !== 1) {
            return {...state};
        }
        const updatedText = textToUpdate[0];
        updatedText.updateOptions(action.options);

        return {
            ...state,
            text: [
                ...(state.text).filter(({id}) => id !== state.selectedText),
                updatedText
            ]
        };
    }
    case DrawingActions.moveCursor: {
        return {
            ...state,
            cursorPosition: action.position
        };
    } 
    case DrawingActions.removeText: {
        return {
            ...state,
            text: state.text.filter(
                ({id: textId}) => textId !== action.id
            )
        };
    }
    case DrawingActions.clearText: {
        return {
            ...state,
            text: state.text.filter(
                ({floor}) => !action.floors.includes(floor)
            )
        };
    } 
    case DrawingActions.startShape: {
        let newShape;
        switch (action.shapeType) {
        case 'placeholder':
        case 'rect': {
            newShape = new Rect(action.floor, action.position, [0, 0], action.operator, action.color, action.thickness, action.filled);
            break;
        }
        case 'circle': {
            newShape = new Circle(action.floor, action.position, [0, 0], action.operator, action.color, action.thickness, action.filled);
            break;
        }
        default: {
            console.log(`WARNING: Invalid shape ${action.shapeType} provided.`);
            return state;
        }}

        return {
            ...state,
            shapes: [...(state.shapes), newShape]
        };
    }
    case DrawingActions.resizeShape: {
        state.shapes.at(-1).resize(action.dx, action.dy);
        return {...state};
    }
    case DrawingActions.removeShape: {
        return {
            ...state,
            shapes: state.shapes.filter(({id}) => id !== action.id)
        };
    }
    case DrawingActions.clearShapes: {
        return {
            ...state,
            shapes: state.shapes.filter(
                ({floor}) => !action.floors.includes(floor)
            )
        };
    }
    case DrawingActions.import: {
        return {
            ...state,
            lines: action.lines || [],
            highlights: action.highlights || [],
            icons: action.icons || [],
            text: action.text || [],
            shapes: action.shapes || []
        };
    }
    default: {
        console.log(`WARNING: Invalid action ${action.type} provided.`);
        return state;
    }}
};
