import React, { useContext, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import theme from '../../theme';

import { MapBackgroundContext } from '../../contexts/map_background';
import useWindowSize from '../../hooks/useWindowSize';
import { ToolOptionsContext } from '../../contexts/tool_options';
import { DrawingContext } from '../../contexts/drawing';

export default function BoundingBox({children, ...props}) {

    const {
        floor,
        hoverX,
        hoverY,
        mapScale,
        resizeMap,
        startMapCropping
    } = useContext(MapBackgroundContext);

    const {
        tool,
        icon,
        toolOperator,
        iconColor,
        iconWidthFactor,
        iconHeightFactor,
        iconRotation,
        pencilColor,
        pencilWidth,
        pencilLineStart,
        pencilEndCap,
        pencilLineStyle,
        pencilLineIsFreehand,
        highlighterColor,
        shapeType,
        shapeColor,
        shapeThickness,
        shapeIsFilled,
        textFontFamily,
        textFontStyle,
        textFontSize,
        textFontWeight,
        textFontColor,
        textHasBackground,
        textBackgroundColor,
        textRotation
    } = useContext(ToolOptionsContext);

    const {
        startLine,
        startHighlight,
        startShape,
        drawIcon,
        drawText,
        setMouseDown,
        setMouseUp,
    } = useContext(DrawingContext);

    const onMouseUp = event => {
        if (event.button === 0) {
            setMouseUp('left');
        }
        else if (event.button === 1) {
            setMouseUp('middle');
        } else if (event.button === 2) {
            setMouseUp('right');
        }
    };

    const onMouseDown = event => {
        event.preventDefault();
        if (event.button === 0) {
            if (tool === 'Pencil') {
                startLine(floor, pencilColor, pencilWidth, toolOperator, pencilLineStart, pencilEndCap, pencilLineStyle, pencilLineIsFreehand);
            } else if (tool === 'Highlighter') {
                startHighlight(floor, highlighterColor, toolOperator);
            } else if (tool === 'Shape') {
                startShape(
                    shapeType,
                    floor,
                    [hoverX, hoverY],
                    toolOperator,
                    shapeColor,
                    shapeThickness,
                    shapeIsFilled,
                );
            } else if (tool === 'Icon') {
                drawIcon(
                    icon,
                    floor,
                    iconWidthFactor,
                    iconHeightFactor,
                    iconRotation,
                    iconColor,
                    hoverX,
                    hoverY,
                    toolOperator
                );
            } else if (tool === 'Text') {
                drawText(floor, '', toolOperator, hoverX, hoverY, {
                    fontFamily: textFontFamily,
                    fontStyle: textFontStyle,
                    fontSize: textFontSize,
                    fontWeight: textFontWeight,
                    fontColor: textFontColor,
                    hasBackground: textHasBackground,
                    backgroundColor: textBackgroundColor,
                    rotation: textRotation
                });
            } else if (tool === 'Cropper') {
                const minimalMap = document.getElementById('minimal-map');
                const {x: mapX, y: mapY} = minimalMap.getBoundingClientRect();
                startMapCropping((event.clientX - Math.round(mapX)) / mapScale, (event.clientY - Math.round(mapY)) / mapScale);
            }
    
            setMouseDown('left');
        } else if (event.button === 1) {
            setMouseDown('middle');
        } else if (event.button === 2) {
            setMouseDown('right');
        }
    };

    const [windowWidth, windowHeight] = useWindowSize();
    useLayoutEffect(() => {

        // In case we want to change the size
        // to be window size + or - some constant.
        resizeMap(windowHeight, windowWidth);
    }, [windowWidth, windowHeight]);

    return (
        <StyledSVG
            id='bounding-box'

            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            preserveAspectRatio='none'
            height="100%"
            width="100%"

            {...props}
        >
            {children}
        </StyledSVG>
    );
}

const StyledSVG = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${theme.zIndices.lineMap};
`;
