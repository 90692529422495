import React, { useContext, useState, useMemo } from 'react';

import { Button } from '../../../elements';
import { getDrawnFloors } from './helpers'; 
import { DrawingContext } from '../../../contexts/drawing';
import { ModalContext } from '../../../contexts/modal';
import {
    ModalBody,
    ModalTitle,
    ModalLabel,
    OptionsWrapper,
    ErrorText,
    StyledToggleSwitch,
    StyledDropdown
} from './elements';
import { OperatorContext } from '../../../contexts/operators';
import { imageToReadable } from '../../../assets/maps';

export default function ClearModal(props) {
    const {hideModal} = useContext(ModalContext);

    const {
        lines,
        highlights,
        shapes,
        icons,
        text,
        clearLines,
        clearHighlights,
        clearIcons,
        clearText,
        clearShapes
    } = useContext(DrawingContext);

    const {
        resetOperators,
        resetBans
    } = useContext(OperatorContext);

    const drawnFloors = useMemo(() => {
        return getDrawnFloors(lines, highlights, shapes, icons, text);
    }, [lines, highlights, shapes, icons, text]);

    const [floorsToClear, setFloorsToClear] = useState([]);

    const [eraseLines, setEraseLines] = useState(true);
    const [eraseHighlights, setEraseHighlights] = useState(true);
    const [eraseIcons, setEraseIcons] = useState(true);
    const [eraseText, setEraseText] = useState(true);
    const [eraseOperators, setEraseOperators] = useState(true);
    const [eraseBans, setEraseBans] = useState(true);
    const [eraseShapes, setEraseShapes] = useState(true);

    const clearLineOptions = ['Clear Lines', 'Keep Lines'];
    const clearHighlightOptions = ['Clear Highlights', 'Keep Highlights'];
    const clearShapeOptions = ['Clear Shapes', 'Keep Shapes'];
    const clearIconOptions = ['Clear Icons', 'Keep Icons'];
    const clearTextOptions = ['Clear Text', 'Keep Text'];
    const clearOperatorOptions = ['Clear Operators', 'Keep Operators'];
    const clearBanOptions = ['Clear Bans', 'Keep Bans'];
    return (
        <ModalBody {...props}>
            <ModalTitle>Clear</ModalTitle>
            <ModalLabel htmlFor="floor-selector">Maps to Clear</ModalLabel>
            {  drawnFloors.length !== 0 ? (
                <StyledDropdown
                    onSelect={value => {
                        if (floorsToClear.includes(value)) setFloorsToClear(floorsToClear.filter(floor => floor !== value));
                        else setFloorsToClear([...floorsToClear, value]);
                    }}
                    placeHolder={'Maps To Clear'}
                    multi={true}
                >
                    {drawnFloors.map(floorString => <span value={floorString} key={floorString}>{imageToReadable[floorString]}</span>)}
                </StyledDropdown>
            ) : (
                <ErrorText>No drawings to clear. Draw something first.</ErrorText>
            )}
            <OptionsWrapper>
                <StyledToggleSwitch
                    option1={clearLineOptions[0]}
                    option2={clearLineOptions[1]}
                    defaultOption={eraseLines ? clearLineOptions[0] : clearLineOptions[1]}
                    onClick={() => setEraseLines(!eraseLines)}
                />
                <StyledToggleSwitch
                    option1={clearHighlightOptions[0]}
                    option2={clearHighlightOptions[1]}
                    defaultOption={eraseHighlights ? clearHighlightOptions[0] : clearHighlightOptions[1]}
                    onClick={() => setEraseHighlights(!eraseHighlights)}
                />
                <StyledToggleSwitch
                    option1={clearShapeOptions[0]}
                    option2={clearShapeOptions[1]}
                    defaultOption={eraseShapes ? clearShapeOptions[0] : clearShapeOptions[1]}
                    onClick={() => setEraseShapes(!eraseShapes)}
                />
                <StyledToggleSwitch
                    option1={clearIconOptions[0]}
                    option2={clearIconOptions[1]}
                    defaultOption={eraseIcons ? clearIconOptions[0] : clearIconOptions[1]}
                    onClick={() => setEraseIcons(!eraseIcons)}
                />
                <StyledToggleSwitch
                    option1={clearTextOptions[0]}
                    option2={clearTextOptions[1]}
                    defaultOption={eraseText ? clearTextOptions[0] : clearTextOptions[1]}
                    onClick={() => setEraseText(!eraseText)}
                />
                <StyledToggleSwitch
                    option1={clearOperatorOptions[0]}
                    option2={clearOperatorOptions[1]}
                    defaultOption={eraseOperators ? clearOperatorOptions[0] : clearOperatorOptions[1]}
                    onClick={() => setEraseOperators(!eraseOperators)}
                />
                <StyledToggleSwitch
                    option1={clearBanOptions[0]}
                    option2={clearBanOptions[1]}
                    defaultOption={eraseBans ? clearBanOptions[0] : clearBanOptions[1]}
                    onClick={() => setEraseBans(!eraseBans)}
                />
            </OptionsWrapper>
            <Button
                onClick={() => {
                    if (eraseLines) clearLines(floorsToClear);
                    if (eraseHighlights) clearHighlights(floorsToClear);
                    if (eraseShapes) clearShapes(floorsToClear);  
                    if (eraseText) clearText(floorsToClear);             
                    if (eraseIcons) clearIcons(floorsToClear);
                    if (eraseOperators) resetOperators();
                    if (eraseBans) resetBans();
                    hideModal();
                }}
            >
                Clear
            </Button>
        </ModalBody>
    );
}
