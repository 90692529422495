import React, { useCallback, useContext, useMemo, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { exportService } from '../../../services';

import styled from '@emotion/styled';
import theme from '../../../theme';
import { getMapFromFloor, imageToReadable } from '../../../assets/maps';
import { Button } from '../../../elements';
import { MapBackgroundContext } from '../../../contexts/map_background';
import { DrawingContext } from '../../../contexts/drawing';
import { OperatorContext } from '../../../contexts/operators';
import { OverlayContext } from '../../../contexts/overlay';
import { ModalContext } from '../../../contexts/modal';
import { ToolOptionsContext } from '../../../contexts/tool_options';
import Dropdown from '../../../elements/dropdown';

async function waitForLoad(loadCallback, setLoadingDoneCallback) {
    await loadCallback();
    setLoadingDoneCallback();
}

export default function ExportModal(props) {

    const {
        lines,
        highlights,
        icons,
        text,
        shapes
    } = useContext(DrawingContext);

    const {
        operators,
        secondaryUtility,
        attackBan,
        defenceBan
    } = useContext(OperatorContext);
    const bans = {
        attack: attackBan,
        defence: defenceBan
    };

    const { hideModal } = useContext(ModalContext);

    const {
        floor,
        setFloor,
        mapWidth,
        mapHeight,
        resetMapPan,
        resetMapScale,
        lockMapCropping
    } = useContext(MapBackgroundContext);

    const { changeOverlay } = useContext(OverlayContext);

    const { setTool } = useContext(ToolOptionsContext); 

    const [filename, setFilename] = useState('strat');
    const [exporting, setExporting] = useState(false);

    const mappedFloors = useMemo(() => {
        return Array.from(new Set([
            ...lines.map(({floor}) => floor),
            ...highlights.map(({floor}) => floor),
            ...shapes.map(({floor}) => floor),
            ...icons.map(({floor}) => floor),
            ...text.map(({floor}) => floor)
        ]));
    }, [lines, highlights, icons, text]);

    const allFloors = useMemo(() => {
        return Array.from(new Set(mappedFloors.flatMap(getMapFromFloor)));
    }, [mappedFloors]);

    const [floorsToExport, setFloorsToExport] = useState([]);

    const getCropBounds = useCallback(() => {
        resetMapPan();
        resetMapScale();
        hideModal();
        changeOverlay('Export');
        setTool('Cropper');

        return new Promise(resolve => {
            document.addEventListener('croppingUnlocked', () => {
                setTimeout(() => {
                    resolve(true);
                }, 25);
            }, {
                once: true,
            });
        });

    }, [changeOverlay]);

    const exitCroppingTool = useCallback(() => {
        changeOverlay('Siege');
        setTool('Cursor');
    });

    // For internal use to save smaller formats.
    // const saveStrat = useCallback(() => {
    //     exportService.exportStrat(filename, floorsToExport, lines, highlights, shapes, icons, text, operators, bans, secondaryUtility);
    // }, [filename, floorsToExport, lines, highlights, shapes, icons, text, operators, bans, secondaryUtility]);

    const getStratJson = useCallback(() => {
        return exportService.exportStrat(undefined, floorsToExport, lines, highlights, shapes, icons, text, operators, bans, secondaryUtility);
    }, [floorsToExport, lines, highlights, shapes, icons, text, operators, bans, secondaryUtility]);

    const exportImage = useCallback(async () => {
        const stratJSON = getStratJson();
        await exportService.exportImage(filename, mapHeight, mapWidth, floorsToExport, setFloor, operators, bans, secondaryUtility, getCropBounds, exitCroppingTool, lockMapCropping, stratJSON);
    }, [filename, mapHeight, mapWidth, floorsToExport, floor, setFloor, operators, bans, secondaryUtility, getCropBounds, exitCroppingTool, lockMapCropping, getStratJson]);

    const exportButton = exporting ? (
        <ClipLoader css="grid-area: export-button;"/>
    ) : (
        <ExportButton 
            onClick={() => {
                setExporting(true);
                waitForLoad(
                    exportImage,
                    () => setExporting(false)
                );
            }}
        >
            Export
        </ExportButton>
    );

    return (
        <ModalBody {...props}>
            <ModalTitle>Export Strat</ModalTitle>
            <ModalLabel gridArea="strat-label"  htmlFor="strat-name">Strat Name</ModalLabel>
            <StratNameInput
                id="strat-name"
                type="text"
                value={filename}
                onChange={(event) => {
                    setFilename(event.target.value);
                }}
            />
            <ModalLabel gridArea="export-label" htmlFor="floor-selector">Map Exports</ModalLabel>
            { allFloors.length !== 0 ? (
                <StyledDropdown
                    onSelect={value => {
                        if (floorsToExport.includes(value)) {
                            setFloorsToExport(prev => prev.filter(floor => floor !== value));
                        } else {
                            setFloorsToExport(prev => [...prev, value]);
                        }
                    }}
                    value={mappedFloors}
                    placeHolder={'Maps To Export'}
                    multi={true}
                >
                    {allFloors.map(floorString => <span value={floorString} key={floorString}>{imageToReadable[floorString]}</span>)}
                </StyledDropdown>
            ) : (
                <ErrorText>No drawings to export. Draw something first.</ErrorText>
            )}
            <ExportDescription>
                Importable Image
            </ExportDescription>
            {exportButton}
        </ModalBody>
    );
}

const ModalBody = styled.div`
    display: grid;
    grid-template:
        ". title title title ."
        ". . . . ." 20px
        ". . strat-label . ."
        ". strat-input strat-input strat-input ." 
        ". . export-label . ."
        ". export-input export-input export-input ."
        ". type-selector type-selector type-selector ."
        ". .             .             .             ." 35px
        ". .   export-button .   ."
        ". . export-desc . ." / 10px 1fr 1fr 1fr 10px;

    text-align: center;

    place-items: center;
`;

const ModalTitle = styled.p`
    font-size: ${theme.text.fontSizePrimary};
    font-family: ${theme.text.fontPrimary};
    color: ${theme.text.colorPrimary};
    text-decoration: ${theme.text.decorationPrimary};

    grid-area: title;
    margin: 5px 0px 10px 0px;
`;

const ModalLabel = styled.label`
    font-size: ${theme.text.fontSizeSecondary};
    font-family: ${theme.text.fontSecondary};
    color: ${theme.text.colorSecondary};
    text-decoration: ${theme.text.decorationSecondary};

    ${({gridArea}) => gridArea ? `grid-area: ${gridArea}` : ''}
`;

const ErrorText = styled.p`
    grid-area: export-input;

    text-align: center;
    font-size: ${theme.text.fontSizeError};
    color: ${theme.text.colorError};
`;

const DescriptionText = styled.p`
    font-size: ${theme.text.fontSizeTertiary};
    font-color: ${theme.text.colorTertiary};
    opacity: .5;

    place-self: start;
    margin: unset;
    padding: unset;
`;

const StyledDropdown = styled(Dropdown)`
    grid-area: export-input;
`;

const ExportDescription = styled(DescriptionText)`
    grid-area: export-desc;
`;

const ExportButton = styled(Button)`
    grid-area: export-button;
`;

const StratNameInput = styled.input`
    grid-area: strat-input;
    margin-bottom: 30px;
`;
