import React from 'react';
import styled from '@emotion/styled';
import theme from '../../theme';


export default function Button({children, onClick, ...props}) { 
    const buttonClick = props.disabled ? () => {} : onClick;

    return (
        <StyledButton onClick={buttonClick} {...props}>
            <ButtonText>
                {children}
            </ButtonText>
        </StyledButton>
    );
}

const ButtonText = styled.p`
    font-family: ${theme.text.fontButton};
    font-weight: ${theme.text.fontWeightButton};
    font-size: ${theme.text.fontSizeButton};

    padding: unset;
    margin: unset;
`;

const StyledButton = styled.div`
    display: grid;
    place-items: center;

    position: relative;
    height: 30px;
    text-align: center;

    color: ${({disabled}) => disabled ? 'gray' : 'black'};
    border: 2px solid ${({disabled}) => disabled ? 'gray' : 'black'};
    padding: 0px 5px 0px 5px;
    border-radius: 4px;

    transition: background 350ms;

    ${({disabled}) => disabled ? '' : `
        :hover {
            background: ${theme.colors.buttonHover};
            color: white;
            cursor: pointer;
        }

        :active {
            background: ${theme.colors.buttonPress}
        }
    `}
`;
